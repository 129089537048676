/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Radio, Table } from 'antd'
import moment from 'moment'
import qs from 'query-string'

import { getRadioFilterProps, getSearchFilterProps } from '../../components'
import { useGiftsDonations } from '../../hooks'

import BulkEditGiftDonationsModal from './bulk-edit-modal'

export default function ShopItemsPage() {
	const { data, totalCount, isLoading, fetchGiftsDonations } =
		useGiftsDonations()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const handleTableChange = (pagination, filters) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (!value || !value.length) return
			Object.assign(params, { [key]: value[0] })
		})

		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})
		fetchGiftsDonations(params)
	}

	useEffect(() => {
		fetchGiftsDonations(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchGiftsDonations])

	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchGiftsDonations()
	}
	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	const [selections, setSelections] = useState([])

	const [bulkEditModalVisible, setBulkEditModalVisible] = useState(false)
	return (
		<>
			<BulkEditGiftDonationsModal
				isVisible={bulkEditModalVisible}
				onClose={() => setBulkEditModalVisible(false)}
				selections={selections}
				onSuccessEdit={() => {
					setSelections([])
					fetchGiftsDonations(queryParams)
				}}
			/>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold mb-0 mr-auto">
					Gift Donations Statistics
				</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
				<Button
					type="primary"
					className="mr-4"
					disabled={!selections.length}
					onClick={() => setBulkEditModalVisible(true)}
				>
					Bulk edit
				</Button>
			</header>
			<Table
				className="mx-4"
				loading={isLoading}
				dataSource={data}
				size="small"
				tableLayout="fixed"
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 259px)',
					x: 991
				}}
				rowSelection={{
					type: 'checkbox',
					selectedRowKeys: selections,
					onChange: (selectedRowKeys) => {
						setSelections(selectedRowKeys)
					},
					getCheckboxProps: (record) => ({
						name: record.name
					})
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					dataIndex={['child', 'parent', 'name']}
					title="User"
					key="user_id"
					render={(val, record) => (
						<Link
							to={`/users?modal_is_open=true&mode=view_user&record_id=${record?.child?.parent?._id}`}
						>
							{record?.child?.parent?.name}
						</Link>
					)}
					width={198}
				/>
				<Table.Column
					dataIndex={['gift', 'coins']}
					title="Coins"
					key="coins"
					width={128}
					className="text-center"
				/>
				<Table.Column
					dataIndex="createdAt"
					title="Donated on"
					key="createdAt"
					render={(val) => moment(val).format('DD-MM-YYYY, HH:mm')}
					width={148}
				/>
				<Table.Column
					dataIndex={['gift', 'title']}
					title="Gift"
					key="gift"
					ellipsis
					{...getSearchFilterProps('Gift title')}
					filteredValue={getFilterValue('gift')}
				/>
				<Table.Column
					dataIndex="donationStatus"
					title="Donation status"
					key="status"
					filteredValue={getFilterValue('status')}
					{...getRadioFilterProps(
						'Completed?',
						[
							{ value: true, label: 'Yes' },
							{ value: false, label: 'No' }
						],
						(item) => <Radio value={item.value}>{item.label}</Radio>
					)}
					render={(val) =>
						val ? (
							<>
								<CheckOutlined className="text-green-500" />
								<span>&nbsp;Completed</span>
							</>
						) : (
							<>
								<CloseOutlined className="text-red-500" />
								<span>&nbsp;Incompleted</span>
							</>
						)
					}
				/>
			</Table>
		</>
	)
}
