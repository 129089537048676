import { message } from 'antd'
import axios from 'axios'

import { store } from '../store'
import userSlice from '../store/reducers/user'

const logError = (err) => {
	if (err.response) {
		const { status, statusText, data } = err.response
		const { method, url, baseURL } = err.response.config

		let error = ''
		error += `\nError ${status}: ${statusText}`
		error += `\nmethod: ${method}`
		error += `\nbase url: ${baseURL}\nendpoint: ${url}`
		error += `${!data.message ? `\ndata:\n${data}` : ''}`
		// eslint-disable-next-line no-console
		console.error(error)
	} else {
		// eslint-disable-next-line no-console
		console.error(err)
	}
}

const API_URL = process.env.REACT_APP_API_URL

const instance = axios.create({
	baseURL: API_URL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json'
	}
})

// request handler
instance.interceptors.request.use(
	(config) => {
		const temp = config
		const access = localStorage.getItem('access_token')
		if (access) {
			temp.headers.Authorization = `Bearer ${access}`
		}
		return temp
	},
	(error) => Promise.reject(error)
)

// response handler
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (process.env.NODE_ENV === 'development') logError(error)
		const originalRequest = error.config
		const errCode = error?.response?.status || undefined
		const errMessage = error?.response?.data?.message || undefined
		const self =
			originalRequest.url.includes('/admin/auth') &&
			originalRequest.method === 'get'

		const notAuthenticated = errCode === 401
		if (
			errMessage &&
			!notAuthenticated &&
			!(errCode === 404 && originalRequest.url.includes('resetpassword'))
		)
			message.error(errMessage)

		if (!error) {
			message.error('Unexpected error')
		} else if (typeof error.response === 'undefined') {
			message.error({ content: 'Network Error', key: 'NETWORK_ERROR' })
		} else if (
			error.code === 'ECONNABORTED' ||
			(error.response && error.response.status === 408)
		) {
			message.error('The server is taking to long to respond')
		} else if (notAuthenticated && !self) {
			store.dispatch(userSlice.actions.updateState({ isAuthenticated: false }))
			const msg = error.response.data.message ?? 'Your session has expired'
			message.error(msg)
		} else if (errCode === 429) {
			message.error('Too many request')
		} else if (errCode >= 500 && errCode < 600) {
			message.error('Internal Server Error')
		} else if (errCode === 400) {
			const errData = error.response.data
			if (
				errData.error &&
				Array.isArray(errData.error) &&
				errData.error.length > 0
			) {
				errData.error.map((item) => message.error(item))
				return Promise.reject(error)
			}
			if (typeof errData === 'object' && errData !== null) {
				Object.values(errData).map((item) => message.error(item))
				return Promise.reject(error)
			}
			if (typeof errData === 'string') {
				message.error(errData)
			} else {
				message.error('Error in data handling')
			}

			return Promise.reject(error)
		}

		return Promise.reject(error)
	}
)

export default instance
