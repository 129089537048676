/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'
import { arrayOf, bool, func, string } from 'prop-types'

import { useGiftsDonations } from '../../hooks'

export default function BulkEditGiftDonationsModal({
	onClose,
	isVisible,
	selections,
	onSuccessEdit
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()

	const { resetFields, validateFields } = form
	const { bulkEditGiftDonations } = useGiftsDonations()

	const handleSubmitEdit = () => {
		validateFields().then(({ status }) => {
			setIsSubmitting(true)
			bulkEditGiftDonations({ status, ids: selections })
				.then(() => {
					onSuccessEdit()
					onClose()
				})
				.finally(() => setIsSubmitting(false))
		})
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		resetFields()
	}, [resetFields])

	return (
		<>
			<Modal
				visible={isVisible}
				onCancel={onClose}
				title={<h3 className="font-bold mb-0">Bulk edit gift donations</h3>}
				afterClose={afterClose}
				confirmLoading={isSubmitting}
				forceRender
				destroyOnClose
				footer={
					<div className="flex flex-row flex-nowrap">
						<Button onClick={onClose}>Cancel</Button>
						<Button
							type="primary"
							onClick={handleSubmitEdit}
							className="inline-flex items-center justify-center leading-none"
						>
							Edit
						</Button>
					</div>
				}
			>
				<Form form={form} preserve={false}>
					<Form.Item
						name="status"
						label="Donation status"
						rules={[
							{
								required: true,
								message: 'Please select a value'
							}
						]}
					>
						<Select>
							<Select.Option value>Completed</Select.Option>
							<Select.Option value={false}>Incompleted</Select.Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

BulkEditGiftDonationsModal.propTypes = {
	selections: arrayOf(string).isRequired,
	onClose: func.isRequired,
	isVisible: bool.isRequired,
	onSuccessEdit: func.isRequired
}
