import { Layout } from 'antd'
import { element } from 'prop-types'

import Footer from './footer'

export default function AuthLayout(props) {
	const { children } = props
	return (
		<Layout className="h-full">
			<div className="h-full bg-white flex flex-col m-2">{children}</div>
			<Footer />
		</Layout>
	)
}
AuthLayout.propTypes = {
	children: element.isRequired
}
