import { string } from 'prop-types'

import sprite from './sprite.svg'

/**
 * should declare width and height of icon in most cases.
 * @param {Variant} variant
 */
export function Icon(props) {
	const { variant, ...restProps } = props
	return (
		<svg {...restProps}>
			<use xlinkHref={`${sprite}#${variant}`} />
		</svg>
	)
}
Icon.propTypes = {
	variant: string.isRequired
}

export default Icon
