/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, InputNumber, message, Modal, Spin, Switch } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useAvatars } from '../../hooks'
import { confirmExit, getUrl } from '../../utils'

export default function EditAvatarModal({
	onClose,
	onEdit,
	onDelete,
	queryParams
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(true)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form
	const { fetchAvatar } = useAvatars()

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { _id, user_id, icon_dark, ...rest } = record || {}
		const fieldsValues = getFieldsValue()
		setFormHasChanged(!isEqual(fieldsValues, rest))
	}, [getFieldsValue, record])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleSubmitEdit = () => {
		validateFields().then(({ order, ...payload }) => {
			setIsSubmitting(true)
			onEdit({ ...payload, _id: record._id })
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])
	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchAvatar(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch avatar data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		onClose,
		fetchAvatar,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	return (
		<>
			<Modal
				visible={modalIsVisible}
				onCancel={handleCancelEdit}
				title={<h3 className="font-bold mb-0">Edit Avatar</h3>}
				afterClose={afterClose}
				confirmLoading={isSubmitting}
				forceRender
				destroyOnClose
				// okText="Edit"
				// onOk={handleSubmitEdit}
				// okButtonProps={{
				// 	disabled: !formHasChanged,
				// 	className: 'inline-flex flex-row items-center justify-center'
				// }}
				footer={
					<div className="flex flex-row flex-nowrap">
						<DeleteBtn
							onDelete={handleDelete}
							type="text"
							className="mr-auto"
						/>
						<Button onClick={handleCancelEdit}>Cancel</Button>
						<Button
							type="primary"
							disabled={!formHasChanged}
							onClick={handleSubmitEdit}
							className="inline-flex items-center justify-center leading-none"
						>
							Save
						</Button>
					</div>
				}
			>
				<Spin spinning={isFetching}>
					<Form
						form={form}
						name="edit_avatar"
						labelCol={{
							span: 6
						}}
						preserve={false}
						onFieldsChange={checkFormChanged}
					>
						<Form.Item name="order" label="Avatar ID">
							<InputNumber readOnly />
						</Form.Item>
						<Form.Item
							name="avatarCategory"
							label="Avatar Category"
							rules={[
								{
									required: true,
									message: 'Please input a category for avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item name="isFree" label="For Free" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item name="atShop" label="In Shop" valuePropName="checked">
							<Switch />
						</Form.Item>
						<Form.Item
							name="coins"
							label="Coins"
							rules={[
								{
									required: true,
									message: 'Please input the coins amount for purchase avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item
							name="gemLevel"
							label="Gem Level"
							rules={[
								{
									required: true,
									message: 'Please input the gem level of avatar'
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						{[
							{
								name: 'lightIcon',
								label: 'Icon'
							}
							/*
							,{
								name: 'icon_dark',
								label: 'Dark Icon'
							}
							*/
						].map((icon) => {
							let source
							if (record) source = getUrl(record[icon.name].path)
							return (
								<Form.Item
									name={icon.name}
									key={icon.name}
									label={icon.label}
									rules={[
										{
											required: icon.name === 'lightIcon',
											message: 'Please select icon file of avatar'
										}
									]}
									getValueFromEvent={source}
									className="flex flex-row items-center"
								>
									<div>
										<SingleFileUploader
											type="avatar"
											onlyPNG
											defaultFile={source}
											afterUpload={(path) => {
												setFieldsValue({ [icon.name]: path })
												checkFormChanged()
											}}
										/>
									</div>
								</Form.Item>
							)
						})}
					</Form>
				</Spin>
			</Modal>
		</>
	)
}

EditAvatarModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired
}
