import { Layout } from 'antd'
import { bool, element } from 'prop-types'

import { Header } from '../header'
import { SideBar } from '../sidebar'

import Footer from './footer'

export function MainLayout(props) {
	const { children, hasFooter, hasSidebar, hasHeader } = props
	return (
		<Layout className="min-h-full">
			{hasSidebar && <SideBar />}
			<Layout>
				{hasHeader && <Header />}
				<div className="h-full bg-white flex flex-col m-2">{children}</div>
				{hasFooter && <Footer />}
			</Layout>
		</Layout>
	)
}

MainLayout.propTypes = {
	children: element,
	hasSidebar: bool,
	hasHeader: bool,
	hasFooter: bool
}
MainLayout.defaultProps = {
	children: null,
	hasSidebar: true,
	hasHeader: true,
	hasFooter: true
}
export default MainLayout
