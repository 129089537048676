/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import cn from 'classnames'
import { func, oneOf, string } from 'prop-types'

import { useUser } from '../../store/hooks'

function DeleteBtn({ onDelete, className, type }) {
	const [modalIsVisible, setModalVisible] = useState(false)
	const { isSuper } = useUser()
	return (
		<>
			<Button
				disabled={!isSuper}
				type="default"
				icon={<DeleteOutlined />}
				className={cn(
					'flex items-center justify-center',
					{ 'delete-btn': type === 'icon' },
					className
				)}
				onClick={() => setModalVisible(true)}
				danger
			>
				{type === 'text' && 'Delete'}
			</Button>
			<Modal
				visible={modalIsVisible}
				onCancel={() => setModalVisible(false)}
				onOk={() => {
					onDelete()
					setModalVisible(false)
				}}
				okType="danger"
				closable={false}
				maskClosable={false}
				title="Delete Confirmation"
				okText="Yes, delete it"
			>
				<p>Are you sure about deleting this item?</p>
			</Modal>
		</>
	)
}
DeleteBtn.propTypes = {
	onDelete: func.isRequired,
	className: string,
	type: oneOf(['icon', 'text'])
}
DeleteBtn.defaultProps = {
	className: '',
	type: 'icon'
}
export default DeleteBtn
