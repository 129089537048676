/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import qs from 'query-string'

import { axios } from '../utils'

/**
 * @typedef {object} DonationsStatisticsQueryParams
 * @property {string} gift_id
 * @property {string} user_id
 * @property {string} child_id
 */

export default function useGiftsDonations() {
	const [data, setData] = useState([])
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(true)

	const fetchGiftsDonations = useCallback(
		/**
		 * @param {DonationsStatisticsQueryParams} queryParams
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				const {
					page: offset,
					size: limit,
					sort_parent,
					sort_coins,
					sort_gift,
					sort_status,
					sort_createdAt,
					...params
				} = queryParams

				params.offset = offset
				params.limit = limit

				if (typeof sort_parent === 'number') {
					params.sortKey = 'parent'
					params.sortType = sort_parent === 1 ? 'desc' : 'asc'
				} else if (typeof sort_coins === 'number') {
					params.sortKey = 'coins'
					params.sortType = sort_coins === 1 ? 'desc' : 'asc'
				} else if (typeof sort_gift === 'number') {
					params.sortKey = 'gift'
					params.sortType = sort_gift === 1 ? 'desc' : 'asc'
				} else if (typeof sort_status === 'number') {
					params.sortKey = 'status'
					params.sortType = sort_status === 1 ? 'desc' : 'asc'
				} else if (typeof sort_createdAt === 'number') {
					params.sortKey = 'createdAt'
					params.sortType = sort_createdAt === 1 ? 'desc' : 'asc'
				}

				setIsLoading(true)
				const endpoint = `/admin/gifts/donations?${qs.stringify(params, {
					skipNull: true,
					skipEmptyString: true
				})}`

				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: donationsData, count: total_count } = res.data
						setTotalCount(total_count)
						setData(donationsData)
						resolve(donationsData)
					})
					.catch(reject)
					.finally(() => setIsLoading(false))
			})
		},
		[]
	)
	const bulkEditGiftDonations = useCallback((payload) => {
		return new Promise((resolve, reject) => {
			axios
				.put('/admin/gifts/donations', payload)
				.then(({ data: res }) => resolve(res.data))
				.catch(reject)
		})
	}, [])

	return {
		data,
		totalCount,
		isLoading,
		fetchGiftsDonations,
		bulkEditGiftDonations
	}
}
