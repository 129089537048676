import { useEffect } from 'react'
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom'

import { MainLayout } from '../../components'
import { useRemoveElement } from '../../hooks'
import {
	AdminsPage,
	AvatarsPage,
	GiftDonationsPage,
	GiftsPage,
	HomePage,
	LessonsPage,
	LevelsPage,
	MicroLessonsPage,
	NotFoundPage,
	QuestionTypesPage,
	QuizQuestionsPage,
	RatingsPage,
	ReportsPage,
	RevisionLessonsPage,
	SubjectsPage,
	TrophiesPage,
	UsersPage
} from '../../pages'
import { useUser } from '../../store/hooks'

export default function UserRouter() {
	const { isSuper, updateAvatar, fetchStatistics } = useUser()

	useRemoveElement()

	useEffect(() => {
		updateAvatar()
	}, [updateAvatar])

	useEffect(() => {
		fetchStatistics()
	}, [fetchStatistics])

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<MainLayout>
				<Switch>
					<Route exact path="/">
						<HomePage />
					</Route>
					<Route path="/levels">
						<LevelsPage />
					</Route>
					<Route path="/lessons">
						<LessonsPage />
					</Route>
					<Route path="/subjects">
						<SubjectsPage />
					</Route>
					<Route path="/question-types">
						<QuestionTypesPage />
					</Route>
					<Route path="/avatars">
						<AvatarsPage />
					</Route>
					<Route path="/users">
						<UsersPage />
					</Route>
					<Route path="/micro-lessons">
						<MicroLessonsPage />
					</Route>
					<Route path="/quiz-questions">
						<QuizQuestionsPage />
					</Route>
					<Route path="/trophies">
						<TrophiesPage />
					</Route>
					<Route path="/revision-lessons">
						<RevisionLessonsPage />
					</Route>
					<Route path="/gifts">
						<GiftsPage />
					</Route>
					<Route path="/gift-donations">
						<GiftDonationsPage />
					</Route>
					<Route path="/reviews">
						<RatingsPage />
					</Route>
					<Route path="/bug-reports">
						<ReportsPage />
					</Route>
					{!isSuper ? null : (
						<Route path="/admins">
							<AdminsPage />
						</Route>
					)}
					<Redirect from="/auth/login" to="/" />
					<Route path="*">
						<NotFoundPage />
					</Route>
				</Switch>
			</MainLayout>
		</Router>
	)
}
