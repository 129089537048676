/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	MenuOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Radio, Table } from 'antd'
import qs from 'query-string'

import {
	AddNewAvatarModal,
	getRadioFilterProps,
	getSearchFilterProps
} from '../../components'
import { useAvatars } from '../../hooks'
import { useUser } from '../../store/hooks'
import { getUrl } from '../../utils'

import EditAvatarModal from './edit-btn'

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export function AvatarsPage() {
	const { isSuper } = useUser()

	const {
		data,
		count,
		isLoading,
		fetchAvatars,
		addNewAvatar,
		deleteAvatar,
		editAvatar,
		reorderAvatar
	} = useAvatars()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				Object.assign(params, { [key]: value[0] })
				// let paramKey
				// switch (key) {
				// 	default:
				// 		paramKey = key
				// 		break
				// }
				// Object.assign(params, { [paramKey]: value[0] })
			}
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}
		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})
		fetchAvatars(params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchAvatars()
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	useEffect(() => {
		fetchAvatars(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAvatars])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			reorderAvatar({
				_id: data[oldIndex]._id,
				destination: data[newIndex]._id
			})
		}
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => document.querySelector('.ant-table-tbody')}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Avatars List</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				className="mx-4"
				loading={isLoading}
				dataSource={data}
				size="small"
				tableLayout="fixed"
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 259px)',
					x: 498
				}}
				pagination={{
					position: ['bottomCenter'],
					total: count,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			>
				{!isSuper ? null : (
					<Table.Column
						dataIndex="sort"
						key="sort"
						width={48}
						className="drag-handle-col text-center"
						title={
							<Button
								type={reordering ? 'primary' : 'default'}
								icon={<MenuOutlined />}
								className="w-full h-full flex items-center justify-center"
								onClick={() => setReordering((prev) => !prev)}
							/>
						}
						render={reordering ? () => <DragHandle /> : null}
					/>
				)}
				<Table.Column
					dataIndex="order"
					title="ID"
					key="order"
					className="text-center"
					width={112}
					filteredValue={getFilterValue('order')}
					{...getSearchFilterProps('avatar ID')}
					sorter
					sortOrder={getSortOrder('sort_order')}
				/>
				<Table.Column
					dataIndex="lightIcon"
					title="Icon"
					key="lightIcon"
					className="text-center"
					width={112}
					render={(val) => {
						const source = getUrl(val.path)
						return (
							<div className="h-8 overflow-hidden">
								{val ? (
									<img
										src={source}
										alt={val.type}
										className="h-full w-auto mx-auto"
									/>
								) : (
									'not set'
								)}
							</div>
						)
					}}
				/>
				<Table.Column
					dataIndex="avatarCategory"
					title="Category"
					key="avatarCategory"
					className="text-center"
					width={136}
					filteredValue={getFilterValue('avatarCategory')}
					{...getSearchFilterProps('avatar category')}
					sorter
					sortOrder={getSortOrder('sort_avatarCategory')}
				/>
				<Table.Column
					dataIndex="isFree"
					title="For Free"
					key="isFree"
					className="text-center"
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
					filteredValue={getFilterValue('isFree')}
					{...getRadioFilterProps(
						'Is free?',
						[
							{ value: true, label: 'Yes' },
							{ value: false, label: 'No' }
						],
						(item) => <Radio value={item.value}>{item.label}</Radio>
					)}
				/>
				<Table.Column
					dataIndex="atShop"
					title="In Shop"
					key="atShop"
					className="text-center"
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
					filteredValue={getFilterValue('atShop')}
					{...getRadioFilterProps(
						'Is in shop?',
						[
							{ value: true, label: 'Yes' },
							{ value: false, label: 'No' }
						],
						(item) => <Radio value={item.value}>{item.label}</Radio>
					)}
				/>
				<Table.Column
					dataIndex="coins"
					title="Coins"
					key="coins"
					className="text-center"
				/>
				<Table.Column
					dataIndex="gemLevel"
					title="Gem Level"
					key="gemLevel"
					className="text-center"
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="edit-btn mr-2"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}
							/>
							{/* <DeleteBtn onDelete={() => deleteAvatar(record._id)} /> */}
						</div>
					)}
				/>
			</Table>
			<EditAvatarModal
				onClose={handleCloseModal}
				onEdit={editAvatar}
				onDelete={deleteAvatar}
				queryParams={queryParams}
			/>
			<AddNewAvatarModal
				visible={
					!!(queryParams?.modal_is_open && queryParams?.mode === 'add') || false
				}
				setVisible={setModalVisibility}
				onAdd={addNewAvatar}
			/>
		</>
	)
}

export default AvatarsPage
