import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { object, string } from 'prop-types'

import {
	ISQuestion,
	MASorMISQuestion,
	STSorEATQuestion
} from '../../components/questions'

export default function RevisionQuestionsForm({
	// active,
	formInstance,
	selectedTypeName,
	initialValues
}) {
	const [initialState, setInitialState] = useState()
	useEffect(() => {
		if (initialValues && initialValues.details) {
			const { details } = initialValues || {}
			formInstance.setFieldsValue(details)
			setInitialState(details)
		} else {
			setInitialState()
		}
	}, [formInstance, initialValues])

	return (
		<Form
			form={formInstance}
			name="revision_question_details_form"
			initialValues={initialValues}
			preserve={false}
			// className={cn({ hidden: !active })}
		>
			<Form.Item>
				{selectedTypeName === 'EAT' && (
					<STSorEATQuestion
						formInstance={formInstance}
						type="EAT"
						initialValues={initialState}
					/>
				)}
				{selectedTypeName === 'IS' && (
					<ISQuestion
						formInstance={formInstance}
						initialValues={initialState}
					/>
				)}
				{selectedTypeName === 'MIS' && (
					<MASorMISQuestion
						formInstance={formInstance}
						initialValues={initialState}
						type="MIS"
					/>
				)}
			</Form.Item>
		</Form>
	)
}
RevisionQuestionsForm.propTypes = {
	// active: bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	selectedTypeName: string.isRequired
}
