/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react'
import { Form, Input, InputNumber, Modal, Select, Spin } from 'antd'
import PropTypes, { arrayOf, bool, func, shape, string } from 'prop-types'

import { useLevels } from '../../hooks'
import { axios, confirmExit } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

const { Option } = Select

export default function AddNewLessonModal({
	visible,
	setVisible,
	onAdd,
	subjects,
	subjectsIsLoading
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()
	const [order, setOrder] = useState(null)
	const [loadingOrder, setLoadingOrder] = useState(false)
	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()

	const [filteredLevels, setFilteredLevels] = useState([])
	const [selectedSubject, setSelectedSubject] = useState()

	const onChangeLevel = useCallback(
		(levelId, subjectId) => {
			axios
				.get(`/admin/lessons/priority?level=${levelId}&subject=${subjectId}`)
				.then((res) => {
					const priority = res.data.data

					setOrder(priority)
					form.setFieldsValue({ priority })
				})
				.finally(() => setLoadingOrder(false))
		},
		[form]
	)

	useEffect(() => {
		if (selectedSubject) {
			fetchLevels().then((levels) => {
				setFilteredLevels(levels)
			})
		}
	}, [fetchLevels, selectedSubject])

	const closeModal = useCallback(() => {
		form.resetFields()
		setVisible(false)
		setIsSubmitting(false)
	}, [form, setVisible])

	const handleAddingNewLesson = useCallback(() => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}, [closeModal, form, onAdd])

	const onCancel = () => {
		const formIsEmpty =
			Object.values({ ...form.getFieldsValue() }).every((el) => !el) &&
			!selectedSubject

		confirmExit(closeModal, !formIsEmpty)
	}

	useEffect(() => {
		if (visible) {
			setSelectedSubject()
			form.resetFields()
		}
	}, [form, visible])

	return (
		<Modal
			visible={visible}
			okText="ADD NEW LESSON"
			onOk={handleAddingNewLesson}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Lesson</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_lesson"
				initialValues={{
					subject: null,
					level: null,
					title: '',
					lightIcon: null,
					darkIcon: null
				}}
				labelCol={{
					span: 5
				}}
				onFinish={handleAddingNewLesson}
			>
				<Form.Item
					name="subject"
					label="Subject"
					rules={[
						{
							required: true,
							message: 'Please select a subject for lesson'
						}
					]}
				>
					<Select
						value={selectedSubject}
						notFoundContent={
							subjectsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							setSelectedSubject(e)
							// form.setFieldsValue({ subject: e })
							setOrder(null)
						}}
					>
						{subjects.map((s) => (
							<Option value={s._id} key={s._id}>
								{s.title}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="level"
					label="Level"
					rules={[
						{
							required: true,
							message: 'Please select a level for lesson!'
						}
					]}
				>
					<Select
						notFoundContent={
							levelsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(level) => onChangeLevel(level, selectedSubject)}
					>
						{filteredLevels.map((level) => (
							<Option value={level._id} key={level._id}>
								{level.title}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title of lesson'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="order" label="Lesson NO.">
					<Spin spinning={loadingOrder} wrapperClassName="w-max">
						<InputNumber readOnly value={order} />
					</Spin>
				</Form.Item>
				{[
					{
						name: 'lightIcon',
						label: 'Gray Icon'
					},
					{
						name: 'darkIcon',
						label: 'Color Icon'
					}
				].map((icon) => (
					<Form.Item
						name={icon.name}
						key={icon.name}
						label={icon.label}
						rules={[
							{
								required: true,
								message: 'Please select icon file of lesson'
							}
						]}
						className="flex flex-row items-center"
					>
						<div>
							<SingleFileUploader
								type="lesson"
								onlyPNG
								afterUpload={(fileID) => {
									form.setFieldsValue({ [icon.name]: fileID })
								}}
							/>
						</div>
					</Form.Item>
				))}
				<Form.Item
					name="status"
					label="Status"
					rules={[
						{
							required: true,
							message: 'Please select a status for lesson!'
						}
					]}
				>
					<Select>
						{[
							{ id: 'active', title: 'Active' },
							{ id: 'inactive', title: 'Inactive' }
						].map((status) => (
							<Select.Option value={status.id} key={status.id}>
								{status.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}
AddNewLessonModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired,
	onAdd: func.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired
}
