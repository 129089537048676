import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const { confirm } = Modal

/** @param {() => void} logout */
const logoutConfirm = (logout) => {
	return confirm({
		title: 'You are about to logout',
		icon: <ExclamationCircleOutlined />,
		content:
			'after logout, you need to re-enter your credential to use system again.',
		okText: 'Logout',
		okType: 'danger',
		onOk() {
			logout()
		},
		onCancel() {
			// do nothing
		}
	})
}

export default logoutConfirm
