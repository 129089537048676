import { useState } from 'react'
import { ChromePicker as ColorPicker } from 'react-color'
import { Popover } from 'antd'
import { func, string } from 'prop-types'

export default function CustomColorPicker(props) {
	const { color, setColor } = props
	const [isVisible, setVisible] = useState(false)

	return (
		<div className="flex flex-row items-center justify-start">
			<Popover
				content={
					<ColorPicker
						color={color}
						onChangeComplete={(col) => setColor(col.hex)}
						disableAlpha
						onChange={(col) => setColor(col.hex)}
					/>
				}
				trigger="click"
				visible={isVisible}
				onVisibleChange={setVisible}
				placement="right"
				overlayClassName="color-picker-popover"
			>
				<button
					type="button"
					onClick={() => setVisible((prevState) => !prevState)}
					className="p-1 bg-white rounded-sm inline-block cursor-pointer border border-solid border-gray-300"
				>
					<div
						className="w-9 h-4 rounded-sm"
						style={{
							backgroundColor: color
						}}
					/>
				</button>
			</Popover>
		</div>
	)
}
CustomColorPicker.propTypes = {
	color: string.isRequired,
	setColor: func.isRequired
}
