/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import qs from 'query-string'

import { axios } from '../utils'

export default function useUsers() {
	const [data, setData] = useState({
		users: [],
		count: 0,
		pages: 0,
		page: 0
	})
	const [isLoading, setLoading] = useState(true)

	const fetchUsers = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				const {
					page: _page,
					size: _size,
					sort_createdAt,
					...params
				} = queryParams

				params.offset = _page
				params.limit = _size

				if (typeof sort_createdAt === 'number') {
					params.sortKey = 'createdAt'
					params.sortType = sort_createdAt === 1 ? 'desc' : 'asc'
				}

				setLoading(true)
				const endpoint = `/admin/users?${qs.stringify(params, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: users, count, pages, page } = res.data
						setData({ users, count, pages, page })
						resolve(users)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchUser = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				if (!id) {
					reject(new Error('user ID not provided'))
					setLoading(false)
				} else {
					axios
						.get(`/admin/users/${id}`)
						.then(({ data: res }) => resolve(res.data))
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	return {
		data: data.users,
		count: data.count,
		pages: data.pages,
		page: data.page,
		isLoading,
		fetchUsers,
		fetchUser
	}
}
