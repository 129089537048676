import { Form, Input } from 'antd'
import { object } from 'prop-types'

import SingleFileUploader from '../single-file-uploader'

export default function ISQuestion({ formInstance, initialValues }) {
	return (
		<Form name="IS_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="text"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item
				name="file"
				label="File"
				// rules={[
				// 	{
				// 		required: true,
				// 		message: 'Please upload a video/audio file for question'
				// 	}
				// ]}
			>
				<div className="flex flex-col items-start justify-start w-max">
					<SingleFileUploader
						type="other"
						variant="FileList"
						defaultFile={initialValues?.file}
						getFileAddressInsteadOfID
						afterUpload={(fileAddress) =>
							formInstance.setFieldsValue({ file: fileAddress })
						}
						accept=".mp3, .mp4, .png"
						onlyMP3
						onlyMP4
						onlyPNG
						listType="text"
					/>
				</div>
			</Form.Item>
		</Form>
	)
}

ISQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}
