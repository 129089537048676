import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { element } from 'prop-types'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from '../store'

const MainProvider = ({ children }) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider>{children}</ConfigProvider>
			</PersistGate>
		</Provider>
	)
}

MainProvider.propTypes = {
	children: element.isRequired
}
export default MainProvider
export { MainProvider }
