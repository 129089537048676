import { Layout } from 'antd'

import { version } from '../../../package.json'

export default function Footer() {
	return (
		<Layout.Footer className="h-10 flex items-center p-0 px-4 bg-gray-300">
			v{version}
		</Layout.Footer>
	)
}
