import { useCallback, useEffect } from 'react'

import packageJson from '../package.json'

import { MainProvider } from './providers/main-provider'
import { MainRouter } from './routers'

import './styles/index.scss'
import 'antd/dist/antd.css'

export function App() {
	const clearCache = useCallback(async () => {
		const version = localStorage.getItem('version')
		if (!version) {
			localStorage.setItem('version', packageJson.version)
			return
		}
		if (version === packageJson.version) return

		localStorage.clear()
		localStorage.setItem('version', packageJson.version)

		const hasCache = 'caches' in window
		if (!hasCache) return

		const keys = await caches.keys()
		await Promise.all(keys.map((key) => caches.delete(key)))

		// Makes sure the page reloads. Changes are only visible after you refresh.
		window.location.reload(true)
	}, [])
	useEffect(() => clearCache(), [clearCache])
	return (
		<MainProvider>
			<MainRouter />
		</MainProvider>
	)
}

export default App
