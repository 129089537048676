import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { thunk } from 'redux-thunk'

import rootReducer from './reducers'

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
	key: 'root',
	storage,
	whitelist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
	devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)
