import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const { confirm } = Modal

const confirmExit = (onConfirm, getConfirmCondition = true) => {
	if (getConfirmCondition) {
		confirm({
			title: 'Exit Confirmation',
			content: 'Are you sure you want to exit? any entered data will be lost',
			icon: <ExclamationCircleOutlined />,
			onOk: onConfirm,
			okText: 'Yes, exit',
			okType: 'danger',
			closable: true,
			maskClosable: true
		})
	} else {
		onConfirm()
	}
}
export default confirmExit
