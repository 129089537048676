/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Spin, Switch } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useAdmins } from '../../hooks'
import { confirmExit, getUrl } from '../../utils'

export default function EditAdminModal({
	onClose,
	onEdit,
	onDelete,
	queryParams
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { fetchAdmin } = useAdmins()
	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form

	const checkFormChanged = useCallback(() => {
		const { _id, ...rest } = record || {}
		setFormHasChanged(!isEqual(getFieldsValue(), rest))
	}, [getFieldsValue, record])

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)

			Object.assign(values, { _id: record._id })

			onEdit(values)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	useEffect(() => {
		if (!modalIsVisible) return

		setIsFetching(true)
		fetchAdmin(queryParams.record_id)
			.then((data) => {
				setRecord(data)
				setFieldsValue(data)
			})
			.catch(() => {
				message.error("Couldn't fetch admin data")
				onClose()
			})
			.finally(() => setIsFetching(false))
	}, [
		onClose,
		fetchAdmin,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={
				!!(queryParams?.modal_is_open && queryParams?.mode === 'edit') || false
			}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold">Edit Admin</h3>}
			confirmLoading={isSubmitting}
			afterClose={afterClose}
			forceRender
			destroyOnClose
			centered
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						disabled={!formHasChanged}
						onClick={handleSubmitEdit}
						className="inline-flex flex-row items-center justify-center"
					>
						Save
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_admin"
					labelCol={{
						span: 5
					}}
					onFieldsChange={checkFormChanged}
					preserve={false}
				>
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: 'Please enter a name for new admin' }
						]}
					>
						<Input autoFocus type="text" prefix={<UserOutlined />} />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{ required: true, message: 'Please enter an email address' },
							{ type: 'email', message: 'Email is not valid' }
						]}
					>
						<Input type="text" prefix={<MailOutlined />} autoComplete="email" />
					</Form.Item>
					<Form.Item name="isSuper" label="Super Admin" valuePropName="checked">
						<Switch />
					</Form.Item>
					<Form.Item
						name="avatarIcon"
						key="avatarIcon"
						label="Avatar Icon"
						className="flex flex-row items-center"
					>
						<div>
							<SingleFileUploader
								type="admin"
								onlyPNG
								defaultFile={record?.avatar && getUrl(record.avatar?.path)}
								afterUpload={(path) => {
									form.setFieldsValue({ avatarIcon: path })
								}}
							/>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
EditAdminModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired
}
