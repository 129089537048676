/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

function useAdmins() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()
	const fetchAdmins = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin/admins?limit=100')
				.then(({ data: res }) => {
					const { rows: admins } = res.data
					setData(admins)
					resolve(admins)
				})
				.catch((err) => {
					reject(err)
				})
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchAdmin = useCallback(
		/**
		 * @param {string=} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error('admin ID not provided'))
				} else {
					setLoading(true)

					axios
						.get(`/admin/admins/${id}`)
						.then(({ data: res }) => {
							const admin = {
								...res.data,
								avatarSource: res.data.avatar?.path
							}
							resolve(admin)
						})
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const addNewAdmin = useCallback(
		({ avatarIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'adding new admin...',
					key: payload.title
				})

				const regex = /^temp\//i
				const hasTempInAvatar = regex.test(avatarIcon)
				if (hasTempInAvatar) Object.assign(payload, { avatar: avatarIcon })

				axios
					.post('/admin/admins', payload)
					.then(({ data: newAdmin }) => {
						message.success({
							content: 'new admin added',
							key: payload.title
						})
						enableUpdateStatistics()
						const admin = {
							...newAdmin,
							avatarSource: newAdmin.avatar?.path
						}
						enableUpdateStatistics()
						fetchAdmins()
						resolve(admin)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new admin',
							key: payload.title
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAdmins]
	)

	const deleteAdmin = useCallback(
		/**
		 * @param {string} id - admin _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting admin...', key: id })
				axios
					.delete(`/admin/admins/${id}`)
					.then(() => {
						message.success({
							content: 'admin deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchAdmins()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'deleting admin operation failed',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAdmins]
	)

	const editAdmin = useCallback(
		/**
		 * @param {EditAdminPayload} payload
		 */
		({ _id: adminId, avatarIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'updating admin data...',
					key: adminId
				})

				const regex = /^temp\//i
				const hasTempInAvatar = regex.test(avatarIcon)
				if (hasTempInAvatar) Object.assign(payload, { avatar: avatarIcon })

				axios
					.put(`/admin/admins/${adminId}`, payload)
					.then(({ data: res }) => {
						const { data: editedAdmin } = res
						const admin = {
							...res.data,
							avatarSource: editedAdmin.avatar?.path
						}
						message.success({ content: 'admin edited', key: adminId })
						enableUpdateStatistics()
						fetchAdmins()
						resolve(admin)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson',
							key: adminId
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAdmins]
	)

	return {
		data,
		isLoading,
		fetchAdmins,
		fetchAdmin,
		addNewAdmin,
		deleteAdmin,
		editAdmin
	}
}
export default useAdmins
