/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { useQuestionTypes } from '../../hooks'

import EditQTModal from './edit-btn'

function QuestionTypesPage() {
	const { isLoading, data, fetchQuestionTypes, editQuestionType } =
		useQuestionTypes()

	useEffect(() => {
		fetchQuestionTypes()
	}, [fetchQuestionTypes])

	const history = useHistory()
	const location = useLocation()

	const queryParams = useMemo(
		() => qs.parse(location.search),
		[location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history, location]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold max-w-">Question Types List</h1>
			</header>
			<Table
				className="mx-4"
				scroll={{
					y: 'calc(100vh - 219px)',
					x: 1152
				}}
				pagination={false}
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
			>
				<Table.Column dataIndex="name" title="Name" key="name" width={68} />

				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
				/>
				<Table.Column dataIndex="hint" title="Hint for Users" key="hint" />
				{/* <Table.Column
					dataIndex="button"
					title="CTA Button Text"
					key="button"
					width={124}
					className="text-center"
				/> */}
				<Table.Column
					dataIndex="correctFeedback"
					title="Correct Feedback"
					key="correctFeedback"
				/>
				<Table.Column
					dataIndex="incorrectFeedback"
					title="Incorrect Feedback"
					key="incorrectFeedback"
				/>
				<Table.Column
					dataIndex="tryAgainFeedback"
					title="Try Again Feedback"
					key="tryAgainFeedback"
				/>
				{/* <Table.Column
					dataIndex="hint_show_answer"
					title="Hint Show Answer"
					key="hint_show_answer"
					width={84}
					render={(val) => (
						<div className="flex items-center justify-center">
							{val ? (
								<CheckOutlined className="text-green-500" />
							) : (
								<CloseOutlined className="text-red-500" />
							)}
						</div>
					)}
				/> */}
				<Table.Column
					title="Actions"
					className="w-24 text-center"
					key="actions"
					width={68}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="edit-btn"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}
							/>
						</div>
					)}
				/>
			</Table>
			<EditQTModal
				onClose={handleCloseModal}
				onEdit={editQuestionType}
				queryParams={queryParams}
			/>
		</>
	)
}

export default QuestionTypesPage
