import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, Space } from 'antd'

export default function getSearchFilterProps(label, isInputNumber = false) {
	let searchInput

	return {
		filterDropdown: (props) => {
			// eslint-disable-next-line react/prop-types
			const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props

			return (
				<div className="p-2">
					{isInputNumber ? (
						<InputNumber
							placeholder={`Search in ${label}`}
							value={selectedKeys[0]}
							onChange={(value) => setSelectedKeys(value ? [value] : [])}
							onPressEnter={() => confirm()}
							className="w-48 mb-2 block"
							ref={(node) => {
								searchInput = node
							}}
						/>
					) : (
						<Input
							placeholder={`Search in ${label}`}
							value={selectedKeys[0]}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => confirm()}
							className="w-48 mb-2 block"
							ref={(node) => {
								searchInput = node
							}}
						/>
					)}
					<Space>
						<Button
							type="primary"
							onClick={confirm}
							icon={<SearchOutlined />}
							size="small"
							className="w-18 flex items-center"
						>
							Search
						</Button>
						<Button onClick={clearFilters} size="small" className="w-18">
							Reset
						</Button>
					</Space>
				</div>
			)
		},
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput?.select?.(), 100)
			}
		}
	}
}
