/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react'
import { Form, InputNumber, Modal, Switch } from 'antd'
import PropTypes from 'prop-types'

import { axios, confirmExit } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function AddNewAvatarModal({ visible, setVisible, onAdd }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()

	const closeModal = () => {
		const order = form.getFieldValue('order')
		form.resetFields()
		setVisible(false)
		setIsSubmitting(false)
		form.setFieldsValue({ order })
	}

	const handleAddingNewData = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const onCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)

		confirmExit(closeModal, !formIsEmpty)
	}

	useEffect(() => {
		axios.get('/admin/avatars/order').then((res) => {
			const order = res.data.data
			form.setFieldsValue({ order })
		})
	}, [form])

	return (
		<Modal
			visible={visible}
			okText="ADD NEW AVATAR"
			onOk={handleAddingNewData}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Avatar</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_avatar"
				initialValues={{
					order: null,
					avatarCategory: null,
					isFree: false,
					atShop: false,
					coins: null,
					gemLevel: null,
					lightIcon: null
				}}
				labelCol={{
					span: 6
				}}
				onFinish={handleAddingNewData}
			>
				<Form.Item name="order" label="Avatar ID">
					<InputNumber readOnly />
				</Form.Item>
				<Form.Item
					name="avatarCategory"
					label="Avatar Category"
					rules={[
						{
							required: true,
							message: 'Please input a category for avatar'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item name="isFree" label="For Free" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item name="atShop" label="In Shop" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item
					name="coins"
					label="Coins"
					rules={[
						{
							required: true,
							message: 'Please input the coins amount for purchase avatar'
						},
						{
							type: 'number',
							message: 'Coins amount should be a number'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					name="gemLevel"
					label="Gem Level"
					rules={[
						{
							required: true,
							message: 'Please input the gem level of avatar'
						},
						{
							type: 'number',
							message: 'Gem level should be a number'
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				{[
					{
						name: 'lightIcon',
						label: 'Icon'
					}
					/*
					,
					{
						name: 'icon_dark',
						label: 'Dark Icon'
					}
					*/
				].map((icon) => (
					<Form.Item
						name={icon.name}
						key={icon.name}
						label={icon.label}
						rules={[
							{
								required: icon.name === 'lightIcon',
								message: 'Please select icon file of avatar'
							}
						]}
						className="flex flex-row items-center"
					>
						<div className="flex flex-col items-start justify-start w-max">
							<SingleFileUploader
								type="avatar"
								onlyPNG
								afterUpload={(fileID) =>
									form.setFieldsValue({ [icon.name]: fileID })
								}
							/>
						</div>
					</Form.Item>
				))}
			</Form>
		</Modal>
	)
}
AddNewAvatarModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired
}
