import { SearchOutlined } from '@ant-design/icons'
import { Button, Select, Space } from 'antd'

export default function getSelectFilterProps(
	label,
	selectData,
	selectItemRenderer
) {
	let searchInput

	return {
		filterDropdown: (props) => {
			// eslint-disable-next-line react/prop-types
			const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props
			return (
				<div className="p-2 flex flex-col">
					<Select
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys([e])}
						placeholder={`Select ${label}`}
						className="mb-2"
						showSearch
						ref={(node) => {
							searchInput = node
						}}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{selectData.map((record) => selectItemRenderer(record))}
					</Select>
					<Space>
						<Button
							type="primary"
							icon={<SearchOutlined />}
							size="small"
							className="w-18 flex items-center"
							onClick={() => {
								confirm()
							}}
						>
							Search
						</Button>
						<Button
							onClick={() => clearFilters()}
							size="small"
							className="w-18"
						>
							Reset
						</Button>
					</Space>
				</div>
			)
		},
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					if (searchInput) searchInput?.focus?.()
				}, 100)
			}
		}
	}
}
