import { useMemo, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { useUser } from '../../store/hooks'
import { getUrl } from '../../utils'

export function UploadAvatar({ className, updateAvatarUrl }) {
	const [tempAvatar, setTempAvatar] = useState(null)
	const user = useUser()
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setLoading] = useState(false)

	const customRequest = ({ file }) => {
		setLoading(true)
		user
			.uploadAvatar({ file })
			.then((path) => {
				updateAvatarUrl(path)
				setTempAvatar(path)
			})
			.finally(() => setLoading(false))
	}

	/** @type {string=} */
	const avatarUrl = useMemo(() => {
		if (tempAvatar) return getUrl(tempAvatar)
		if (user.avatar) return getUrl(user.avatar.path)
		if (user.avatarUrl) return user.avatarUrl
		return undefined
	}, [tempAvatar, user.avatar, user.avatarUrl])
	return (
		<div
			className={cn('flex items-center justify-center', className, {
				'pointer-events-none cursor-wait': isLoading
			})}
		>
			<Spin spinning={isLoading}>
				<ImgCrop>
					<Upload.Dragger
						className="rounded-full overflow-hidden flex items-center justify-center w-24 h-24"
						listType="picture-card"
						accept="image/png, image/jpeg"
						maxCount={1}
						multiple={false}
						showUploadList={false}
						customRequest={customRequest}
					>
						{avatarUrl ? (
							<img src={avatarUrl} alt="avatar" className="w-full" />
						) : (
							<div className="flex flex-col items-center justify-center">
								{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
								<span className="mt-2">Upload</span>
							</div>
						)}
					</Upload.Dragger>
				</ImgCrop>
			</Spin>
		</div>
	)
}
UploadAvatar.propTypes = {
	className: PropTypes.string,
	updateAvatarUrl: PropTypes.func.isRequired
}
UploadAvatar.defaultProps = {
	className: undefined
}
export default UploadAvatar
