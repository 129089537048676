/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import { EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { useTrophies } from '../../hooks'
import { useUser } from '../../store/hooks'
import { getUrl } from '../../utils'

import TrophyModal from './trophy-modal'

const ADD_MODAL = {
	title: 'New Trophy',
	ctaBtnText: 'ADD NEW TROPHY'
}
const EDIT_MODAL = {
	title: 'Edit Trophy',
	ctaBtnText: 'Save'
}

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

function QuestionTypesPage() {
	const { isSuper } = useUser()

	const {
		data,
		totalCount,
		isLoading,
		fetchTrophies,
		addNewTrophy,
		deleteTrophy,
		editTrophy,
		reorderTrophy
	} = useTrophies()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const [modal, setModal] = useState()

	useEffect(() => {
		if (queryParams.mode === 'edit') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (pagination) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchTrophies(params)
	}

	useEffect(() => {
		fetchTrophies(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchTrophies])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = useCallback(
		({ oldIndex, newIndex }) => {
			if (oldIndex !== newIndex) {
				reorderTrophy({
					_id: data[oldIndex]._id,
					destination: data[newIndex]._id
				})
			}
		},
		[data, reorderTrophy]
	)

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	const DraggableContainer = useCallback(
		(props) => (
			<SortableContainer
				lockAxis="y"
				useDragHandle
				disableAutoscroll
				helperClass="row-dragging"
				helperContainer={() => document.querySelector('.ant-table-tbody')}
				onSortEnd={onSortEnd}
				{...props}
			/>
		),
		[onSortEnd]
	)

	const DraggableBodyRow = useCallback(
		({ ...restProps }) => {
			// function findIndex base on Table rowKey props and should always be a right array index
			const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
			return <SortableItem index={index} key={index} {...restProps} />
		},
		[data]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Trophies List</h1>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				loading={isLoading}
				dataSource={data}
				className="mx-4"
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 1024
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
				tableLayout="fixed"
				size="small"
				rowKey={(record) => record._id}
			>
				{!isSuper ? null : (
					<Table.Column
						dataIndex="sort"
						key="sort"
						width={48}
						className="drag-handle-col text-center"
						title={
							<Button
								type={reordering ? 'primary' : 'default'}
								icon={<MenuOutlined />}
								className="w-full h-full flex items-center justify-center"
								onClick={() => setReordering((prev) => !prev)}
							/>
						}
						render={reordering ? () => <DragHandle /> : null}
					/>
				)}
				<Table.Column
					dataIndex="order"
					title="Order"
					key="order"
					width={56}
					className="text-center"
				/>
				<Table.Column dataIndex="name" title="Name" key="name" />
				<Table.Column
					dataIndex="description"
					title="Description"
					key="description"
				/>
				<Table.Column
					dataIndex="event"
					title="Level 1"
					key="data"
					className="text-center"
					render={(text, record) => text || record.level1}
				/>
				<Table.Column
					dataIndex="level2"
					title="Level 2"
					key="level2"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level3"
					title="Level 3"
					key="level3"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level4"
					title="Level 4"
					key="level4"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="level5"
					title="Level 5"
					key="level5"
					className="text-center"
					width={74}
				/>
				<Table.Column
					dataIndex="icon"
					title="Icon"
					key="icon"
					className="text-center"
					render={(val) => {
						const source = getUrl(val.path)
						return (
							<div className="h-8 overflow-hidden">
								{val ? (
									<img
										src={source}
										alt={val.type}
										className="h-full w-auto mx-auto"
									/>
								) : (
									'not set'
								)}
							</div>
						)
					}}
				/>
				<Table.Column
					title="Actions"
					className="w-24 text-center"
					key="actions"
					width={68}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}}
							/>
						</div>
					)}
				/>
			</Table>
			<TrophyModal
				onClose={handleCloseModal}
				title={modal?.title}
				ctaBtnText={modal?.ctaBtnText}
				onEdit={editTrophy}
				onAdd={addNewTrophy}
				onDelete={deleteTrophy}
				queryParams={queryParams}
			/>
		</>
	)
}

export default QuestionTypesPage
