/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react'
import { Form, InputNumber, Select, Spin, Switch } from 'antd'
import { arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types'
import qs from 'query-string'

import { useLessons, useLevels } from '../../hooks'
import { axios } from '../../utils'

export default function QuestionFixedDataSelector({
	formInstance,
	subjects,
	subjectsIsLoading,
	questionTypes,
	questionTypesIsLoading,
	setSelectedType,
	mode,
	initialValues,
	resetQuestionDetails,
	modalIsVisible,
	isFetching
}) {
	const [state, setState] = useState({
		selectedSubject: undefined,
		selectedLevel: undefined,
		selectedLesson: undefined,
		filteredLevels: [],
		filteredLessons: []
	})
	const updateState = (newState) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()
	const { isLoading: lessonsIsLoading, fetchLessons } = useLessons()

	const filterLevels = useCallback(() => {
		fetchLevels().then((levels) => {
			updateState({ filteredLevels: levels ?? [] })
		})
	}, [fetchLevels])

	const filterLessons = useCallback(
		(levelID) => {
			fetchLessons({ level: levelID, limit: 9999 }).then((lessons) => {
				updateState({ filteredLessons: lessons || [] })
			})
		},
		[fetchLessons]
	)

	useEffect(() => {
		if (state.selectedSubject) {
			filterLevels(state.selectedSubject)
		}
	}, [filterLevels, state.selectedSubject])

	useEffect(() => {
		if (state.selectedLevel) {
			filterLessons(state.selectedLevel)
		}
	}, [filterLessons, state.selectedLevel])

	const [loadingOrder, setLoadingOrder] = useState(false)
	const [order, setOrder] = useState(null)

	const onGetOrderNo = useCallback((level, lesson) => {
		if (!level || !lesson) return

		setLoadingOrder(true)
		const endpoint = `/admin/micro-lessons/order?${qs.stringify({
			level,
			lesson
		})}`
		axios
			.get(endpoint)
			.then((res) => setOrder(res.data.data))
			.finally(() => setLoadingOrder(false))
	}, [])

	useEffect(() => {
		if (modalIsVisible) {
			if (
				Object.keys(initialValues).length &&
				mode === 'edit_question' &&
				isFetching
			) {
				const {
					status,
					order: seq,
					level: { _id: levelId },
					lesson: {
						_id: lessonId,
						subject: { _id: subjectId }
					}
				} = initialValues || {}
				updateState({
					selectedSubject: subjectId,
					selectedLevel: levelId
				})
				setOrder(seq)
				formInstance.setFieldsValue({
					...initialValues,
					status: status === 'active',
					subject: subjectId,
					level: levelId,
					lesson: lessonId
				})
			}
		} else {
			updateState({
				selectedSubject: undefined,
				selectedLevel: undefined
			})
			setOrder(null)
			formInstance.setFieldsValue({ order: undefined })
		}
	}, [formInstance, initialValues, isFetching, modalIsVisible, mode])

	return (
		<Form
			form={formInstance}
			name="select_micro_lesson_data"
			initialValues={{
				status: true
			}}
		>
			<div className="w-full flex flex-row">
				<Form.Item
					name="subject"
					label="Subject"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						value={state.selectedSubject}
						disabled={mode === 'edit_question'}
						notFoundContent={
							subjectsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({
								selectedSubject: e,
								selectedLevel: undefined,
								filteredLevels: [],
								filteredLessons: []
							})
							formInstance.setFieldsValue({
								level: undefined,
								lesson: undefined,
								order: undefined
							})
							setOrder(null)
						}}
					>
						{subjects?.map((subject) => (
							<Select.Option value={subject._id} key={subject._id}>
								{subject.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="level"
					label="Level"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Select
						disabled={!state.selectedSubject || mode === 'edit_question'}
						notFoundContent={
							levelsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(e) => {
							updateState({ selectedLevel: e, filteredLessons: [] })
							formInstance.setFieldsValue({
								lesson: undefined,
								order: undefined
							})
							setOrder(null)
						}}
					>
						{state.filteredLevels.map((level) => (
							<Select.Option value={level._id} key={level._id}>
								{level.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="lesson"
					label="Lesson"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a lesson for micro-lesson'
						}
					]}
				>
					<Select
						disabled={!state.selectedLevel || mode === 'edit_question'}
						notFoundContent={
							lessonsIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(lesson) => {
							updateState({ selectedLesson: lesson })
							onGetOrderNo(state.selectedLevel, lesson)
						}}
					>
						{state.filteredLessons.map((lesson) => (
							<Select.Option value={lesson._id} key={lesson._id}>
								{lesson.priority} - {lesson.title}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</div>
			<div className="w-full flex flex-row">
				<Form.Item
					name="type"
					label="Question Type"
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
					rules={[
						{
							required: true,
							message: 'Please select a question type for micro-lesson'
						}
					]}
				>
					<Select
						notFoundContent={
							questionTypesIsLoading ? <Spin size="small" /> : undefined
						}
						onChange={(value) => {
							const type = questionTypes.find((el) => el._id === value)
							setSelectedType(type)

							resetQuestionDetails()
						}}
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{questionTypes?.map((qt) => (
							<Select.Option value={qt._id} key={qt._id}>
								{qt.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name="order"
					label="Micro Lesson NO."
					labelAlign="left"
					className="flex flex-col flex-1 mr-2"
				>
					<Spin spinning={loadingOrder}>
						<InputNumber disabled value={order} className="w-full" />
					</Spin>
				</Form.Item>
				<Form.Item
					name="status"
					label="Active"
					labelAlign="left"
					className="flex flex-col flex-1"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
			</div>
		</Form>
	)
}
QuestionFixedDataSelector.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	subjects: arrayOf(
		shape({
			_id: string,
			title: string
		})
	).isRequired,
	subjectsIsLoading: bool.isRequired,
	questionTypes: arrayOf(
		shape({
			_id: string,
			name: string
		})
	).isRequired,
	questionTypesIsLoading: bool.isRequired,
	mode: oneOf(['add', 'edit_question']).isRequired,
	setSelectedType: func.isRequired,
	resetQuestionDetails: func.isRequired,
	modalIsVisible: bool.isRequired,
	isFetching: bool.isRequired
}
