import { string } from 'prop-types'

export function ViewListCard(props) {
	const { title, amount } = props
	return (
		<div className="dashboard-card flex-1 h-44 m-2 bg-white flex flex-col items-center justify-center shadow-md rounded-md overflow-hidden">
			<span className="text-inherit font-bold text-4xl">{amount}</span>
			<h3 className="text-inherit font-normal">{title}</h3>
		</div>
	)
}

ViewListCard.propTypes = {
	title: string.isRequired,
	amount: string.isRequired
}
export default ViewListCard
