import { object, string } from 'prop-types'

import {
	ARQuestion,
	ASorAASQuestion,
	CLSQuestion,
	DWQuestion,
	FBOrFBTQuestion,
	ISQuestion,
	MASorMISQuestion,
	MCorMRorMCIQuestion,
	MTPQuestion,
	NUMQuestion,
	SHAQuestion,
	SQQuestion,
	STSorEATQuestion,
	TFQuestion
} from '../questions'

export default function QuestionDetailsSelector({
	formInstance,
	selectedTypeName,
	initialValues
}) {
	switch (selectedTypeName) {
		case 'MC':
		case 'MR':
		case 'MCI':
			return (
				<MCorMRorMCIQuestion
					formInstance={formInstance}
					type={selectedTypeName}
					initialValues={initialValues}
				/>
			)
		case 'STS':
		case 'EAT':
			return (
				<STSorEATQuestion
					formInstance={formInstance}
					type={selectedTypeName}
					initialValues={initialValues}
				/>
			)
		case 'TF':
			return (
				<TFQuestion formInstance={formInstance} initialValues={initialValues} />
			)
		case 'AR':
			return (
				<ARQuestion formInstance={formInstance} initialValues={initialValues} />
			)
		case 'FBT':
		case 'FB':
			return (
				<FBOrFBTQuestion
					type={selectedTypeName}
					formInstance={formInstance}
					initialValues={initialValues}
				/>
			)
		case 'MTP':
			return (
				<MTPQuestion
					formInstance={formInstance}
					initialValues={initialValues}
				/>
			)
		case 'SQ':
			return (
				<SQQuestion formInstance={formInstance} initialValues={initialValues} />
			)
		case 'DW':
			return (
				<DWQuestion formInstance={formInstance} initialValues={initialValues} />
			)
		case 'IS':
			return (
				<ISQuestion formInstance={formInstance} initialValues={initialValues} />
			)
		case 'AS':
		case 'AAS':
			return (
				<ASorAASQuestion
					formInstance={formInstance}
					initialValues={initialValues}
					type={selectedTypeName}
				/>
			)
		case 'MAS':
		case 'MIS':
			return (
				<MASorMISQuestion
					formInstance={formInstance}
					initialValues={initialValues}
					type={selectedTypeName}
				/>
			)
		case 'NUM':
			return (
				<NUMQuestion
					formInstance={formInstance}
					initialValues={initialValues}
				/>
			)
		case 'SHA':
			return (
				<SHAQuestion
					formInstance={formInstance}
					initialValues={initialValues}
				/>
			)
		case 'CLS':
			return (
				<CLSQuestion
					formInstance={formInstance}
					initialValues={initialValues}
				/>
			)
		default:
			return null
	}
}
QuestionDetailsSelector.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	selectedTypeName: string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object
}
QuestionDetailsSelector.defaultProps = {
	initialValues: undefined
}
