import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { useUser } from '../../store/hooks'
import GuestRouter from '../guest-router'
import UserRouter from '../user-router'

export default function MainRouter() {
	const [isLoading, setLoading] = useState(true)

	const { loggedIn } = useUser()

	useEffect(() => {
		loggedIn().finally(() => setLoading(false))
	}, [loggedIn])
	const { isAuthenticated } = useSelector((store) => store.user, shallowEqual)

	if (isLoading) return null

	if (isAuthenticated) return <UserRouter />
	return <GuestRouter />
}
