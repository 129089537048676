/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import moment from 'moment'
import qs from 'query-string'

import { getSearchFilterProps, getSelectFilterProps } from '../../components'
import { useUsers } from '../../hooks'
import { useUser } from '../../store/hooks'

import ViewUserModal from './view-user-modal'

export default function UsersPage() {
	const { isLoading, data, count, fetchUsers } = useUsers()
	const { isSuper } = useUser()

	const history = useHistory()
	const location = useLocation()
	const queryParams = useMemo(
		() => qs.parse(location.search),
		[location.search]
	)

	const setModalVisibility = (isVisible, qParams) => {
		const params = qs.parse(location.search)
		if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
		else {
			delete params.modal_is_open
			delete params.mode
			delete params.record_id
		}

		history.replace({
			search: qs.stringify(params)
		})
	}

	useEffect(() => {
		fetchUsers(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchUsers])

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) Object.assign(params, { [key]: value[0] })
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}
		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchUsers(params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}

	const getFilterValue = (parameter) => {
		let value
		if (parameter === 'verified') {
			value = queryParams[parameter]
				? [queryParams[parameter] === 'true' ? 'Verified' : 'Not Verified']
				: null
		} else {
			value = queryParams[parameter] ? [queryParams[parameter]] : null
		}

		return value
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchUsers()
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Users List</h1>
				<Button
					disabled={resetFiltersDisabled}
					onClick={resetFilters}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
			</header>
			<Table
				className="mx-4"
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 800
				}}
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
				pagination={{
					position: ['bottomCenter'],
					total: count,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					dataIndex="name"
					title="Name"
					key="name"
					filteredValue={getFilterValue('name')}
					{...getSearchFilterProps('name')}
				/>
				<Table.Column
					dataIndex="email"
					title="Email"
					key="email"
					filteredValue={getFilterValue('email')}
					{...getSearchFilterProps('email')}
				/>
				<Table.Column
					render={(value) => value && moment(value).format('DD-MMM-YYYY')}
					dataIndex="createdAt"
					title="Date of Joining"
					key="createdAt"
					filteredValue={getFilterValue('createdAt')}
					sorter
					sortOrder={getSortOrder('sort_createdAt')}
					{...getSearchFilterProps('YYYY-MM-DD')}
				/>
				<Table.Column
					dataIndex="verified"
					title="Verified"
					key="verified"
					width={84}
					{...getSelectFilterProps(
						'verified status',
						[
							{
								key: `id-${Date.now()}-verified-index-0`,
								_id: true,
								title: 'Verified'
							},
							{
								key: `id-${Date.now()}-verified-index-1`,
								_id: false,
								title: 'Not Verified'
							}
						],
						(record) => (
							<Select.Option key={record.key} value={record._id}>
								{record.title}
							</Select.Option>
						)
					)}
					filteredValue={getFilterValue('verified')}
					render={(val) => (
						<div className="flex items-center justify-center">
							{val ? (
								<CheckOutlined className="text-green-500" />
							) : (
								<CloseOutlined className="text-red-500" />
							)}
						</div>
					)}
				/>
				<Table.Column
					title="Actions"
					className="text-center"
					key="actions"
					width={84}
					// eslint-disable-next-line consistent-return
					render={(_val, record) => {
						if (record.children)
							return (
								<div className="flex items-center justify-center">
									<Button
										type="default"
										icon={<EditOutlined />}
										className="edit-btn bg-gray-200 hover:border-transparent"
										onClick={() => {
											setModalVisibility(true, {
												mode: 'view_user',
												record_id: record._id
											})
										}}
									/>
								</div>
							)
					}}
				/>
			</Table>
			<ViewUserModal
				onClose={() => setModalVisibility(false)}
				queryParams={queryParams}
				reloader={fetchUsers}
				isSuper={isSuper}
			/>
		</>
	)
}
