import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
	EyeInvisibleOutlined,
	EyeTwoTone,
	LockOutlined
} from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import cn from 'classnames'

import logo from '../../assets/img/logo.png'
import { useUser } from '../../store/hooks'

import styles from './styles.module.scss'

export default function ResetPasswordPage() {
	const [form] = Form.useForm()
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)

	const { token } = useParams()
	const user = useUser()

	const handleSubmit = () => {
		setIsLoading(true)
		form
			.validateFields()
			.then(({ password }) => {
				user
					.resetPassword(token, password)
					.then(() => {
						message.success('Your password has been reset successfully')
						history.replace('/')
					})
					.finally(() => setIsLoading(false))
			})
			.catch(() => setIsLoading(false))
	}

	return (
		<div
			className={cn(
				styles['resetPassword-page'],
				'resetPassword-page h-full w-full flex flex-col items-center justify-center'
			)}
		>
			<div className="max-w-xs m-4 hidden sm:block">
				<img
					src={logo}
					alt="Deenee logo"
					className="h-full object-contain mr-5"
				/>
			</div>
			<div className="w-full h-full flex flex-col items-center justify-center sm:w-2/3 md:w-3/5 lg:w-1/2 2xl:w-1/3 sm:h-auto bg-white px-12 py-5 sm:m-4 bg-opacity-95 rounded-sm shadow-xl">
				<h1 className="text-xl">Reset Password</h1>
				<p className="text-l mb-5" style={{ textAlign: 'center' }}>
					Please enter your new password below to reset it.
				</p>
				<Form
					form={form}
					name="login"
					className="w-full"
					initialValues={{ remember: true }}
					onFinish={handleSubmit}
				>
					<Form.Item
						name="password"
						rules={[
							{ required: true, message: 'Please input your Password' },
							{ min: 6, message: 'Password must be at least 6 characters' }
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="form-item-icon" />}
							placeholder="New Password"
							iconRender={(visible) =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>
					<Form.Item
						name="confirm"
						rules={[
							{ required: true, message: 'Please confirm your Password' },
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve()
									}
									// eslint-disable-next-line prefer-promise-reject-errors
									return Promise.reject(
										'The two passwords that you entered do not match!'
									)
								}
							})
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="form-item-icon" />}
							placeholder="Confirm Password"
							iconRender={(visible) =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>
					<Form.Item name="submit">
						<Button
							type="primary"
							htmlType="submit"
							className="flex items-center justify-center"
							block
							size="large"
							loading={isLoading}
						>
							Save
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}
