import { useCallback, useState } from 'react'
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	LockOutlined
} from '@ant-design/icons'
import { Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'

import { useUser } from '../../store/hooks'
import { confirmExit } from '../../utils'

import { UploadAvatar } from './upload-avatar'

export function ProfileModal(props) {
	const { visible, setVisible } = props
	const hideModal = () => setVisible(false)

	const user = useUser()
	const [avatar, setAvatar] = useState(undefined)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [passwordIsVisible, setPasswordVisible] = useState(false)
	const [form] = Form.useForm()
	const [dataHasChanged, setDataHasChanged] = useState(false)

	const detectFormHasChanged = useCallback(() => {
		const data = form.getFieldsValue()
		const { name, avatarUrl } = user
		const hasChanged = data.name !== name || avatar !== avatarUrl
		setDataHasChanged(hasChanged)
	}, [form, user, avatar])

	const togglePasswordVisibility = () => setPasswordVisible((state) => !state)

	/**
	 * @param {string} url
	 * @returns {void}
	 * */
	const updateAvatarUrl = (url) => {
		setAvatar(url)
		detectFormHasChanged()
	}

	const handleEditProfile = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			const payload = { name: values.name }
			if (values.password) payload.password = values.password
			if (avatar) payload.avatar = avatar
			user
				.updateProfile(payload)
				.then((data) => {
					user.updateState(data)
				})
				.finally(() => {
					hideModal()
					setIsSubmitting(false)
				})
		})
	}

	const handleCancelEditing = () => {
		confirmExit(hideModal, dataHasChanged)
	}

	return (
		<Modal
			visible={visible}
			okText="Edit"
			onOk={handleEditProfile}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none',
				disabled: !dataHasChanged
			}}
			onCancel={handleCancelEditing}
			confirmLoading={isSubmitting}
			title="Profile"
			destroyOnClose
		>
			<UploadAvatar className="mb-4" updateAvatarUrl={updateAvatarUrl} />
			<Form
				form={form}
				name="edit_profile"
				initialValues={{
					name: user.name,
					password: '',
					confirmPassword: ''
				}}
				onFinish={handleEditProfile}
				onFieldsChange={detectFormHasChanged}
				labelCol={{
					span: 8
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input your name!'
						}
					]}
				>
					<Input placeholder="Name" type="text" />
				</Form.Item>
				<Form.Item
					name="password"
					hasFeedback
					label="Password"
					rules={[
						{
							pattern: /[0-9a-zA-Z?!@#$%^&*-_]/,
							message:
								'Only numbers, english letters and special characters is allowed'
						}
					]}
				>
					<Input
						allowClear
						autoComplete="new-password"
						dir="ltr"
						type={passwordIsVisible ? 'text' : 'password'}
						prefix={<LockOutlined />}
						suffix={
							<button
								type="button"
								className="focus:text-blue-500 ml-1 flex items-center justify-center outline-none focus:outline-black border-none cursor-pointer bg-inherit"
								onClick={togglePasswordVisibility}
							>
								{passwordIsVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
							</button>
						}
					/>
				</Form.Item>
				<Form.Item
					name="confirmPassword"
					label="Confirm Password"
					hasFeedback
					dependencies={['password']}
					rules={[
						({ getFieldValue }) => ({
							validator(_rule, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve()
								}
								return Promise.reject(Error('Passwords do not match'))
							}
						})
					]}
				>
					<Input
						allowClear
						dir="ltr"
						type={passwordIsVisible ? 'text' : 'password'}
						prefix={<LockOutlined />}
						suffix={
							<button
								type="button"
								className="focus:text-blue-500 ml-1 flex items-center justify-center outline-none focus:outline-black border-none cursor-pointer bg-inherit"
								onClick={togglePasswordVisibility}
							>
								{passwordIsVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
							</button>
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
ProfileModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired
}
export default ProfileModal
