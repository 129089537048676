import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectSidebar, sidebarSlice } from '../reducers/sidebar'

const { actions } = sidebarSlice

const useSidebar = () => {
	const sidebar = useSelector(selectSidebar, shallowEqual)
	const dispatchSidebar = useDispatch()

	const setIsCollapsed = useCallback(
		(isCollapsed) => {
			dispatchSidebar(actions.setIsCollapsed(isCollapsed))
		},
		[dispatchSidebar]
	)

	return {
		isCollapsed: sidebar.isCollapsed,
		setIsCollapsed
	}
}

export default useSidebar
