/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
	Button,
	Col,
	Empty,
	Form,
	Input,
	message,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Tabs
} from 'antd'
import moment from 'moment'
import { bool, func } from 'prop-types'

import { useUsers } from '../../hooks'
import { axios } from '../../utils'

import styles from './styles.module.scss'

const minToHour = (min) => {
	return `${Math.floor(min / 60)}h ${min % 60}m`
}

const confirmDelete = (setLoading, user, closer, reloader) => {
	const { confirm } = Modal
	return confirm({
		title: 'Delete confirmation',
		icon: <ExclamationCircleOutlined />,
		content:
			'You are about to delete this user. This action is irreversible. Are you sure about this?',
		okText: 'Delete',
		okType: 'danger',
		onOk() {
			setLoading(true)
			axios
				.delete(`/admin/users/${user?._id}`)
				.then(({ data: res }) => {
					message.success(res.message)
				})
				.finally(() => {
					setLoading(false)
					closer()
					reloader()
				})
		},
		onCancel() {
			// do nothing
		}
	})
}

const WeeklyPlayReportSection = ({ title, first, second }) => {
	return (
		<div
			style={{
				padding: 12,
				borderRadius: 5,
				backgroundColor: 'rgb(241, 247, 253)'
			}}
		>
			<h1 className="font-bold">{title}</h1>
			<div>
				<div
					className={styles.flex_spaceBetween}
					style={{
						margin: 0
					}}
				>
					<p className="m-0">{first.title}</p>
					<p className="font-bold m-0">{first.value}</p>
				</div>
				<div
					className={`${styles.flex_spaceBetween} m-0`}
					style={{
						margin: 0
					}}
				>
					<p className="m-0">{second.title}</p>
					<p className="font-bold m-0">{second.value}</p>
				</div>
			</div>
		</div>
	)
}

const WeeklyPlayReport = ({ child }) => {
	const { streaks, lessons, trophies, gems, coins, quizes, learningTime } =
		child.weeklyScore

	return (
		<Col span={12}>
			<div
				className={styles.flex}
				style={{
					flexDirection: 'column'
				}}
			>
				<h1
					className={`m-0 ${styles.center_extAlign}`}
					style={{ fontSize: 24, fontWeight: 700 }}
				>
					{child?.name}
				</h1>
				<Row gutter={[8, 8]}>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Lessons"
							first={{ title: 'This week', value: lessons.thisWeek }}
							second={{ title: 'Total', value: lessons.all }}
						/>
					</Col>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Streaks"
							first={{ title: 'Current', value: streaks.current }}
							second={{ title: 'Highest', value: streaks.highest }}
						/>
					</Col>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Trophies"
							first={{ title: 'This week', value: trophies.thisWeek }}
							second={{ title: 'Total', value: trophies.all }}
						/>
					</Col>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Gems"
							first={{ title: 'This week', value: gems.thisWeek }}
							second={{ title: 'Total', value: gems.all }}
						/>
					</Col>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Coins"
							first={{ title: 'This week', value: coins.thisWeek }}
							second={{ title: 'Balance', value: coins.all }}
						/>
					</Col>
					<Col span={12}>
						<WeeklyPlayReportSection
							title="Quiz"
							first={{ title: 'This week', value: quizes.thisWeek }}
							second={{ title: 'Total', value: quizes.all }}
						/>
					</Col>
					<Col span={24}>
						<h1
							className={`${styles.center_extAlign} font-bold`}
							style={{
								padding: 5,
								borderRadius: 5,
								border: '2px solid #1ae',
								backgroundColor: 'rgb(241, 247, 253)'
							}}
						>
							Total learning time: {minToHour(learningTime.inMinutes)}
						</h1>
					</Col>
				</Row>
			</div>
		</Col>
	)
}

const TotalPlayReport = ({ child }) => {
	const {
		overAllSubjectProgress: { subjects, learningTime },
		overAllQuizProgress
	} = child

	const ShowLesson = ({ title, level, lessonStatus: [passed, total] }) => {
		return (
			<div className={styles.flex_spaceBetween}>
				<Space>
					<p className="m-0">{title}</p>
					<p className="m-0">level: {level}</p>
				</Space>
				<p className="m-0">
					lesson: {passed}/{total}
				</p>
			</div>
		)
	}

	return (
		<Col span={12}>
			<div
				className={styles.flex}
				style={{
					flexDirection: 'column'
				}}
			>
				<h1
					className={`m-0 ${styles.center_extAlign}`}
					style={{ fontSize: 24, fontWeight: 700 }}
				>
					{child?.name}
				</h1>
				<div
					style={{
						padding: 12,
						borderRadius: 5,
						backgroundColor: 'rgb(241, 247, 253)'
					}}
				>
					<Row>
						<Col span={24}>
							{subjects?.map((subject) => {
								return (
									<ShowLesson
										title={subject?.title}
										level={subject?.currentLevel}
										lessonStatus={[subject?.passed, subject?.total]}
									/>
								)
							})}
						</Col>
					</Row>
					<div className="m-0 mt-4">
						<div>
							<div className={styles.flex_spaceBetween}>
								<p className="m-0">Quiz:</p>
								<p className="m-0 font-bold">
									{overAllQuizProgress?.percent ?? 0}
								</p>
							</div>
							<div className={styles.flex_spaceBetween}>
								<p className="m-0">Correct:</p>
								<p className="m-0 font-bold">{overAllQuizProgress?.correct}</p>
							</div>
							<div
								className={`${styles.flex_spaceBetween} mb-4`}
								style={{ borderBottom: '1px solid #000' }}
							>
								<p className="m-0">Incorrect:</p>
								<p className="m-0 font-bold">
									{overAllQuizProgress?.incorrect}
								</p>
							</div>
						</div>
						<div className={`${styles.flex_spaceBetween} font-bold`}>
							<p className="m-0">Total learning time:</p>
							<p className="m-0">{minToHour(learningTime?.inMinutes)}</p>
						</div>
					</div>
				</div>
			</div>
		</Col>
	)
}

export const DisplayObject = ({
	isSuper,
	user,
	reloader,
	queryParams,
	setUser,
	setIsFetching,
	signal,
	setSignal,
	tableReloader,
	closer
}) => {
	const [form] = Form.useForm()
	const [newPassForm] = Form.useForm()
	const { TabPane } = Tabs

	const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false)
	const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false)

	useEffect(() => {
		form.setFieldsValue({
			email: user?.email,
			name: user?.name
		})
	}, [user])

	useEffect(() => {
		if (signal) {
			setIsFetching(true)
			form
				.validateFields()
				.then(({ name, email }) => {
					axios
						.put(`/admin/users/${user?._id}`, { name, email })
						.then(() => {
							message.success('Changes saved.')
							reloader(queryParams.record_id).then((userData) => {
								setUser(userData)
							})
							tableReloader(queryParams)
							closer()
						})
						.finally(() => {
							setIsFetching(false)
						})
				})
				.finally(() => {
					setSignal(false)
				})
		}
	}, [signal])

	const sendEmailHandler = useCallback((email) => {
		setSendEmailIsLoading(true)
		axios
			.post('/auth/reset/password', {
				email,
				role: 'user'
			})
			.then(({ data }) => {
				message.success(data.message)
			})
			.finally(() => {
				sendEmailIsLoading(false)
			})
	}, [])

	const changePasswordHandler = useCallback(() => {
		if (!user) return

		newPassForm.validateFields().then(({ newPassword: password }) => {
			setChangePasswordIsLoading(true)
			axios
				.put(`/admin/users/${user._id}`, { password })
				.then(() => {
					message.success('Password changed successfully!')
				})
				.finally(() => {
					setChangePasswordIsLoading(false)
					newPassForm.resetFields()
				})
		})
	}, [user])

	const sub = (value) => {
		if (value === '1-month') return 'one'
		if (value === '6-months') return 'six'
		if (value === '1-year') return 'twelve'
		return 'none'
	}

	const subscriptionChangeHandler = (value) => {
		setIsFetching(true)
		axios
			.put('/admin/user', {
				user_id: user?._id,
				subscription: sub(value)
			})
			.then(() => {
				message.success('Subscription changed successfully!')
				reloader(queryParams.record_id).then((userData) => {
					setUser(userData)
				})
			})
			.finally(() => {
				setIsFetching(false)
			})
	}

	const subscriptionStatus = () => {
		switch (user?.subscription?.output?.data?.state?.productId) {
			case 'test1':
			case 'one':
				return '1-month'
			case 'test2':
			case 'six':
				return '6-months'
			case 'test3':
			case 'twelve':
				return '1-year'
			default:
				return 'unsubscribed'
		}
	}

	const subscriptionStatusTitle = () => {
		switch (subscriptionStatus()) {
			case '1-month':
				return '1 Month'
			case '6-months':
				return '6 Months'
			case '1-year':
				return '1 Year'
			default:
				return 'Free trial'
		}
	}

	const subscriptionTypes = [
		{
			value: 'unsubscribed',
			label: 'Free trial'
		},
		{
			value: '1-month',
			label: '1 month'
		},
		{
			value: '6-months',
			label: '6 months'
		},
		{
			value: '1-year',
			label: '1 year'
		}
	]

	const historyOfLogins = user?.activities?.map((act, idx) => {
		return {
			key: act._id,
			id: idx + 1,
			date: moment(act?.createdAt).format('DD-MMM-YYYY'),
			time: moment(act?.createdAt).format('hh:mm:ss A')
		}
	})

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date'
		},
		{
			title: 'Time',
			dataIndex: 'time',
			key: 'time'
		}
	]

	return (
		<Form form={form}>
			<Tabs tabPosition="left">
				<TabPane tab="User details" key="details">
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{
								required: true,
								message: 'Please enter name!'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								type: 'email',
								message: 'The input is not valid E-mail!'
							}
						]}
					>
						<Input />
					</Form.Item>
					<div className={styles.flex} style={{ gap: 5 }}>
						<Form form={newPassForm}>
							<Form.Item
								name="newPassword"
								label="New Password"
								rules={[
									{
										required: true,
										message: 'This field cannot be empty!'
									},
									{
										min: 6,
										message: 'Password must be at least 6 characters!'
									}
								]}
							>
								<Input.Password autoComplete="false" />
							</Form.Item>
						</Form>
						<Button
							disabled={!isSuper}
							loading={changePasswordIsLoading}
							onClick={changePasswordHandler}
						>
							Change
						</Button>
						<Button
							disabled
							loading={sendEmailIsLoading}
							onClick={sendEmailHandler}
						>
							Send password reset email
						</Button>
					</div>
				</TabPane>
				<TabPane tab="History of logins" key="logins">
					<Table
						dataSource={historyOfLogins}
						columns={columns}
						rowKey={(record) => record._id}
						pagination={{
							position: ['bottomCenter'],
							pageSize: 5,
							showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
						}}
					/>
				</TabPane>
				<TabPane tab="History of play" key="plays">
					<Tabs>
						<TabPane tab="This Week" key="week">
							<Row gutter={[20, 0]}>
								{user?.children?.map((child) => (
									<WeeklyPlayReport child={child} />
								))}
								{!user?.children?.length && (
									<Empty description={<p>No children</p>} />
								)}
							</Row>
						</TabPane>
						<TabPane tab="Total" key="total">
							<Row gutter={30}>
								{user?.children?.map((child) => (
									<TotalPlayReport child={child} />
								))}
								{!user?.children?.length && (
									<Empty description={<p>No children</p>} />
								)}
							</Row>
						</TabPane>
					</Tabs>
				</TabPane>
				<TabPane tab="Subscription status" key="subscription">
					<div
						className={styles.flex_spaceBetween}
						style={{
							flexWrap: 'wrap'
						}}
					>
						<p>Current subscription: {subscriptionStatusTitle()}</p>
						<p>
							Active since:{' '}
							{moment(
								user?.subscription?.output?.data?.state?.startDate
							).format('L')}
						</p>
						<p>
							Expiration date:{' '}
							{moment(user?.subscription?.output?.data?.state?.endDate).format(
								'L'
							)}
						</p>
					</div>
					<Form.Item name="subscription" label="Choose the new subscription">
						<Select
							options={subscriptionTypes}
							defaultValue={subscriptionStatus}
							onChange={(value) => {
								subscriptionChangeHandler(value)
							}}
						/>
					</Form.Item>
				</TabPane>
			</Tabs>
		</Form>
	)
}

export default function ViewUserModal({
	isSuper,
	onClose,
	queryParams,
	isVisible,
	reloader
}) {
	const { fetchUser } = useUsers()
	const [user, setUser] = useState(undefined)
	const [isFetching, setIsFetching] = useState(true)

	const [deleteIsLoading, setDeleteIsLoading] = useState(false)
	const [editSignal, setEditSignal] = useState(false)

	const modalIsVisible = useMemo(() => {
		if (typeof isVisible === 'boolean') return isVisible
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'view_user' && record_id
	}, [queryParams, isVisible])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchUser(queryParams.record_id)
				.then((userData) => {
					setUser(userData)
				})
				.finally(() => setIsFetching(false))
		} else setUser(undefined)
	}, [fetchUser, modalIsVisible, queryParams.record_id])

	return (
		<Modal
			visible={modalIsVisible}
			centered
			footer={[
				<div className={styles.flex_spaceBetween}>
					<Button
						danger
						disabled={!isSuper}
						type="primary"
						onClick={() => {
							confirmDelete(setDeleteIsLoading, user, onClose, () =>
								reloader(queryParams)
							)
						}}
						loading={deleteIsLoading}
					>
						Delete
					</Button>
					<Space>
						<Button
							disabled={!isSuper}
							key="Submit"
							type="primary"
							htmlType="submit"
							style={{ width: '100%' }}
							onClick={() => {
								setEditSignal(true)
							}}
						>
							Save
						</Button>
					</Space>
				</div>
			]}
			title="User Profile"
			width={800}
			closable
			destroyOnClose
			onCancel={onClose}
			className="overflow-x-hidden"
		>
			<Spin spinning={isFetching}>
				<DisplayObject
					user={user}
					isSuper={isSuper}
					reloader={fetchUser}
					tableReloader={reloader}
					queryParams={queryParams}
					setUser={setUser}
					setIsFetching={setIsFetching}
					signal={editSignal}
					setSignal={setEditSignal}
					closer={onClose}
				/>
			</Spin>
		</Modal>
	)
}
ViewUserModal.propTypes = {
	isSuper: bool,
	onClose: func.isRequired
}

ViewUserModal.defaultProps = {
	isSuper: false
}
