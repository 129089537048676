import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom'

import { AuthLayout } from '../../components'
import { useRemoveElement } from '../../hooks'
import {
	ForgotPasswordPage,
	LoginPage,
	NotFoundPage,
	ResetPasswordPage
} from '../../pages'

export default function GuestRouter() {
	useRemoveElement()
	return (
		<Router basename={process.env.PUBLIC_URL}>
			<AuthLayout>
				<Switch>
					<Route exact path="/auth/login">
						<LoginPage />
					</Route>
					<Route exact path="/auth/forgot-password">
						<ForgotPasswordPage />
					</Route>
					<Route exact path="/auth/reset-password/:token">
						<ResetPasswordPage />
					</Route>
					<Redirect from="/" to="/auth/login" />
					<Route path="*">
						<NotFoundPage />
					</Route>
				</Switch>
			</AuthLayout>
		</Router>
	)
}
