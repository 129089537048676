/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EditOutlined } from '@ant-design/icons'
import { Button, Select, Spin, Table } from 'antd'
import moment from 'moment'
import { func, oneOf, string } from 'prop-types'
import qs from 'query-string'

import {
	getSearchFilterProps,
	getSelectFilterProps,
	Icon,
	QuestionModal
} from '../../components'
import {
	useLessons,
	useQuestions,
	useQuestionTypes,
	useReports,
	useSubjects
} from '../../hooks'
import EditLessonModal from '../lessons/edit-btn'
import { AVAILABLE_QUIZ_TYPES } from '../quiz-questions'
import { AVAILABLE_REVISION_TYPES } from '../revision-lessons/index'
import RevisionModal from '../revision-lessons/modal'
import ViewUserModal from '../users/view-user-modal'

// eslint-disable-next-line import/no-cycle
import ViewAndRespondModal from './modal'

export const modalModes = {
	editQuestion: 'edit_question',
	editLesson: 'edit_lesson',
	editRevision: 'edit_revision',
	viewUser: 'view_user'
}

const STATUS_OPTIONS = [
	{ _id: 'open', title: 'Open' },
	{ _id: 'closed', title: 'Closed' }
]
const ResponseColumn = ({ val, onAction, status }) => {
	return !val && status === 'closed'
		? 'Closed without any response'
		: val || (
				<Button
					type="default"
					htmlType="submit"
					className="edit-btn px-2"
					onClick={onAction}
				>
					<Icon
						variant="reply"
						width={24}
						height={24}
						fill="currentColor"
						className="mr-2"
					/>
					Respond to user
				</Button>
		  )
}
ResponseColumn.propTypes = {
	val: string,
	onAction: func.isRequired,
	status: oneOf(['open', 'closed']).isRequired
}
ResponseColumn.defaultProps = {
	val: ''
}

export default function ReportsPage() {
	const { isLoading, data, totalCount, fetchReports, respondToReport } =
		useReports()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	useEffect(() => {
		fetchReports(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchReports])

	const { editQuestion } = useQuestions()

	const { editLesson, deleteLesson } = useLessons()

	const {
		data: subjectsData,
		fetchSubjects,
		isLoading: subjectsIsLoading
	} = useSubjects()

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	const {
		data: questionTypes,
		fetchQuestionTypes,
		isLoading: questionTypesIsLoading
	} = useQuestionTypes()

	const [quizTypes, setQuizTypes] = useState([])
	const [revisionTypes, setRevisionTypes] = useState([])

	useEffect(() => {
		fetchQuestionTypes().then((QTs) => {
			const quizzes = []
			const revisions = []
			QTs.forEach((qt) => {
				if (AVAILABLE_QUIZ_TYPES.includes(qt.name)) quizzes.push(qt)
				if (AVAILABLE_REVISION_TYPES.includes(qt.name)) revisions.push(qt)
			})
			setQuizTypes(quizzes)
			setRevisionTypes(revisions)
		})
	}, [fetchQuestionTypes])

	const [modalType, setModalType] = useState(null)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				// delete params.record_id
				delete params.record_type
				setModalType(null)
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return (
			modal_is_open && Object.values(modalModes).includes(mode) && record_id
		)
	}, [queryParams])

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (!value || !value.length) return
			Object.assign(params, { [key]: value[0] })
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({ search: qs.stringify(params, { skipNull: true }) })
		fetchReports(params)
	}

	const getFilterValue = useCallback(
		(parameter) => {
			return queryParams[parameter] ? [queryParams[parameter]] : undefined
		},
		[queryParams]
	)

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}

	const handleSendStatus = useCallback(
		(status, recordId) => {
			respondToReport({ _id: recordId, status }).then(() =>
				fetchReports(queryParams)
			)
		},
		[fetchReports, respondToReport, queryParams]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold max-w-">Reports List</h1>
			</header>
			<Table
				className="mx-4"
				dataSource={data}
				tableLayout="fixed"
				size="small"
				loading={isLoading}
				rowKey={(record) => record._id}
				scroll={{
					y: 'calc(100vh - 219px)',
					x: 768
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column width={50} title="ID" dataIndex="bugId" key="bugId" />
				<Table.Column
					dataIndex="status"
					title="Status"
					key="status"
					width={100}
					{...getSelectFilterProps(
						'response status',
						STATUS_OPTIONS,
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					render={(_val, record) => (
						<Select
							value={_val}
							notFoundContent={isLoading ? <Spin size="small" /> : undefined}
							onChange={(status) => handleSendStatus(status, record._id)}
						>
							{STATUS_OPTIONS.map((option) => (
								<Select.Option value={option._id} key={option._id}>
									{option.title}
								</Select.Option>
							))}
						</Select>
					)}
				/>
				<Table.Column
					dataIndex="description"
					title="Description"
					width={190}
					key="description"
					filteredValue={getFilterValue('description')}
					{...getSearchFilterProps('description')}
				/>
				<Table.Column
					dataIndex="respond"
					title="Response"
					key="respond"
					width={190}
					className=""
					filteredValue={getFilterValue('respond')}
					{...getSearchFilterProps('respond text')}
					render={(_val, record) => (
						<ResponseColumn
							val={record.respond}
							onAction={() => {
								setModalVisibility(true, {
									mode: 'view_and_reply',
									record_id: record._id
								})
							}}
							status={record.status}
						/>
					)}
				/>
				<Table.Column
					dataIndex={['respondBy', 'name']}
					title="Respond By"
					key="respondBy"
					width={190}
					filteredValue={getFilterValue('respondBy')}
					{...getSearchFilterProps('respond by')}
				/>
				<Table.Column
					render={(value) =>
						value && moment(value).format('DD-MMM-YYYY, hh:mm A')
					}
					dataIndex="createdAt"
					title="Date created"
					key="createdAt"
					width={190}
				/>
				<Table.Column
					render={(value) =>
						value && moment(value).format('DD-MMM-YYYY, hh:mm A')
					}
					dataIndex="respondAt"
					title="Date updated"
					key="respondAt"
					filteredValue={getFilterValue('respondAt')}
					sorter
					sortOrder={getSortOrder('sort_respondAt')}
					width={190}
					{...getSearchFilterProps('YYYY-MM-DD')}
				/>
				<Table.Column
					dataIndex={['user', 'name']}
					title="User"
					key="user"
					width={190}
					filteredValue={getFilterValue('user')}
					{...getSearchFilterProps('user')}
					render={(_val, record) => (
						<Button
							type="default"
							className="edit-btn px-2 py-1 mr-2"
							onClick={() => {
								setModalType('view_user')
								setModalVisibility(true, {
									mode: modalModes.viewUser,
									record_id: record.user._id
								})
							}}
						>
							<Icon
								variant="user"
								width={24}
								height={24}
								fill="currentColor"
								className="mr-2"
							/>
							{record.user.name}
						</Button>
					)}
				/>
				<Table.Column
					title="References"
					key="reference"
					width={190}
					filteredValue={getFilterValue('reference')}
					{...getSearchFilterProps('reference')}
					render={(_val, record) => (
						<Button
							type="default"
							className="edit-btn px-2 py-1"
							onClick={() => {
								const params = {
									record_id: record.record_id
								}
								switch (record.type) {
									case 'micro_lesson':
										params.mode = modalModes.editQuestion
										params.record_type = 'micro_lesson'
										setModalType('question')
										break
									case 'lesson':
										params.mode = modalModes.editLesson
										params.record_type = 'lesson'
										setModalType('lesson')
										break
									case 'quiz':
										params.mode = modalModes.editQuestion
										params.record_type = 'question'
										setModalType('question')
										break
									case 'revision':
										params.mode = modalModes.editRevision
										params.record_type = 'revision'
										setModalType('revision')
										break
									default:
										params.mode = 'edit'
										setModalType(null)
										break
								}
								setModalVisibility(true, params)
							}}
						>
							<EditOutlined className="mr-2" />
							{record.reference}
						</Button>
					)}
				/>
			</Table>
			<ViewAndRespondModal
				onClose={() => setModalVisibility(false)}
				onRequest={respondToReport}
				queryParams={queryParams}
				setModalType={setModalType}
				setModalVisibility={setModalVisibility}
			/>
			<QuestionModal
				questionCategory={
					queryParams?.record_type === 'question'
						? 'admin/quizes'
						: 'admin/micro-lessons'
				}
				onClose={handleCloseModal}
				onEdit={editQuestion}
				title="Edit Question"
				ctaBtnText="Save"
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
				questionTypes={
					queryParams?.record_type === 'question' ? quizTypes : questionTypes
				}
				questionTypesIsLoading={questionTypesIsLoading}
				queryParams={queryParams}
				isVisible={modalIsVisible && modalType === 'question'}
			/>
			<EditLessonModal
				onClose={handleCloseModal}
				onEdit={editLesson}
				onDelete={(id) => deleteLesson(id)}
				isVisible={modalIsVisible && modalType === 'lesson'}
				queryParams={queryParams}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
			/>
			<RevisionModal
				onClose={handleCloseModal}
				title="Edit Revision Lesson"
				ctaBtnText="Save"
				onEdit={(payload) => editQuestion(payload, 'admin_microrevision')}
				subjects={subjectsData}
				subjectsIsLoading={subjectsIsLoading}
				questionTypes={revisionTypes}
				questionTypesIsLoading={questionTypesIsLoading}
				isVisible={modalIsVisible && modalType === 'revision'}
			/>
			<ViewUserModal
				onClose={handleCloseModal}
				queryParams={queryParams}
				isVisible={modalIsVisible && modalType === 'view_user'}
			/>
		</>
	)
}
