/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	MenuOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { AddNewSubjectModal } from '../../components'
import { useSubjects } from '../../hooks'
import { useUser } from '../../store/hooks'

import EditSubjectModal from './edit-btn'

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export function SubjectsPage() {
	const { isSuper } = useUser()

	const history = useHistory()
	const location = useLocation()
	const queryParams = useMemo(
		() => qs.parse(location.search),
		[location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history, location]
	)

	const {
		data,
		isLoading,
		fetchSubjects,
		deleteSubject,
		editSubject,
		reorderSubject,
		addNewSubject
	} = useSubjects()

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			reorderSubject({
				_id: data[oldIndex]._id,
				destination: data[newIndex]._id
			})
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => document.querySelector('.ant-table-tbody')}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold">Subjects List</h1>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				size="small"
				tableLayout="fixed"
				className="mx-4"
				pagination={false}
				loading={isLoading}
				rowKey={(record) => record._id}
				rowClassName="subject-row"
				scroll={{
					y: 'calc(100vh - 199px)',
					x: 640
				}}
				dataSource={data}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			>
				{!isSuper ? null : (
					<Table.Column
						dataIndex="sort"
						key="sort"
						width={48}
						className="drag-handle-col text-center"
						title={
							<Button
								type={reordering ? 'primary' : 'default'}
								icon={<MenuOutlined />}
								className="w-full h-full flex items-center justify-center"
								onClick={() => setReordering((prev) => !prev)}
							/>
						}
						render={reordering ? () => <DragHandle /> : null}
					/>
				)}
				<Table.Column
					title="Subject ID"
					dataIndex="order"
					key="order"
					className="subject-index-col highlighted-number text-center"
					width={96}
				/>
				<Table.Column
					title="Title"
					dataIndex="title"
					key="title"
					className="subject-title-col"
					width={128}
				/>
				<Table.Column
					title="Description"
					dataIndex="description"
					key="description"
					className="subject-description-col"
					ellipsis
				/>
				<Table.Column
					title="Color"
					dataIndex="color"
					key="color"
					className="subject-color-col text-center"
					width={68}
					render={(val) => (
						<div
							className="w-9 h-4 rounded-sm mx-auto"
							style={{
								backgroundColor: val
							}}
						/>
					)}
				/>
				<Table.Column
					title="Active"
					dataIndex="active"
					key="active"
					className="subject-status-col text-center"
					width={92}
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}
							/>
						</div>
					)}
				/>
			</Table>
			<EditSubjectModal
				onClose={handleCloseModal}
				onEdit={editSubject}
				onDelete={(id) => deleteSubject(id)}
				queryParams={queryParams}
			/>
			<AddNewSubjectModal
				visible={
					!!(queryParams?.modal_is_open && queryParams.mode === 'add') || false
				}
				setVisible={setModalVisibility}
				onAdd={addNewSubject}
			/>
		</>
	)
}

export default SubjectsPage
