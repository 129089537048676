import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Spin } from 'antd'
import classnames from 'classnames'
import { number, string } from 'prop-types'

import { Icon } from '../icon'

export function ViewListCard(props) {
	const { title, quantity, viewListPath, createNewPath } = props
	const [commaSeparatedNum, setNum] = useState()

	useEffect(() => {
		if (quantity !== undefined)
			setNum(quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
	}, [quantity])

	return (
		<div className="dashboard-card flex-1 m-2 bg-white shadow-md rounded-md overflow-hidden">
			<div className="inner flex flex-col items-center justify-start">
				<div className="top-section text-white flex flex-col items-center justify-start uppercase w-full py-3">
					<h2 className="text-inherit mb-3 font-normal">{title}</h2>
					<span className="text-inherit font-bold text-4xl mt-4 mb-8">
						{quantity !== undefined ? commaSeparatedNum : <Spin />}
					</span>
				</div>
				<Button
					type="primary"
					className={classnames(
						'cta-btn text-white border-none transform -translate-y-1/2 w-5/6 shadow-md',
						{
							invisible: !viewListPath
						}
					)}
				>
					<Link to={viewListPath}>View List</Link>
				</Button>
				{createNewPath && (
					<Button
						type="text"
						className={classnames(
							'flex flex-row items-center justify-center mb-3',
							{ invisible: !createNewPath }
						)}
					>
						<Icon
							variant="add"
							width={12}
							height={12}
							className="inline-block m-1"
						/>
						<Link to={createNewPath} className="text-black text-xs">
							Create new
						</Link>
					</Button>
				)}
			</div>
		</div>
	)
}

ViewListCard.propTypes = {
	title: string.isRequired,
	quantity: number,
	viewListPath: string,
	createNewPath: string
}
ViewListCard.defaultProps = {
	quantity: undefined,
	viewListPath: undefined,
	createNewPath: undefined
}
export default ViewListCard
