/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import {
	EditOutlined,
	MenuOutlined,
	PlusOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { Button, message, Select, Table } from 'antd'
import qs from 'query-string'

import {
	getSearchFilterProps,
	getSelectFilterProps,
	QuestionModal
} from '../../components'
import {
	useLevels,
	useQuestions,
	useQuestionTypes,
	useSubjects
} from '../../hooks'
import { useUser } from '../../store/hooks'

const ADD_MODAL = {
	title: 'New Micro Lesson',
	ctaBtnText: 'ADD NEW MICRO LESSON'
}
const EDIT_MODAL = {
	title: 'Edit Micro Lesson',
	ctaBtnText: 'Save'
}

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export default function MicroLessonsPage() {
	const { isSuper } = useUser()

	const [uploadingBulk, setUploadingBulk] = useState(false)
	const [modal, setModal] = useState()

	const {
		data,
		totalCount,
		isLoading,
		fetchQuestions,
		addNewQuestion,
		deleteQuestion,
		editQuestion,
		bulkUploadQuestions
	} = useQuestions()

	const fileInputRef = useRef(null)
	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const {
		data: subjectsData,
		fetchSubjects,
		isLoading: subjectsIsLoading
	} = useSubjects()

	const { data: levelsData, fetchAllLevels } = useLevels()

	const {
		data: questionTypes,
		fetchQuestionTypes,
		isLoading: questionTypesIsLoading
	} = useQuestionTypes()

	const handleTableChange = (pagination, filters, sorters) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length) Object.assign(params, { [key]: value[0] })
		})

		if (Array.isArray(sorters)) {
			sorters.forEach((sorter) => {
				if (sorter && sorter.column) {
					Object.assign(params, {
						[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
					})
				}
			})
		} else if (sorters.column) {
			Object.assign(params, {
				[`sort_${sorters.columnKey}`]: sorters.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})

		fetchQuestions('admin/micro-lessons', params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}
	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchQuestions('admin/micro-lessons')
	}
	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	const onChangeFile = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const file = e.target.files[0]
		if (!file) return

		setUploadingBulk(true)
		bulkUploadQuestions(file, 'microLessons').finally(() =>
			setUploadingBulk(false)
		)
	}

	useEffect(() => {
		fetchQuestionTypes()
	}, [fetchQuestionTypes])

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	useEffect(() => {
		fetchQuestions('admin/micro-lessons', queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchQuestions])

	useEffect(() => {
		if (queryParams.mode === 'edit_question') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const item = data[oldIndex]
			const min = Math.min(oldIndex, newIndex)
			const max = Math.max(oldIndex, newIndex)

			const filteredData = data
				.reduce((res, cur, index) => {
					res.push({ ...cur, index })
					return res
				}, [])
				.filter(
					(el, index) =>
						index >= min && index <= max && el?.lesson_id === item?.lesson_id
				)
			if (filteredData.length > 1) {
				let order
				if (oldIndex > newIndex) {
					// moved up
					const target = filteredData[0]
					order = target?.order
				} else {
					// moved down
					const target = filteredData[filteredData.length - 1]
					order = target?.order
				}
				editQuestion(
					{
						_id: item._id,
						type: item.question_type._id,
						lesson_id: item.lesson_id,
						details: item.details,
						order
					},
					'admin/micro-lessons'
				)
			} else {
				message.error(
					'You can only reorder micro-lessons within the same lesson'
				)
			}
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => {
				const node = document.querySelector('.ant-table-tbody')
				// console.log(node)
				return node
			}}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold mr-auto">Micro Lessons List</h1>
				<Button
					disabled={resetFiltersDisabled || uploadingBulk}
					onClick={resetFilters}
					type="default"
					className="mr-2"
				>
					Reset All Filters
				</Button>
				<input
					disabled={uploadingBulk}
					type="file"
					ref={fileInputRef}
					className="hidden"
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={onChangeFile}
				/>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => fileInputRef.current?.click()}
					icon={<UploadOutlined />}
					className="flex items-center mr-2"
				>
					BULK UPLOAD
				</Button>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => {
						setModalVisibility(true, { mode: 'add' })
					}}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				loading={isLoading}
				dataSource={data}
				className="mx-4 text-xs"
				rowClassName="micro-lesson-row text-xs"
				tableLayout="fixed"
				size="small"
				scroll={{
					y: 'calc(100vh - 242px)',
					x: 1360
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
				rowKey={(record) => record._id}
			>
				{!isSuper ? null : (
					<Table.Column
						dataIndex="sort"
						key="sort"
						className="drag-handle-col text-center"
						width={48}
						title={
							<Button
								type={reordering ? 'primary' : 'default'}
								icon={<MenuOutlined />}
								className="w-full h-full flex items-center justify-center"
								onClick={() => setReordering((prev) => !prev)}
							/>
						}
						render={reordering ? () => <DragHandle /> : null}
					/>
				)}
				<Table.Column
					title="Subject"
					dataIndex={['lesson', 'subject', 'title']}
					key="subject"
					className="subject-title-col"
					width={148}
					sorter={{ multiple: 5 }}
					sortOrder={getSortOrder('sort_subject')}
					{...getSelectFilterProps('subject', subjectsData, (record) => (
						<Select.Option value={record._id}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('subject')}
				/>
				<Table.Column
					title="Level"
					dataIndex={['level', 'order']}
					key="level"
					width={84}
					className="lvl-index-col text-center"
					sorter={{ multiple: 5 }}
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', levelsData, (record) => (
						<Select.Option value={record._id}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('level')}
				/>
				<Table.Column
					title="Lesson NO."
					dataIndex={['lesson', 'priority']}
					key="lessonPriority"
					className="lesson-number-col text-center"
					width={124}
					sorter={{ multiple: 5 }}
					sortOrder={getSortOrder('sort_lessonPriority')}
					{...getSearchFilterProps('Lesson number', true)}
					filteredValue={getFilterValue('lessonPriority')}
				/>
				<Table.Column
					title="Lesson"
					dataIndex={['lesson', 'title']}
					key="lessonTitle"
					className="lesson-title-col"
					width={198}
					{...getSearchFilterProps('lessons')}
					filteredValue={getFilterValue('lessonTitle')}
				/>
				<Table.Column
					title="Micro Lesson NO."
					dataIndex="order"
					key="order"
					className="order-col highlighted-number text-center"
					width={184}
					sorter={{ multiple: 5 }}
					sortOrder={getSortOrder('sort_order')}
					{...getSearchFilterProps('Micro lesson number')}
					filteredValue={getFilterValue('order')}
				/>
				<Table.Column
					title="Q-Type"
					dataIndex={['type', 'name']}
					key="type"
					className="qt-name-col text-center"
					width={98}
					sorter={{ multiple: 5 }}
					sortOrder={getSortOrder('sort_type')}
					{...getSelectFilterProps('question type', questionTypes, (record) => (
						<Select.Option value={record._id}>{record.name}</Select.Option>
					))}
					filteredValue={getFilterValue('type')}
				/>
				<Table.Column
					title="Question"
					dataIndex={['details', 'text']}
					render={(text, row) => text ?? row.details.completedText}
					key="questionText"
					className="question-text-col"
					ellipsis
					{...getSearchFilterProps('questions')}
					filteredValue={getFilterValue('questionText')}
				/>
				<Table.Column
					title="Status"
					dataIndex="status"
					key="status"
					className="status-col text-center"
					width={98}
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
				/>
				{/* <Table.Column
					title="Last Modified"
					dataIndex="last_modified"
					key="last_modified"
					render={(val) => moment(val).format('DD MMM, YYYY')}
				/> */}
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit_question',
										record_id: record._id
									})
								}}
							/>
							{/* <DeleteBtn onDelete={() => deleteQuestion(record._id)} /> */}
						</div>
					)}
				/>
			</Table>
			{uploadingBulk ? null : (
				<QuestionModal
					questionCategory="admin/micro-lessons"
					onClose={handleCloseModal}
					onEdit={editQuestion}
					onAdd={addNewQuestion}
					onDelete={deleteQuestion}
					title={modal?.title}
					ctaBtnText={modal?.ctaBtnText}
					subjects={subjectsData}
					subjectsIsLoading={subjectsIsLoading}
					questionTypes={questionTypes}
					questionTypesIsLoading={questionTypesIsLoading}
					queryParams={queryParams}
				/>
			)}
		</>
	)
}
