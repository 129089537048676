/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

export default function useGifts() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchGifts = useCallback(
		/**
		 * @param {object} queryParams
		 */
		(queryParams) => {
			const { page: _page, size: _size, ...params } = queryParams
			params.offset = _page
			params.limit = _size

			setLoading(true)
			return new Promise((resolve, reject) => {
				const endpoint = `/admin/gifts?${qs.stringify(queryParams, {
					skipNull: true
				})}`

				axios
					.get(endpoint)
					.then((res) => {
						const { rows: giftsData, count: total_count } = res.data.data

						setTotalCount(total_count)
						setData(giftsData)
						resolve(giftsData)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchGift = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`/admin/gifts/${id}`)
					.then((res) => {
						const giftData = res.data.data
						resolve(giftData)
					})
					.catch(reject)
			})
		},
		[]
	)

	/**
	 * @typedef {object} Options
	 * @property {bool} [refetchOnSuccess]
	 */
	const addNewGift = useCallback(
		/**
		 * @param {*} payload
		 * @param {Options} [options]
		 */
		(payload, options) => {
			const key = Date.now()
			message.loading({
				content: 'adding new gift...',
				key
			})

			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				axios
					.post('/admin/gifts', payload)
					.then(({ data: newGift }) => {
						message.success({
							content: 'new gift added',
							key
						})
						enableUpdateStatistics()
						if (refetchOnSuccess) fetchGifts(qs.parse(search))
						resolve(newGift)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new gift',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	const deleteGift = useCallback(
		/**
		 * @param {string} id
		 * @param {Options} [options]
		 */
		(id, options) => {
			if (!id) throw new Error('gift ID not provided')
			message.loading({
				content: 'deleting gift...',
				key: id
			})

			return new Promise((resolve, reject) => {
				const { refetchOnDone = true } = options || {}
				axios
					.delete(`/admin/gifts/${id}`)
					.then(() => {
						message.success({
							content: 'gift deleted',
							key: id
						})
						enableUpdateStatistics()
						if (refetchOnDone) fetchGifts(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to delete gift',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	const editGift = useCallback(
		/**
		 * @param {*} payload
		 * @param {Options} [options]
		 */
		({ image, ...payload }, options) => {
			const { _id } = payload || {}
			message.loading({
				content: 'updating gift data...',
				key: _id
			})

			const regex = /^temp\//i
			const hasImage = regex.test(image)
			if (hasImage) Object.assign(payload, { image })

			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				axios
					.put(`/admin/gifts/${_id}`, payload)
					.then(({ data: giftData }) => {
						message.success({
							content: 'gift data updated',
							key: _id
						})
						enableUpdateStatistics()
						if (refetchOnSuccess) fetchGifts(qs.parse(search))
						resolve(giftData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update gift data',
							key: _id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	const reorderGift = useCallback(
		({ _id: gift_id, ...payload }) => {
			message.loading({
				content: 'reordering avatar...',
				key: gift_id
			})

			return new Promise((resolve, reject) => {
				axios
					.put(`/admin/gifts/reorder/${gift_id}`, payload)
					.then(() => {
						message.success({ content: 'gift reordered', key: gift_id })
						enableUpdateStatistics()
						fetchGifts(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to reorder gift',
							key: gift_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchGifts, search]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchGifts,
		editGift,
		deleteGift,
		addNewGift,
		fetchGift,
		reorderGift
	}
}
