import { useReducer } from 'react'
import {
	CloseOutlined,
	MinusCircleOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Form, Input, Select, Tooltip } from 'antd'
import { object, oneOf } from 'prop-types'

import { uniqueID } from '../../utils'

export default function FBOrFBTQuestion({ type, formInstance, initialValues }) {
	const forceUpdate = useReducer(() => ({}), {})[1]

	return (
		<Form
			name={`${type}_question`}
			form={formInstance}
			initialValues={initialValues}
		>
			<Form.List name="sentences">
				{(sentences, { add: addSentence, remove: removeSentence }) => (
					<>
						<h3>Sentences:</h3>
						{sentences.map((sentence) => (
							<div
								key={sentence.key}
								className="mb-6 w-full flex flex-row items-center border-b-2 last:border-b-0"
							>
								<Form.List name={[sentence.name]}>
									{(subtexts, { add: addSubtext, remove: removeSubtext }) => (
										<div className="flex flex-row flex-wrap">
											{subtexts.map((subtext) => (
												<Form.Item key={subtext.key} required={false}>
													<Input.Group
														className="flex flex-row flex-wrap w-auto border-r-0"
														compact
													>
														{typeof formInstance.getFieldValue('sentences')[
															sentence.name
														][subtext.name] === 'string' ? (
															<Input.Group
																compact
																className="mr-2 flex flex-row flex-nowrap border-r-0"
															>
																<Form.Item
																	name={[subtext.name]}
																	noStyle
																	required
																>
																	<Input placeholder="text" key={subtext.key} />
																</Form.Item>
																<Button
																	icon={<CloseOutlined />}
																	onClick={() => removeSubtext(subtext.name)}
																	className="inline-flex items-center justify-center hover:text-red-500 focus:text-red-500 hover:border-red-500 focus:border-red-500"
																/>
															</Input.Group>
														) : (
															<Input.Group compact className="mr-2 border-r-0">
																<Form.Item
																	name={[subtext.name, 'answer']}
																	noStyle
																	rules={[
																		{
																			required: true,
																			message:
																				'please select an answer for this field'
																		}
																	]}
																>
																	<Select
																		mode={type === 'FBT' && 'multiple'}
																		placeholder="blank"
																		key={subtext.key}
																		style={{ minWidth: '144px' }}
																		name={[subtext.name, 'answer']}
																		className="blank-field-select"
																	>
																		{formInstance
																			.getFieldValue('choices')
																			?.map((choice) =>
																				choice.text ? (
																					<Select.Option
																						value={choice.id}
																						key={choice.id}
																					>
																						{choice.text}
																					</Select.Option>
																				) : null
																			)}
																	</Select>
																</Form.Item>
																<Button
																	icon={<CloseOutlined />}
																	onClick={() => removeSubtext(subtext.name)}
																	className="inline-flex items-center justify-center hover:text-red-500 focus:text-red-500 hover:border-red-500 focus:border-red-500"
																/>
															</Input.Group>
														)}
													</Input.Group>
												</Form.Item>
											))}
											<div className="flex flex-row flex-nowrap">
												<Button
													type="dashed"
													onClick={() => addSubtext('')}
													className="flex items-center justify-center mr-2 mb-6"
													icon={<PlusOutlined />}
												>
													Subtext
												</Button>
												<Button
													type="dashed"
													onClick={() =>
														addSubtext({
															id: uniqueID(),
															answer: []
														})
													}
													className="flex items-center justify-center mr-2 mb-6"
													icon={<PlusOutlined />}
												>
													Blank
												</Button>
											</div>
										</div>
									)}
								</Form.List>
								<Tooltip title="Remove sentence" trigger={['focus', 'hover']}>
									<button
										type="button"
										className="flex items-center focus:text-red-500 focus:outline-black self-start mt-2"
									>
										<MinusCircleOutlined
											className="text-inherit"
											onClick={() => removeSentence(sentence.name)}
										/>
									</button>
								</Tooltip>
							</div>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => addSentence([])}
								className="flex items-center justify-center mb-6"
								icon={<PlusOutlined />}
							>
								Sentence
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<Form.List name="choices">
				{(choices, { add: addChoice, remove: removeChoice }) => (
					<>
						<h3>Answers:</h3>
						{choices.map((choice, index) => (
							<Form.Item required={false} key={choice.key}>
								<div className="w-full flex flex-row flex-nowrap items-center">
									<Form.Item
										name={[choice.name, 'text']}
										fieldKey={[choice.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message:
													'Please input answer text or delete this field.'
											}
										]}
										noStyle
									>
										<Input
											className="mr-2"
											placeholder={`Answer ${index + 1}`}
											onChange={() => forceUpdate()}
										/>
									</Form.Item>
									<Tooltip title="Remove answer" trigger={['focus', 'hover']}>
										<button
											type="button"
											className="flex items-center focus:text-red-500 focus:outline-black"
										>
											<MinusCircleOutlined
												className="text-inherit"
												onClick={() => removeChoice(choice.name)}
											/>
										</button>
									</Tooltip>
								</div>
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => {
									addChoice({
										id: uniqueID(),
										text: ''
									})
									forceUpdate()
								}}
								className="flex items-center justify-center mb-6"
								icon={<PlusOutlined />}
							>
								Answer
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

FBOrFBTQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired,
	type: oneOf(['FB', 'FBT']).isRequired
}
