/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import { axios, getPathsForDashboardScreen, getUrl, md5 } from '../../utils'
import userSlice, { INITIAL_STATE } from '../reducers/user'

const { actions } = userSlice

const useUser = () => {
	const user = useSelector((state) => state.user, shallowEqual)
	const dispatchUser = useDispatch()

	const updateState = useCallback(
		(newState) => {
			dispatchUser(actions.updateState(newState))
		},
		[dispatchUser]
	)

	const getEmailHash = useCallback((email = '') => {
		return md5(email.trim().toLowerCase())
	}, [])

	const updateAvatar = useCallback(() => {
		let avatarUrl = `https://www.gravatar.com/avatar/${getEmailHash(
			user.email
		)}?default=mp`

		if (user.avatar) avatarUrl = getUrl(user.avatar.path)

		updateState({ avatarUrl })
	}, [getEmailHash, updateState, user])

	const loggedIn = useCallback(() => {
		return new Promise((resolve, reject) => {
			axios
				.get('/admin/auth')
				.then((res) => {
					updateState({ ...res.data.data, isAuthenticated: true })
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}, [updateState])

	const logout = useCallback(() => {
		return new Promise((resolve, reject) => {
			axios
				.delete('/admin/auth')
				.then(() => {
					updateState(INITIAL_STATE)
					resolve()
				})
				.catch((err) => {
					reject(err)
				})
		})
	}, [updateState])

	const login = ({ email, password }) => {
		return new Promise((resolve, reject) => {
			axios
				.post('/admin/auth', { email, password })
				.then((res) => {
					updateState({ ...res.data.data, isAuthenticated: true })

					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const forgetPassword = (email) => {
		return new Promise((resolve, reject) => {
			axios
				.put('/admin/auth/reset-password', { email })
				.then((res) => {
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const resetPassword = (token, password) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`/admin/auth/reset-password/${token}`, { password })
				.then((res) => {
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const checkUuid = (uuid) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`/auth/redirect/admin/resetpassword/${uuid}`)
				.then((res) => {
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const assignNewAdmin = ({ name, email, password }) => {
		return new Promise((resolve, reject) => {
			axios
				.post('/admin/admins', { name, email, password })
				.then(({ data: res }) => {
					if (res.status === 201) {
						message.success('New admin added successfully')
					}
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const fetchProfile = useCallback(() => {
		return new Promise((resolve, reject) => {
			axios
				.get('/admin/profile')
				.then((res) => {
					updateState(res.data)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}, [updateState])

	const updateProfile = (payload) => {
		return new Promise((resolve, reject) => {
			axios
				.put('/admin/auth', payload)
				.then((res) => {
					message.success('Profile edited successfully')
					resolve(res.data.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	const uploadAvatar = ({ file }) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData()
			formData.append('uploadedFile', file)
			axios
				.post('/common/images', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(({ data: res }) => {
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
			return {
				abort() {
					message.error('upload progress is aborted.')
				}
			}
		})
	}
	const disableUpdateStatistics = () => {
		updateState({ shouldUpdateStatistics: false })
	}
	const enableUpdateStatistics = useCallback(() => {
		updateState({ shouldUpdateStatistics: true })
	}, [updateState])

	const fetchStatistics = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (user.shouldUpdateStatistics && user._id) {
				axios
					.get('/admin/auth/dashboard?offset=1&limit=4')
					.then((res) => {
						const { activities, statistics } = res.data.data
						const reformStats = statistics.map((statistic, i) => {
							const path = getPathsForDashboardScreen(statistic.title)

							const keyId = `now-${Date.now()}-ind-${i}`
							return { keyId, ...statistic, ...path }
						})

						updateState({
							activities,
							statistics: reformStats,
							shouldUpdateStatistics: false
						})
						resolve()
					})
					.catch(reject)
			}
		})
	}, [updateState, user._id, user.shouldUpdateStatistics])

	return {
		...user,
		loggedIn,
		logout,
		updateState,
		login,
		forgetPassword,
		resetPassword,
		checkUuid,
		assignNewAdmin,
		updateProfile,
		fetchProfile,
		uploadAvatar,
		updateAvatar,
		disableUpdateStatistics,
		enableUpdateStatistics,
		fetchStatistics
	}
}
export default useUser
