/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddLessonPayload
 * @property {string} title
 * @property {string} level
 * @property {string} subject
 * @property {string} lightIcon
 * @property {string} darkIcon
 */

/**
 * @typedef {object} EditLessonPayload
 * @property {string} _id
 * @property {string} title
 * @property {string} level
 * @property {string} subject
 * @property {string} lightIcon
 * @property {string} darkIcon
 */

/**
 * @typedef {object} ReOrderLessonPayload
 * @property {string} _id
 * @property {string} destination
 */

/**
 * @typedef {object} UpdateLessonIconsPayload
 * @property {string} lesson_id
 * @property {string} lightIcon
 * @property {string} darkIcon
 */
function useLessons() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchLessons = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				const {
					page: _page,
					size: _size,
					sort_level,
					sort_priority,
					sort_title,
					sort_status,
					...params
				} = queryParams

				params.offset = _page
				params.limit = _size

				if (typeof sort_level === 'number') {
					params.sortKey = 'level'
					params.sortType = sort_level === 1 ? 'desc' : 'asc'
				} else if (typeof sort_priority === 'number') {
					params.sortKey = 'priority'
					params.sortType = sort_priority === 1 ? 'desc' : 'asc'
				} else if (typeof sort_title === 'number') {
					params.sortKey = 'title'
					params.sortType = sort_title === 1 ? 'desc' : 'asc'
				} else if (typeof sort_status === 'number') {
					params.sortKey = 'status'
					params.sortType = sort_status === 1 ? 'desc' : 'asc'
				}

				setLoading(true)

				const endpoint = `/admin/lessons?${qs.stringify(params, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: lessons, count: total_count } = res.data
						setTotalCount(total_count)
						setData(lessons)
						resolve(lessons)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchLesson = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) reject(new Error('Lesson ID not provided'))
				else {
					setLoading(true)
					axios
						.get(`/admin/lessons/${id}`)
						.then(({ data: res }) => {
							const lesson = {
								...res.data,
								lightIconSource: res.data.lightIcon?.path,
								darkIconSource: res.data.darkIcon?.path
							}
							resolve(lesson)
						})
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const bulkUploadLessons = (file) => {
		return new Promise((resolve, reject) => {
			const endpoint = '/common/images'

			const fd = new FormData()
			fd.append('uploadedFile', file)

			const key = Date.now()
			const hideLoader = message.loading('uploading and processing file...', 0)
			axios
				.post(endpoint, fd, {
					headers: { 'Content-Type': 'multipart/form-data' }
				})
				.then((res) => {
					const path = res.data.data
					axios
						.post(`admin/lessons/bulk-upload?filename=${path}`)
						.then((result) => {
							message.success({
								content: 'file uploaded and processed',
								key
							})
							if (!result.data.data) resolve()
							else {
								fetchLessons(search)
									.then(() => resolve(result.data.data))
									.catch(reject)
							}
						})
						.catch((err) => {
							message.error({
								content: 'failed to upload or process file',
								key
							})
							reject(err)
						})
						.finally(() => hideLoader())
				})
				.catch((err) => {
					message.error({
						content: 'failed to upload or process file',
						key
					})
					reject(err)
				})
		})
	}

	// const updateLessonIcons = useCallback(
	// 	/**
	// 	 * @param {UpdateLessonIconsPayload} payload
	// 	 */
	// 	(payload) => {
	// 		return new Promise((resolve, reject) => {
	// 			const { lesson_id } = payload
	// 			message.loading({ content: 'updating lesson icons...', key: lesson_id })
	// 			axios
	// 				.post('/admin/lesson/icon_ids', payload)
	// 				.then(({ data: lessonNewIcons }) => {
	// 					message.success({ content: 'lesson icons updated', key: lesson_id })
	// 					resolve(lessonNewIcons)
	// 				})
	// 				.catch((err) => {
	// 					message.error({
	// 						content: 'failed to update lesson icons',
	// 						key: lesson_id
	// 					})
	// 					reject(err)
	// 				})
	// 		})
	// 	},
	// 	[]
	// )

	const addNewLesson = useCallback(
		/**
		 * @param {AddLessonPayload} payload
		 */
		(restPayload) => {
			return new Promise((resolve, reject) => {
				const { lightIcon, darkIcon, ...payload } = restPayload
				message.loading({
					content: 'adding new lesson...',
					key: payload.title
				})

				const regex = /^temp\//i
				const hasTempInDark = regex.test(darkIcon)
				if (hasTempInDark) Object.assign(payload, { darkIcon })

				const hasTempInLight = regex.test(lightIcon)
				if (hasTempInLight) Object.assign(payload, { lightIcon })
				axios
					.post('/admin/lessons', payload)
					.then(({ data: res }) => {
						const lesson = {
							...res.data,
							lightIconSource: res.data.lightIcon?.path,
							darkIconSource: res.data.darkIcon?.path
						}
						message.success({
							content: 'new lesson added',
							key: payload.title
						})
						enableUpdateStatistics()
						fetchLessons(qs.parse(search)).then(() => {
							resolve(lesson)
						})
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new lesson',
							key: payload.title
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLessons, search]
	)

	const deleteLesson = useCallback(
		/**
		 * @param {string} id - lesson _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting lesson...', key: id })
				axios
					.delete(`/admin/lessons/${id}`)
					.then(() => {
						message.success({
							content: 'lesson deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchLessons(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'deleting lesson operation failed',
							key: id
						})
						reject(err)
					})
					.finally(() => fetchLessons(qs.parse(search)))
			})
		},
		[enableUpdateStatistics, fetchLessons, search]
	)

	const editLesson = useCallback(
		/**
		 * @param {EditLessonPayload} payload
		 */
		({ _id: lesson_id, lightIcon, darkIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'updating lesson data...', key: lesson_id })

				const regex = /^temp\//i
				const hasTempInDark = regex.test(darkIcon)
				if (hasTempInDark) Object.assign(payload, { darkIcon })

				const hasTempInLight = regex.test(lightIcon)
				if (hasTempInLight) Object.assign(payload, { lightIcon })

				axios
					.put(`/admin/lessons/${lesson_id}`, payload)
					.then(({ data: res }) => {
						message.success({ content: 'lesson data updated', key: lesson_id })
						const editedLesson = {
							...res.data,
							lightIconSource: res.data.lightIcon?.path,
							darkIconSource: res.data.darkIcon?.path
						}
						enableUpdateStatistics()
						fetchLessons(qs.parse(search))
						resolve(editedLesson)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson',
							key: lesson_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLessons, search]
	)

	const reorderLesson = useCallback(
		/**
		 * @param {ReOrderLessonPayload} payload
		 */
		({ _id: lesson_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'reordering lesson...',
					key: lesson_id
				})

				axios
					.put(`/admin/lessons/reorder/${lesson_id}`, payload)
					.then(() => {
						message.success({ content: 'lesson reordered', key: lesson_id })
						enableUpdateStatistics()
						fetchLessons(qs.parse(search))
					})
					.catch((err) => {
						message.error({
							content: 'failed to reorder lesson',
							key: lesson_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLessons, search]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchLessons,
		fetchLesson,
		addNewLesson,
		deleteLesson,
		editLesson,
		reorderLesson,
		bulkUploadLessons
	}
}
export default useLessons
