import { useEffect } from 'react'

const DELAY = 1000

function useRemoveElement(ele = '#root ~ iframe') {
	useEffect(() => {
		const timeout = setTimeout(() => {
			const elements = document.querySelectorAll(ele)
			elements.forEach((element) => element.remove())
		}, DELAY)

		return () => {
			clearTimeout(timeout)
		}
	}, [ele])
}

export default useRemoveElement
