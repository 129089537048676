import { Layout } from 'antd'

export default function NotFoundPage() {
	return (
		<Layout className="h-full w-full">
			<Layout.Content className="flex items-center justify-center h-full w-full">
				<h1 className="flex flex-col items-center text-xl">
					<span>Error 404</span>
					<span>Page Not Found</span>
				</h1>
			</Layout.Content>
		</Layout>
	)
}
