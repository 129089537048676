/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { bool, func, object } from 'prop-types'

import { Icon } from '../../components'
import { useReports } from '../../hooks'
import { confirmExit } from '../../utils'

// eslint-disable-next-line import/no-cycle
import { modalModes } from './index'

export default function RespondToReportModal({
	isVisible,
	onClose,
	onRequest,
	queryParams,
	setModalType,
	setModalVisibility
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [data, setData] = useState(undefined)
	const { setFieldsValue, resetFields, validateFields, getFieldValue } = form
	const { fetchReport } = useReports()

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'view_and_reply' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { respond } = data
		setFormHasChanged(false)
		setFormHasChanged(!isEqual(getFieldValue('respond'), respond))
	}, [data, getFieldValue])

	const closeModal = useCallback(() => {
		onClose()
		setIsSubmitting(false)
		setFormHasChanged(false)
		resetFields()
	}, [onClose, resetFields])

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)

			onRequest({
				_id: data._id,
				...values
			})
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEdit = () => {
		confirmExit(closeModal, formHasChanged)
	}

	const normalizeText = (str) =>
		str.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase())
	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchReport(queryParams.record_id)
				.then((reportData) => {
					setData(reportData)
					setFieldsValue(reportData)
				})
				.catch(() => {
					message.error("Couldn't fetch report data")
					closeModal()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		closeModal,
		fetchReport,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={isVisible !== undefined ? isVisible : modalIsVisible}
			onCancel={handleCancelEdit}
			onOk={handleSubmitEdit}
			title={<h3 className="font-bold">Respond to Report</h3>}
			okText="Submit"
			okButtonProps={{
				disabled: !formHasChanged,
				className: 'inline-flex flex-row items-center justify-center'
			}}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="response_to_report"
					labelCol={{
						span: 4
					}}
					preserve={false}
					onFieldsChange={checkFormChanged}
				>
					<Form.Item label="Description">
						{data?.description ? (
							<Input readOnly bordered={false} value={data.description} />
						) : (
							<span>-</span>
						)}
					</Form.Item>
					<Form.Item label="Type">
						{data?.type ? (
							<Button
								type="default"
								className="edit-btn px-2 py-1"
								onClick={() => {
									const params = {
										record_id: data.record_id,
										record_type: data.type
									}
									switch (data.type) {
										case 'micro_lesson':
										case 'quiz':
											params.mode = modalModes.editQuestion
											setModalType('question')
											break
										case 'lesson':
											params.mode = modalModes.editLesson
											setModalType(data.type)
											break
										case 'revision':
											params.mode = modalModes.editRevision
											setModalType(data.type)
											break
										default:
											params.mode = 'edit'
											setModalType(null)
											break
									}
									setModalVisibility(true, params)
								}}
							>
								<EditOutlined className="mr-2" />
								{normalizeText(data.reference)}
							</Button>
						) : (
							<span>-</span>
						)}
					</Form.Item>
					<Form.Item label="User">
						{data?.user ? (
							<>
								<Button
									type="default"
									className="edit-btn px-2 py-1 mr-2"
									onClick={() => {
										setModalType('view_user')
										setModalVisibility(true, {
											mode: modalModes.viewUser,
											record_id: data.user_id
										})
									}}
								>
									<Icon
										variant="user"
										width={24}
										height={24}
										fill="currentColor"
										className="mr-2"
									/>
									{data.user.name}
								</Button>
							</>
						) : (
							<span>-</span>
						)}
					</Form.Item>
					<Form.Item name="respond" label="Response">
						<Input.TextArea />
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
RespondToReportModal.propTypes = {
	isVisible: bool,
	onClose: func.isRequired,
	onRequest: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	setModalType: func.isRequired,
	setModalVisibility: func.isRequired
}
RespondToReportModal.defaultProps = {
	isVisible: undefined
}
