import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { object } from 'prop-types'

import { uniqueID } from '../../utils'

export default function SQQuestion({ formInstance, initialValues }) {
	return (
		<Form name="SQ_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="text"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.List name="choices">
				{(choices, { add, remove }, { errors }) => (
					<>
						<h3>Choices (in the correct order):</h3>
						{choices.map((choice, index) => (
							<Form.Item required={false} key={choice.key}>
								<div className="w-full flex flex-row flex-nowrap items-center">
									<Form.Item
										name={[choice.name, 'text']}
										fieldKey={[choice.fieldKey, 'text']}
										rules={[
											{
												required: true,
												message: 'Please input choice text'
											}
										]}
										noStyle
									>
										<Input
											className="mr-2"
											placeholder={`Choice ${index + 1}`}
										/>
									</Form.Item>

									<button
										type="button"
										className="flex items-center focus:text-red-500 focus:outline-black"
									>
										<MinusCircleOutlined
											className="text-inherit"
											onClick={() => remove(choice.name)}
										/>
									</button>
								</div>
							</Form.Item>
						))}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => add({ id: uniqueID(), text: '' })}
								className="flex items-center justify-center"
								icon={<PlusOutlined />}
							>
								Add choice
							</Button>
							<Form.ErrorList errors={errors} />
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	)
}

SQQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}
