/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from 'react-sortable-hoc'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	MenuOutlined,
	PlusOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import qs from 'query-string'

import {
	AddNewLessonModal,
	getSearchFilterProps,
	getSelectFilterProps
} from '../../components'
import { useLessons, useLevels, useSubjects } from '../../hooks'
import { useUser } from '../../store/hooks'
import { getUrl } from '../../utils'

import EditLessonModal from './edit-btn'

import './index.scss'

const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)

export function LessonsPage() {
	const [uploadingBulk, setUploadingBulk] = useState(false)
	const fileInputRef = useRef(null)
	const { isSuper } = useUser()

	const {
		data,
		isLoading,
		fetchLessons,
		addNewLesson,
		deleteLesson,
		editLesson,
		reorderLesson,
		totalCount,
		bulkUploadLessons
	} = useLessons()

	const {
		data: subjectsData,
		isLoading: subjectsIsLoading,
		fetchSubjects
	} = useSubjects()

	const { data: levelsData, fetchAllLevels } = useLevels()

	const history = useHistory()
	const location = useLocation()
	const queryParams = useMemo(
		() => qs.parse(location.search),
		[location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history, location]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (pagination, filters, sorter) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (value?.length > 0) {
				let paramKey
				switch (key) {
					case 'subject':
						paramKey = 'subject'
						break
					case 'level':
						paramKey = 'level'
						break
					default:
						paramKey = key
						break
				}
				Object.assign(params, { [paramKey]: value[0] })
			}
		})

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params, { skipNull: true, skipEmptyString: true })
		})
		fetchLessons(params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}
	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchLessons()
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	const onChangeFile = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const file = e.target.files[0]
		if (!file) return

		setUploadingBulk(true)
		bulkUploadLessons(file).finally(() => setUploadingBulk(false))
	}

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	useEffect(() => {
		fetchLessons(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchLessons])

	const [reordering, setReordering] = useState(false)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			reorderLesson({
				_id: data[oldIndex]._id,
				destination: data[newIndex]._id
			})
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			lockAxis="y"
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			helperContainer={() => document.querySelector('.ant-table-tbody')}
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = data?.findIndex((x) => x._id === restProps['data-row-key'])
		return <SortableItem index={index} key={index} {...restProps} />
	}

	const DragHandle = useMemo(
		() =>
			sortableHandle(() => (
				<MenuOutlined className="drag-handle w-full h-8 flex items-center justify-center cursor-move text-gray-600" />
			)),
		[]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Lessons List</h1>
				<Button
					onClick={resetFilters}
					disabled={resetFiltersDisabled || uploadingBulk}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
				<input
					disabled={uploadingBulk}
					type="file"
					ref={fileInputRef}
					className="hidden"
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={onChangeFile}
				/>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => fileInputRef.current?.click()}
					icon={<UploadOutlined />}
					className="flex items-center mr-2"
				>
					BULK UPLOAD
				</Button>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page ?? 1),
					pageSize: Number(queryParams?.size ?? 10),
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				scroll={{
					y: 'calc(100vh - 242px)',
					x: 640
				}}
				tableLayout="fixed"
				rowKey={(record) => record._id}
				rowClassName="lesson-row"
				size="small"
				className="mx-4"
				loading={isLoading}
				dataSource={data}
				onChange={handleTableChange}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			>
				{!isSuper ? null : (
					<Table.Column
						dataIndex="sort"
						key="sort"
						className="drag-handle-col text-center"
						width={48}
						title={
							<Button
								type={reordering ? 'primary' : 'default'}
								icon={<MenuOutlined />}
								className="w-full h-full flex items-center justify-center"
								onClick={() => setReordering((prev) => !prev)}
							/>
						}
						render={reordering ? () => <DragHandle /> : null}
					/>
				)}
				<Table.Column
					dataIndex={['subject', 'title']}
					title="Subject"
					key="subject"
					className="subject-title-col"
					width={178}
					filteredValue={getFilterValue('subject')}
					{...getSelectFilterProps('subject', subjectsData, (record) => (
						<Select.Option value={record._id} key={record._id}>
							{record.title}
						</Select.Option>
					))}
				/>
				<Table.Column
					dataIndex={['level', 'title']}
					title="Level"
					key="level"
					className="lvl-index-col text-center"
					width={110}
					sorter
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', levelsData, (record) => (
						<Select.Option key={record._id} value={record._id}>
							{record.title}
						</Select.Option>
					))}
					filteredValue={getFilterValue('level')}
				/>
				<Table.Column
					dataIndex="priority"
					title="Lesson NO."
					key="priority"
					className="lesson-number-col highlighted-number text-center"
					width={112}
					sorter
					sortOrder={getSortOrder('sort_priority')}
				/>
				<Table.Column
					dataIndex="title"
					title="Title"
					key="title"
					className="lesson-title-col"
					sorter
					sortOrder={getSortOrder('sort_title')}
					{...getSearchFilterProps('title')}
					filteredValue={getFilterValue('title')}
				/>
				{['lightIcon', 'darkIcon'].map((item) => (
					<Table.Column
						dataIndex={item}
						title={`${item === 'lightIcon' ? 'Gray' : 'Color'} Icon`}
						key={`${item}-${Date.now()}`}
						className="text-center"
						width={98}
						render={(val) => {
							return (
								<div className="h-8 overflow-hidden">
									{val ? (
										<img
											src={getUrl(val.path)}
											alt={val.type}
											className="h-full w-auto mx-auto"
										/>
									) : (
										'not set'
									)}
								</div>
							)
						}}
					/>
				))}
				<Table.Column
					title="Status"
					dataIndex="status"
					key="status"
					className="text-center"
					width={98}
					sorter
					sortOrder={getSortOrder('sort_status')}
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
					render={(val) =>
						val === 'active' ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit_lesson',
										record_id: record._id
									})
								}
							/>
						</div>
					)}
				/>
			</Table>
			{uploadingBulk ? null : (
				<>
					<EditLessonModal
						onClose={handleCloseModal}
						onEdit={editLesson}
						onDelete={(id) => deleteLesson(id)}
						queryParams={queryParams}
						subjects={subjectsData}
						subjectsIsLoading={subjectsIsLoading}
					/>
					<AddNewLessonModal
						visible={
							!!(queryParams?.modal_is_open && queryParams.mode === 'add') ||
							false
						}
						setVisible={setModalVisibility}
						onAdd={addNewLesson}
						subjects={subjectsData}
						subjectsIsLoading={subjectsIsLoading}
					/>
				</>
			)}
		</>
	)
}

export default LessonsPage
