/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {('admin_question'|'admin_quiz'|'admin_revision' | 'admin_microrevision')} QuestionCategory
 */
/**
 * @typedef {object} Messages
 * @property {string} [loading]
 * @property {string} [success]
 * @property {string} [error]
 */

export default function useQuestions() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchQuestions = useCallback(
		/**
		 * @param {QuestionCategory} [questionCategory]
		 * @param {object} queryParams - request query parameters as an object
		 */
		(questionCategory = 'admin/micro-lessons', queryParams) => {
			return new Promise((resolve, reject) => {
				const {
					page: _page,
					size: _size,
					sort_level,
					sort_lesson,
					sort_subject,
					sort_type,
					sort_order,
					sort_lessonPriority,
					...params
				} = queryParams

				params.offset = _page
				params.limit = _size

				if ('sort_lesson' in queryParams) {
					params.sortLesson = +sort_lesson ? 'desc' : 'asc'
				}

				if ('sort_lessonPriority' in queryParams) {
					params.sortLessonPriority = +sort_lessonPriority ? 'desc' : 'asc'
				}

				if ('sort_subject' in queryParams) {
					params.sortSubject = +sort_subject ? 'desc' : 'asc'
				}

				if ('sort_level' in queryParams) {
					params.sortLevel = +sort_level ? 'desc' : 'asc'
				}

				if ('sort_type' in queryParams) {
					params.sortQuestionType = +sort_type ? 'desc' : 'asc'
				}

				if ('sort_order' in queryParams) {
					params.sortOrder = +sort_order ? 'desc' : 'asc'
				}

				setLoading(true)
				const endpoint = `/${questionCategory}?${qs.stringify(params, {
					skipNull: true
				})}`

				axios
					.get(endpoint)
					.then((res) => {
						const { rows: microLessons, count: total_count } = res.data.data
						setTotalCount(total_count)
						setData(microLessons)
						resolve(microLessons)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchQuestion = useCallback(
		/**
		 * @param {string} id - question ID
		 * @param {QuestionCategory} [questionCategory]
		 */
		(id, questionCategory = 'admin/micro-lessons') => {
			return new Promise((resolve, reject) => {
				const endpoint = `/${questionCategory}/${id}`

				axios
					.get(endpoint)
					.then(({ data: { data: question } }) => {
						resolve(question)
					})
					.catch(reject)
			})
		},
		[]
	)

	/**
	 * @typedef {object} Options
	 * @property {bool} [refetchOnSuccess]
	 */
	const addNewQuestion = useCallback(
		/**
		 * @param {*} payload
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Options} [options]
		 */
		(payload, questionCategory = 'admin/micro-lessons', options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const endpoint = `/${questionCategory}`
				axios
					.post(endpoint, payload)
					.then(({ data: { data: newQuestion } }) => {
						enableUpdateStatistics()
						if (refetchOnSuccess)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve(newQuestion)
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	const deleteQuestion = useCallback(
		/**
		 * @param {string} id - question _id
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Messages} [messages]
		 * @param {Options} [options]
		 */
		(id, questionCategory = 'admin/micro-lessons', messages, options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnDone = true } = options || {}
				message.loading({
					content: messages?.loading || 'deleting question...',
					key: id
				})
				const endpoint = `/${questionCategory}/${id}`
				axios
					.delete(endpoint)
					.then(() => {
						message.success({
							content: messages?.success || 'question deleted',
							key: id
						})
						enableUpdateStatistics()
						if (refetchOnDone)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: messages?.error || 'failed to delete question',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	const editQuestion = useCallback(
		/**
		 * @param {*} editData
		 * @param {QuestionCategory} [questionCategory]
		 * @param {Options} [options]
		 */
		(editData, questionCategory = 'admin/micro-lessons', options) => {
			return new Promise((resolve, reject) => {
				const { refetchOnSuccess = true } = options || {}
				const { _id: id, ...payload } = editData || {}
				const endpoint = `/${questionCategory}/${id}`
				message.loading({
					content: 'updating question data...',
					key: id
				})
				axios
					.put(endpoint, payload)
					.then(({ data: { data: questionData } }) => {
						message.success({
							content: 'question data updated',
							key: id
						})
						enableUpdateStatistics()
						if (refetchOnSuccess)
							fetchQuestions(questionCategory, qs.parse(search))
						resolve(questionData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update question data',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestions, search]
	)

	/**
	 * @param {File} file
	 * @param {'microLessons' | 'quizQuestions' | 'revisionLessons'} category
	 */
	const bulkUploadQuestions = (file, category) => {
		return new Promise((resolve, reject) => {
			const error = new Error()
			const endpoint = '/common/images'

			const fd = new FormData()
			fd.append('uploadedFile', file)

			let relevantApiEndpoint
			switch (category) {
				case 'microLessons':
					relevantApiEndpoint = `/admin/micro-lessons`
					break
				case 'quizQuestions':
					relevantApiEndpoint = `/admin/quizes`
					break
				case 'revisionLessons':
					relevantApiEndpoint = `/admin/revisions`
					break
				default:
					message.error('Check console for errors')
					error.message =
						'question category submitted wrong, check wherever the bulkUpload method is called'
					// eslint-disable-next-line no-console
					console.error(error)
					reject(error)
			}
			const key = Date.now()
			const hideLoader = message.loading('uploading and processing file...', 0)
			axios
				.post(endpoint, fd, {
					headers: { 'Content-Type': 'multipart/form-data' }
				})
				.then((res) => {
					const path = res.data.data
					axios
						.post(`${relevantApiEndpoint}/bulk-upload?filename=${path}`)
						.then((result) => {
							message.success({
								content: 'file uploaded and processed',
								key
							})
							if (!result.data.data) resolve()
							else {
								fetchQuestions(relevantApiEndpoint, search)
									.then(() => {
										enableUpdateStatistics()
										resolve()
									})
									.catch(reject)
							}
						})
						.catch((err) => {
							message.error({
								content: 'failed to upload or process file',
								key
							})
							reject(err)
						})
						.finally(() => hideLoader())
				})
				.catch((err) => {
					message.error({
						content: 'failed to upload or process file',
						key
					})
					reject(err)
				})
		})
	}

	return {
		data,
		isLoading,
		totalCount,
		fetchQuestions,
		editQuestion,
		deleteQuestion,
		addNewQuestion,
		bulkUploadQuestions,
		fetchQuestion
	}
}
