/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, Input, InputNumber, message, Modal, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object, string } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useTrophies } from '../../hooks'
import { confirmExit } from '../../utils'

const NOT_EDITABLE = [1, 2, 3, 4]

export default function TrophyModal({
	onClose,
	onAdd,
	onEdit,
	onDelete,
	title,
	ctaBtnText,
	queryParams
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { fetchTrophy, getOrderValue } = useTrophies()
	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form

	const canAdd = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'add' && !record_id
	}, [queryParams])

	const canEdit = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	const isNotEditable = useMemo(
		() => NOT_EDITABLE.includes(record?.order),
		[record?.order]
	)

	const modalIsVisible = useMemo(() => canEdit || canAdd, [canEdit, canAdd])

	const checkFormChanged = useCallback(() => {
		const { _id, ...rest } = record || {}
		const fieldsValues = getFieldsValue()
		setFormHasChanged(!isEqual(fieldsValues, rest))
	}, [getFieldsValue, record])

	const handleSubmit = useCallback(() => {
		validateFields().then(
			({ level1, level2, level3, level4, level5, ...values }) => {
				setIsSubmitting(true)

				const levels = [level1, level2, level3, level4, level5]
				Object.assign(values, { levels })

				if (queryParams.mode === 'add') {
					onAdd(values)
						.then(onClose)
						.finally(() => setIsSubmitting(false))
				} else if (queryParams.mode === 'edit' && record) {
					const { icon, ...payload } = values
					Object.assign(payload, { _id: record._id })
					if (icon !== record.icon) Object.assign(payload, { icon })

					onEdit(payload)
						.then(onClose)
						.finally(() => setIsSubmitting(false))
				}
			}
		)
	}, [validateFields, onAdd, onClose, onEdit, queryParams, record])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	useEffect(() => {
		if (!canEdit) return

		setIsFetching(true)
		fetchTrophy(queryParams?.record_id)
			.then((data) => {
				setRecord(data)
				setFieldsValue(data)
			})
			.catch(() => {
				message.error("Couldn't fetch trophy data")
				onClose()
			})
			.finally(() => setIsFetching(false))
	}, [fetchTrophy, onClose, queryParams, setFieldsValue, canEdit])

	const handleDelete = useCallback(() => {
		onDelete(record?._id).then(onClose)
	}, [onClose, onDelete, record?._id])

	useEffect(() => {
		if (!canAdd) return

		getOrderValue().then((order) => setFieldsValue({ order }))
	}, [canAdd, getOrderValue, setFieldsValue])

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold mb-0">{title}</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			centered
			okText="Save"
			onOk={handleSubmit}
			okButtonProps={{
				disabled: !formHasChanged,
				className: 'inline-flex flex-row items-center justify-center'
			}}
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						onClick={handleSubmit}
						className="inline-flex items-center justify-center leading-none"
					>
						{ctaBtnText}
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_trophy"
					labelCol={{
						span: 5
					}}
					onFieldsChange={checkFormChanged}
					preserve={false}
				>
					<Form.Item name="order" label="Order">
						<InputNumber readOnly />
					</Form.Item>
					<Form.Item name="name" label="Name">
						<Input readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="description" label="Description">
						<Input readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="event" label="Event">
						<Input readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level1" label="Level 1">
						<InputNumber />
					</Form.Item>
					<Form.Item name="level2" label="Level 2">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level3" label="Level 3">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level4" label="Level 4">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item name="level5" label="Level 5">
						<InputNumber readOnly={isNotEditable} />
					</Form.Item>
					<Form.Item
						name="icon"
						key="icon"
						label="Icon"
						rules={[
							{
								required: true,
								message: 'Please select icon file of trophy'
							}
						]}
						className="flex flex-row items-center"
						getValueFromEvent={() => record?.icon}
					>
						<div>
							<SingleFileUploader
								type="other"
								onlyPNG
								defaultFile={record?.icon?.path}
								afterUpload={(fileID) => {
									setFieldsValue({ icon: fileID })
									checkFormChanged()
								}}
							/>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
TrophyModal.propTypes = {
	onClose: func.isRequired,
	onAdd: func,
	onEdit: func,
	onDelete: func,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	title: string,
	ctaBtnText: string
}

TrophyModal.defaultProps = {
	onAdd: undefined,
	onEdit: undefined,
	onDelete: undefined,
	title: '',
	ctaBtnText: ''
}
