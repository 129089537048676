/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	LockOutlined,
	MailOutlined,
	UserOutlined
} from '@ant-design/icons'
import { Form, Input, Modal, Switch } from 'antd'
import { bool, func } from 'prop-types'

import { confirmExit } from '../../utils'
import SingleFileUploader from '../single-file-uploader'

export default function AddNewAdminModal({ visible, setVisible, onAdd }) {
	const [passwordIsVisible, setPasswordVisible] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [form] = Form.useForm()

	const closeModal = () => {
		form.resetFields()
		setIsSubmitting(false)
		setVisible(false)
	}

	const togglePasswordVisibility = () => setPasswordVisible((state) => !state)

	const onCancel = () => {
		const formIsEmpty = Object.values(form.getFieldsValue()).every((el) => !el)

		confirmExit(closeModal, !formIsEmpty)
	}

	const handleAddNewData = () => {
		form.validateFields().then((values) => {
			setIsSubmitting(true)
			onAdd(values)
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	return (
		<Modal
			visible={visible}
			okText="ADD NEW ADMIN"
			onOk={handleAddNewData}
			okButtonProps={{
				className: 'inline-flex items-center justify-center leading-none'
			}}
			onCancel={onCancel}
			confirmLoading={isSubmitting}
			title={<h3 className="font-bold">New Admin</h3>}
			destroyOnClose
		>
			<Form
				form={form}
				name="add_new_subject"
				labelCol={{
					span: 5
				}}
				initialValues={{
					name: '',
					email: '',
					password: '',
					confirmPassword: '',
					avatarIcon: '',
					isSuper: false
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{ required: true, message: 'Please enter a name for new admin' }
					]}
				>
					<Input autoFocus type="text" prefix={<UserOutlined />} />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{ required: true, message: 'Please enter an email address' },
						{ type: 'email', message: 'Email is not valid' }
					]}
				>
					<Input type="text" prefix={<MailOutlined />} autoComplete="email" />
				</Form.Item>
				<Form.Item
					name="password"
					hasFeedback
					label="Password"
					rules={[
						{
							required: true,
							message: 'Please enter a password'
						},
						{
							pattern: /[0-9a-zA-Z?!@#$%^&*-_]/,
							message:
								'Only numbers, english letters and special characters is allowed'
						}
					]}
				>
					<Input
						allowClear
						autoComplete="new-password"
						dir="ltr"
						type={passwordIsVisible ? 'text' : 'password'}
						prefix={<LockOutlined />}
						suffix={
							<button
								type="button"
								className="focus:text-blue-500 ml-1 flex items-center justify-center outline-none focus:outline-black border-none cursor-pointer bg-inherit"
								onClick={togglePasswordVisibility}
							>
								{passwordIsVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
							</button>
						}
					/>
				</Form.Item>
				<Form.Item
					name="confirmPassword"
					label="Confirm Password"
					hasFeedback
					dependencies={['password']}
					rules={[
						{
							required: true,
							message: 'Please re-enter password'
						},
						({ getFieldValue }) => ({
							validator(_rule, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve()
								}
								return Promise.reject(Error('Passwords do not match'))
							}
						})
					]}
				>
					<Input
						allowClear
						dir="ltr"
						type={passwordIsVisible ? 'text' : 'password'}
						prefix={<LockOutlined />}
					/>
				</Form.Item>
				<Form.Item name="isSuper" label="Super Admin" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item
					name="avatarIcon"
					key="avatarIcon"
					label="Avatar Icon"
					className="flex flex-row items-center"
				>
					<div>
						<SingleFileUploader
							type="admin"
							onlyPNG
							afterUpload={(fileID) => {
								form.setFieldsValue({ avatarIcon: fileID })
							}}
						/>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	)
}
AddNewAdminModal.propTypes = {
	visible: bool.isRequired,
	setVisible: func.isRequired,
	onAdd: func.isRequired
}
