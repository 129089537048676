/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isCollapsed: false
}

const reducer = {
	setIsCollapsed: (state, action) => {
		state.isCollapsed = action.payload
	}
}

const slice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: reducer
})

const select = (state) => state.sidebar

const sidebarReducer = slice.reducer
export { sidebarReducer, slice as sidebarSlice, select as selectSidebar }
