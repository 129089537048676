/* eslint-disable camelcase */
import { Form, Input, Radio } from 'antd'
import { object } from 'prop-types'

export default function TFQuestion({
	formInstance,
	initialValues = {
		text: '',
		correct: false
	}
}) {
	return (
		<Form name="TF_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="text"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item
				name="correct"
				label="Answer"
				// valuePropName="checked"
			>
				<Radio.Group>
					<Radio value>True</Radio>
					<Radio value={false}>False</Radio>
				</Radio.Group>
				{/* <Switch
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					className="focus:outline-black"
				/> */}
			</Form.Item>
		</Form>
	)
}

TFQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}
