/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} RespondToReportPayload
 * @property {string} _id
 * @property {string} respond
 */

export default function useReports() {
	const [data, setData] = useState({
		reports: [],
		totalCount: 0
	})
	const [isLoading, setLoading] = useState(true)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()
	const fetchReports = useCallback(
		/**
		 * @param {object} queryParams
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				const {
					page: _page,
					size: _size,
					sort_respondAt,
					...params
				} = queryParams

				params.offset = _page
				params.limit = _size

				if (typeof sort_respondAt === 'number') {
					params.sortKey = 'respondAt'
					params.sortType = sort_respondAt === 1 ? 'desc' : 'asc'
				}

				setLoading(true)
				const endpoint = `/admin/bug-reports?${qs.stringify(params, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: reports, count: total_count } = res.data
						setData({
							reports: reports.map((el) => {
								const payload = {
									...el,
									record_id: {
										lesson: el.lesson?._id,
										revision: el.revision?._id,
										micro_lesson: el.microLesson?._id,
										quiz: el.quiz?._id
									}[el.type]
								}

								// eslint-disable-next-line no-use-before-define
								payload.reference = getReferenceValue(el.type, el)

								return payload
							}),
							totalCount: total_count
						})
						resolve(reports)
					})
					.catch(reject)
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchReport = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`report id is not provided`))
				} else {
					axios
						.get(`/admin/bug-reports/${id}`)
						.then(({ data: { data: reportData } }) => {
							const { lesson, microLesson, revision, quiz } = reportData
							const payload = {
								...reportData,
								record_id:
									lesson?._id || microLesson?._id || revision?._id || quiz?._id
							}

							// eslint-disable-next-line no-use-before-define
							payload.reference = getReferenceValue(reportData.type, reportData)

							resolve(payload)
						})
						.catch(reject)
				}
			})
		},
		[]
	)

	const respondToReport = useCallback(
		/**
		 * @param {RespondToReportPayload} payload
		 */
		({ _id: report_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'submitting report response...',
					key: report_id
				})

				axios
					.put(`/admin/bug-reports/${report_id}`, payload)
					.then(({ data: { data: reportData } }) => {
						message.success({
							content: 'your response submitted successfully',
							key: report_id
						})
						enableUpdateStatistics()
						fetchReports(qs.parse(search))
						resolve(reportData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to submit your response',
							key: report_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchReports, search]
	)

	return {
		data: data.reports,
		totalCount: data.totalCount,
		isLoading,
		fetchReports,
		fetchReport,
		respondToReport
	}
}

/**
 * getReferenceType
 * @param {'revision'|'micro_lesson'|'quiz'|'lesson'} type
 * @param {{}} report
 * @return {string}
 */
function getReferenceValue(type, report) {
	switch (type) {
		case 'revision':
			return `Revision ${report.revision.level.title}`
		case 'micro_lesson':
			return `Micro Lesson ${report.microLesson.order} ${report.microLesson.level.title}`
		case 'quiz':
			return `Quiz ${report.quiz.level}`
		case 'lesson':
			return `Lesson ${report.lesson.priority} ${report.lesson.level.title}`
		default:
			throw new Error(`Unknown type found i.e.: ${type}`)
	}
}
