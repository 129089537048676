/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { StarFilled, StarOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { bool, func, object } from 'prop-types'

import { Icon } from '../../components'
import { useRatings } from '../../hooks'
import { confirmExit, uniqueID } from '../../utils'

// eslint-disable-next-line import/no-cycle
import { modalModes } from './index'

export default function ViewAndRespondModal({
	isVisible,
	onClose,
	onRequest,
	queryParams,
	setModalType,
	setModalVisibility
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [data, setData] = useState(undefined)
	const { setFieldsValue, resetFields, validateFields, getFieldValue } = form
	const { fetchRating } = useRatings()

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'view_and_reply' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { respond } = data
		setFormHasChanged(false)
		setFormHasChanged(!isEqual(getFieldValue('respond'), respond))
	}, [data, getFieldValue])

	const closeModal = useCallback(() => {
		onClose()
		setIsSubmitting(false)
		setFormHasChanged(false)
		resetFields()
	}, [onClose, resetFields])

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)

			onRequest({
				_id: data._id,
				...values
			})
				.then(closeModal)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEdit = () => {
		confirmExit(closeModal, formHasChanged)
	}

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchRating(queryParams.record_id)
				.then((ratingData) => {
					setData(ratingData)
					setFieldsValue(ratingData)
				})
				.catch(() => {
					message.error("Couldn't fetch rating data")
					closeModal()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		closeModal,
		fetchRating,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={isVisible !== undefined ? isVisible : modalIsVisible}
			onCancel={handleCancelEdit}
			onOk={handleSubmitEdit}
			title={<h3 className="font-bold">Respond to Rating</h3>}
			okText="Submit"
			okButtonProps={{
				disabled: !formHasChanged,
				className: 'inline-flex flex-row items-center justify-center'
			}}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="response_to_rating"
					labelCol={{
						span: 4
					}}
					preserve={false}
					onFieldsChange={checkFormChanged}
				>
					<Form.Item label="Stars">
						{data?.stars && (
							<>
								{Array(data.stars)
									.fill(StarFilled)
									.concat(Array(5 - data.stars).fill(StarOutlined))
									.map((Star, i) => (
										// eslint-disable-next-line react/no-array-index-key
										<Star key={`idx-${i}-id-${uniqueID()}`} />
									))}
							</>
						)}
					</Form.Item>
					<Form.Item label="Description">
						<Input
							readOnly
							bordered={false}
							value={data?.description ?? 'Without description!'}
						/>
					</Form.Item>
					<Form.Item label="User">
						<Button
							type="default"
							className="edit-btn px-2 py-1 mr-2"
							onClick={() => {
								setModalType('view_user')
								setModalVisibility(true, {
									mode: modalModes.viewUser,
									record_id: data?.user._id
								})
							}}
						>
							<Icon
								variant="user"
								width={24}
								height={24}
								fill="currentColor"
								className="mr-2"
							/>
							{data?.user.name}
						</Button>
					</Form.Item>
					<Form.Item name="respond" label="Response">
						<Input.TextArea />
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
ViewAndRespondModal.propTypes = {
	isVisible: bool,
	onClose: func.isRequired,
	onRequest: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	setModalType: func.isRequired,
	setModalVisibility: func.isRequired
}
ViewAndRespondModal.defaultProps = {
	isVisible: undefined
}
