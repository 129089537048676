/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, message, Modal, Select, Spin } from 'antd'
import isEqual from 'lodash.isequal'
import { func, object } from 'prop-types'

import { SingleFileUploader } from '../../components'
import { useQuestionTypes } from '../../hooks'
import { confirmExit } from '../../utils'

export default function EditQTBtn({ onClose, onEdit, queryParams }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)
	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form
	const { fetchQuestionType } = useQuestionTypes()

	const modalIsVisible = useMemo(() => {
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit' && record_id
	}, [queryParams])

	const checkFormChanged = useCallback(() => {
		const { _id, user_id, hint_show_answer, ...initialData } = record
		setFormHasChanged(!isEqual(getFieldsValue(), initialData))
	}, [getFieldsValue, record])

	const handleSubmitEdit = () => {
		validateFields().then((values) => {
			setIsSubmitting(true)
			const { icon, ...payload } = values
			Object.assign(payload, { _id: record._id })
			if (record?.icon !== values.icon)
				Object.assign(payload, { icon: values.icon })

			onEdit(payload)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchQuestionType(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch question type data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		onClose,
		fetchQuestionType,
		modalIsVisible,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold">Edit Question Type</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			okText="Save"
			onOk={handleSubmitEdit}
			okButtonProps={{
				disabled: !formHasChanged,
				className: 'inline-flex flex-row items-center justify-center'
			}}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_question_type"
					labelCol={{
						span: 8
					}}
					preserve={false}
					onFieldsChange={checkFormChanged}
				>
					<Form.Item name="name" label="Name">
						<Input disabled />
					</Form.Item>
					<Form.Item name="description" label="Description">
						<Input.TextArea autoFocus />
					</Form.Item>
					<Form.Item name="hint" label="Hint for users">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="button" label="CTA button text">
						<Select placeholder="Please select a text for CTA button">
							{['Continue', 'Check'].map((text) => (
								<Select.Option value={text} key={text}>
									{text}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="correctFeedback" label="Correct feedback">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="tryAgainFeedback" label="Try again feedback">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="incorrectFeedback" label="Incorrect feedback">
						<Input.TextArea />
					</Form.Item>
					{/* <Form.Item
						name="hint_show_answer"
						label="Hint show answer"
						valuePropName="checked"
					>
						<Switch />
					</Form.Item> */}
					<Form.Item
						name="iconSource"
						label="User hint icon"
						rules={[
							{
								required: true,
								message: 'Please select an icon file for user hint'
							}
						]}
						className="flex flex-row items-center"
						getValueFromEvent={() => record?.iconSource}
					>
						<div>
							<SingleFileUploader
								type="question_type"
								onlyPNG
								defaultFile={record?.iconSource}
								afterUpload={(fileID) => {
									setFieldsValue({ iconSource: fileID })
									checkFormChanged()
								}}
							/>
						</div>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
EditQTBtn.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	queryParams: object
}

EditQTBtn.defaultProps = {
	queryParams: {}
}
