/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios, getUrl } from '../utils'

/**
 * @typedef {object} EditQuestionTypePayload
 * @property {string} _id
 * @property {string} name
 * @property {string} description
 * @property {string} hint
 * @property {string} button
 * @property {string} correctFeedback
 * @property {string} tryAgainFeedback
 * @property {string} incorrectFeedback
 * @property {boolean} showHint
 * @property {string} icon
 */

/**
 * @typedef {object} UpdateQuestionTypeIconPayload
 * @property {string} question_type_id
 * @property {string} icon
 */

function useQuestionTypes() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()

	const fetchQuestionTypes = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin/question-types?limit=100')
				.then(({ data: res }) => {
					const { rows: questionTypes } = res.data
					const transformedData = questionTypes.sort((a, b) =>
						a.name > b.name ? 1 : -1
					)
					setData(transformedData)
					resolve(questionTypes)
				})
				.catch((err) => {
					reject(err)
				})
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchQuestionType = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) reject(new Error(`question type id is not provided`))
				else {
					setLoading(true)
					axios
						.get(`/admin/question-types/${id}`)
						.then(({ data: res }) => {
							res.data.iconSource = res.data.icon && getUrl(res.data.icon.path)
							resolve(res.data)
						})
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	// const updateQuestionTypeIcon = useCallback(
	// 	/**
	// 	 * @param {UpdateQuestionTypeIconPayload} payload
	// 	 */
	// 	(payload) => {
	// 		return new Promise((resolve, reject) => {
	// 			const { question_type_id } = payload
	// 			message.loading({
	// 				content: 'updating question type hint icon...',
	// 				key: question_type_id
	// 			})
	// 			axios
	// 				.post('/admin/question-types/icon_ids', payload)
	// 				.then(({ data: questionTypeNewIcon }) => {
	// 					message.success({
	// 						content: 'question type hint icon updated',
	// 						key: question_type_id
	// 					})
	// 					resolve(questionTypeNewIcon)
	// 				})
	// 				.catch((err) => {
	// 					message.error({
	// 						content: 'failed to update question type hint icon',
	// 						key: question_type_id
	// 					})
	// 					reject(err)
	// 				})
	// 		})
	// 	},
	// 	[]
	// )

	const editQuestionType = useCallback(
		/**
		 * @param {EditQuestionTypePayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const { _id: question_type_id, iconSource, ...restPayload } = payload
				message.loading({
					content: 'editing question type data...',
					key: question_type_id
				})

				const hasUploaded = /temp/g.test(iconSource)
				if (hasUploaded) restPayload.icon = iconSource
				axios
					.put(`/admin/question-types/${question_type_id}`, restPayload)
					.then(({ data: res }) => {
						const { data: editedQT } = res.data
						message.success({
							content: 'question type data updated',
							key: question_type_id
						})
						enableUpdateStatistics()
						fetchQuestionTypes()
						resolve(editedQT)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update question type',
							key: question_type_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchQuestionTypes]
	)

	return {
		data,
		isLoading,
		fetchQuestionTypes,
		editQuestionType,
		fetchQuestionType
	}
}
export default useQuestionTypes
