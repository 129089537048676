import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import cn from 'classnames'

import logo from '../../assets/img/logo.png'
import { useUser } from '../../store/hooks'

import styles from './styles.module.scss'

export default function ForgotPasswordPage() {
	const [form] = Form.useForm()
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)

	const user = useUser()

	const handleSubmit = () => {
		form.validateFields().then(({ email }) => {
			setIsLoading(true)
			user
				.forgetPassword(email)
				.then((data) => {
					message.success(data?.message)
				})
				.finally(() => setIsLoading(false))
		})
	}

	return (
		<div
			className={cn(
				styles['forgotPassword-page'],
				'forgotPassword-page h-full w-full flex flex-col items-center justify-center'
			)}
		>
			<div className="max-w-xs m-4 hidden sm:block">
				<img
					src={logo}
					alt="Deenee logo"
					className="h-full object-contain mr-5"
				/>
			</div>
			<div className="w-full h-full flex flex-col items-center justify-center sm:w-2/3 md:w-3/5 lg:w-1/2 2xl:w-1/3 sm:h-auto bg-white px-12 py-5 sm:m-4 bg-opacity-95 rounded-sm shadow-xl">
				<button
					type="button"
					className="text-s text-blue-500 hover:text-blue-700"
					style={{
						outline: 'none',
						alignSelf: 'flex-start',
						textDecoration: 'underline'
					}}
					onClick={() => {
						history.goBack()
					}}
				>
					◁ Back to sign in page
				</button>
				<h1 className="text-xl">Forgot Password</h1>
				<p className="text-l mb-5" style={{ textAlign: 'center' }}>
					Enter your email address below and we will send a password reset
					email.
				</p>
				<Form
					form={form}
					name="login"
					className="w-full"
					initialValues={{ remember: true }}
					onFinish={handleSubmit}
				>
					<Form.Item
						name="email"
						rules={[
							{ required: true, message: 'Please input your email' },
							{ type: 'email', message: 'Email is invalid' }
						]}
					>
						<Input
							prefix={<UserOutlined className="form-item-icon" />}
							placeholder="Email address"
							type="email"
						/>
					</Form.Item>
					<Form.Item name="submit">
						<Button
							type="primary"
							htmlType="submit"
							className="flex items-center justify-center"
							block
							size="large"
							loading={isLoading}
						>
							Send
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}
