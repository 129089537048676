/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

const BASE_URL = '/admin/trophies'

export default function useTrophies() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchTrophies = useCallback((queryParams) => {
		const { page: _page, size: _size, ...params } = queryParams
		params.offset = _page
		params.limit = _size

		setLoading(true)
		return new Promise((resolve, reject) => {
			const endpoint = `${BASE_URL}?${qs.stringify(params, {
				skipNull: true
			})}`

			axios
				.get(endpoint)
				.then(({ data: res }) => {
					const { rows: trophiesData, count: total_count } = res.data

					setTotalCount(total_count)
					setData(trophiesData)
					resolve(trophiesData)
				})
				.catch(reject)
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchTrophy = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`${BASE_URL}/${id}`)
					.then(({ data: res }) => {
						const { data: trophyData } = res
						resolve(trophyData)
					})
					.catch(reject)
			})
		},
		[]
	)

	const addNewTrophy = useCallback(
		(payload) => {
			const key = Date.now()
			message.loading({
				content: 'adding new trophy...',
				key
			})

			return new Promise((resolve, reject) => {
				axios
					.post(BASE_URL, payload)
					.then(({ data: newTrophy }) => {
						message.success({
							content: 'new trophy added',
							key
						})
						enableUpdateStatistics()
						fetchTrophies(qs.parse(search))
						resolve(newTrophy)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new trophy',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchTrophies, search]
	)

	const deleteTrophy = useCallback(
		(trophy_id) => {
			if (!trophy_id) throw new Error('trophy ID not provided')

			message.loading({
				content: 'deleting trophy...',
				key: trophy_id
			})

			return new Promise((resolve, reject) => {
				axios
					.delete(`${BASE_URL}/${trophy_id}`)
					.then(() => {
						message.success({
							content: 'trophy deleted',
							key: trophy_id
						})
						enableUpdateStatistics()
						fetchTrophies(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to delete trophy',
							key: trophy_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchTrophies, search]
	)

	const editTrophy = useCallback(
		({ _id: trophy_id, icon, ...payload }) => {
			message.loading({
				content: 'updating trophy data...',
				key: trophy_id
			})

			const regex = /^temp\//i
			const hasTempInIcon = regex.test(icon)
			if (hasTempInIcon) Object.assign(payload, { icon })

			return new Promise((resolve, reject) => {
				axios
					.put(`${BASE_URL}/${trophy_id}`, payload)
					.then(({ data: trophyData }) => {
						message.success({
							content: 'trophy data updated',
							key: trophy_id
						})

						enableUpdateStatistics()
						fetchTrophies(qs.parse(search))
						resolve(trophyData)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update trophy data',
							key: trophy_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchTrophies, search]
	)

	const reorderTrophy = useCallback(
		({ _id: trophy_id, ...payload }) => {
			message.loading({
				content: 'reordering trophy...',
				key: trophy_id
			})

			return new Promise((resolve, reject) => {
				axios
					.put(`${BASE_URL}/reorder/${trophy_id}`, payload)
					.then(() => {
						message.success({ content: 'trophy reordered', key: trophy_id })
						enableUpdateStatistics()
						fetchTrophies(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to reorder trophy',
							key: trophy_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchTrophies, search]
	)

	const getOrderValue = useCallback(() => {
		return new Promise((resolve) => {
			axios.get(`${BASE_URL}/order`).then((res) => {
				const order = res.data.data
				resolve(order)
			})
		})
	}, [])

	return {
		data,
		isLoading,
		totalCount,
		fetchTrophies,
		fetchTrophy,
		editTrophy,
		reorderTrophy,
		addNewTrophy,
		deleteTrophy,
		getOrderValue
	}
}
