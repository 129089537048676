/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { AddNewAdminModal } from '../../components'
import { useAdmins } from '../../hooks'

// import { useUser } from '../../store/hooks'
import EditAdminModal from './edit-btn'

export function AdminsPage() {
	// const { isSuper } = useUser()

	const history = useHistory()
	const location = useLocation()
	const queryParams = useMemo(
		() => qs.parse(location.search),
		[location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history, location]
	)

	const { data, isLoading, fetchAdmins, deleteAdmin, editAdmin, addNewAdmin } =
		useAdmins()

	useEffect(() => {
		fetchAdmins()
	}, [fetchAdmins])

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold">Deenee panel administrators</h1>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					Add new admin
				</Button>
			</header>
			<Table
				size="small"
				tableLayout="fixed"
				className="mx-4"
				pagination={false}
				loading={isLoading}
				rowKey={(record) => record._id}
				rowClassName="admin-row"
				scroll={{
					y: 'calc(100vh - 199px)',
					x: 640
				}}
				dataSource={data}
			>
				<Table.Column
					title="Name"
					dataIndex="name"
					key="name"
					className="admin-title-col"
					width={256}
				/>
				<Table.Column
					title="Email"
					dataIndex="email"
					key="email"
					className="admin-description-col"
					ellipsis
				/>
				<Table.Column
					title="Verified"
					dataIndex="verified"
					key="verified"
					className="admin-status-col text-center"
					width={92}
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
				/>
				<Table.Column
					title="Super Admin"
					dataIndex="isSuper"
					key="isSuper"
					className="admin-status-col text-center"
					width={125}
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="actions-col text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() =>
									setModalVisibility(true, {
										mode: 'edit',
										record_id: record._id
									})
								}
							/>
						</div>
					)}
				/>
			</Table>
			<EditAdminModal
				onClose={handleCloseModal}
				onEdit={editAdmin}
				onDelete={(id) => deleteAdmin(id)}
				queryParams={queryParams}
			/>
			<AddNewAdminModal
				visible={
					!!(queryParams?.modal_is_open && queryParams.mode === 'add') || false
				}
				setVisible={setModalVisibility}
				onAdd={addNewAdmin}
			/>
		</>
	)
}

export default AdminsPage
