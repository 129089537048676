/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddAvatarPayload
 * @property {string} order
 * @property {string} avatarCategory
 * @property {bool} isFree
 * @property {bool} atShop
 * @property {number} coins
 * @property {number} gemLevel
 * @property {string} lightIcon
 */

/**
 * @typedef {object} EditAvatarPayload
 * @property {string} _id
 * @property {string} order
 * @property {string} avatarCategory
 * @property {bool} isFree
 * @property {bool} atShop
 * @property {number} coins
 * @property {number} gemLevel
 * @property {string} lightIcon
 */

/**
 * @typedef {object} UpdateAvatarIconsPayload
 * @property {string} order
 * @property {string} lightIcon
 */

/**
 * @typedef {object} ReOrderAvatarPayload
 * @property {string} _id
 * @property {string} destination
 */
function useAvatars() {
	const [data, setData] = useState({
		avatars: [],
		count: 0,
		pages: 0,
		page: 0
	})
	const [isLoading, setLoading] = useState(true)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchAvatars = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const {
					page: _page,
					size: _size,
					sort_order,
					sort_avatarCategory,
					...params
				} = queryParams
				params.offset = _page
				params.limit = _size

				if (typeof sort_order === 'number') {
					params.sortKey = 'order'
					params.sortType = sort_order === 1 ? 'desc' : 'asc'
				} else if (typeof sort_avatarCategory === 'number') {
					params.sortKey = 'avatarCategory'
					params.sortType = sort_avatarCategory === 1 ? 'desc' : 'asc'
				}

				const endpoint = `/admin/avatars?${qs.stringify(params, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then((res) => {
						const avatars = res.data.data.rows
						const { count, pages, page } = res.data.data
						setData({ avatars, count, pages, page })
						resolve(avatars)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchAvatar = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`avatar id is not provided`))
				} else {
					setLoading(true)
					axios
						.get(`/admin/avatars/${id}`)
						.then((res) => {
							const avatarData = res.data.data
							resolve(avatarData)
						})
						.catch((err) => {
							reject(err)
						})
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	const addNewAvatar = useCallback(
		/**
		 * @param {AddAvatarPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				// const { icon_dark, icon_light, ...restPayload } = payload
				const key = Date.now()
				message.loading({
					content: 'adding new avatar...',
					key
				})
				axios
					.post('/admin/avatars', payload)
					.then(({ data: newAvatar }) => {
						message.success({
							content: 'new avatar added',
							key
						})
						enableUpdateStatistics()
						// const { _id: avatar_id } = newAvatar

						// updateAvatarIcons({ avatar_id, icon_light, icon_dark })
						// 	.then((avatarIconsData) => {
						// 		Object.assign(newAvatar, avatarIconsData)
						// 	})
						// 	.finally(() => {
						// 		fetchAvatars(qs.parse(search))
						// 		resolve(newAvatar)
						// 	})
						resolve(newAvatar)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new avatar',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics]
	)

	const deleteAvatar = useCallback(
		/**
		 * @param {string} id - avatar _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error(`avatar id is not provided`))
				} else {
					message.loading({ content: 'deleting avatar...', key: id })
					axios
						.delete(`/admin/avatars/${id}`)
						.then(() => {
							message.success({
								content: 'avatar deleted',
								key: id
							})
							enableUpdateStatistics()
							fetchAvatars(qs.parse(search))
							resolve()
						})
						.catch((err) => {
							message.error({
								content: 'deleting avatar operation failed',
								key: id
							})
							reject(err)
						})
				}
			})
		},
		[enableUpdateStatistics, fetchAvatars, search]
	)

	const editAvatar = useCallback(
		/**
		 * @param {EditAvatarPayload} payload
		 */
		({ lightIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				const { _id, ...restPayload } = payload || {}
				message.loading({
					content: 'updating avatar data...',
					key: _id
				})

				const regex = /^temp\//i
				const hasTempInDark = regex.test(lightIcon)
				if (hasTempInDark) Object.assign(payload, { lightIcon })

				axios
					.put(`/admin/avatars/${payload._id}`, restPayload)
					.then((res) => {
						const updatedAvatar = res.data.data

						message.success({ content: 'avatar edited', key: _id })

						enableUpdateStatistics()

						fetchAvatars(qs.parse(search))
						resolve(updatedAvatar)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update avatar',
							key: _id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAvatars, search]
	)

	const reorderAvatar = useCallback(
		/**
		 * @param {ReOrderAvatarPayload} payload
		 */
		({ _id: avatar_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'reordering avatar...',
					key: avatar_id
				})

				axios
					.put(`/admin/avatars/reorder/${avatar_id}`, payload)
					.then(() => {
						message.success({ content: 'avatar reordered', key: avatar_id })
						enableUpdateStatistics()
						fetchAvatars(qs.parse(search))
					})
					.catch((err) => {
						message.error({
							content: 'failed to reorder avatar',
							key: avatar_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAvatars, search]
	)

	return {
		data: data.avatars,
		count: data.count,
		pages: data.pages,
		page: data.page,
		isLoading,
		fetchAvatars,
		fetchAvatar,
		addNewAvatar,
		deleteAvatar,
		editAvatar,
		reorderAvatar
	}
}
export default useAvatars
