/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	PlusOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import qs from 'query-string'

// import moment from 'moment'
import { getSearchFilterProps, getSelectFilterProps } from '../../components'
import {
	useLevels,
	useQuestions,
	useQuestionTypes,
	useSubjects
} from '../../hooks'

import QuestionModal from './modal'

const ADD_MODAL = {
	title: 'New Revision Lesson',
	ctaBtnText: 'ADD NEW REVISION LESSON'
}
const EDIT_MODAL = {
	title: 'Edit Revision Lesson',
	ctaBtnText: 'Save'
}

export const AVAILABLE_REVISION_TYPES = ['EAT', 'IS', 'MIS']

export default function RevisionLessons() {
	const [uploadingBulk, setUploadingBulk] = useState(false)
	const [modal, setModal] = useState()
	const [questions, setQuestions] = useState([])
	const {
		data,
		totalCount,
		isLoading,
		fetchQuestions,
		addNewQuestion,
		deleteQuestion,
		editQuestion,
		bulkUploadQuestions
	} = useQuestions()

	const fileInputRef = useRef(null)
	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const fetchData = useCallback(
		(questionCategory = 'admin/revisions', params) => {
			fetchQuestions(questionCategory, { ...params, has_question: true })
		},
		[fetchQuestions]
	)

	useEffect(() => {
		if (data) {
			setQuestions(data)
			// 	data.reduce((res, cur) => {
			// 		const { questions: curQuestions, ...rest } = cur
			// 		return res.concat(curQuestions.map((q) => ({ ...rest, question: q })))
			// 	}, []) || []
			// )
		}
	}, [data])

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const {
		data: subjectsData,
		fetchSubjects,
		isLoading: subjectsIsLoading
	} = useSubjects()

	const { data: levelsData, fetchAllLevels } = useLevels()
	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	const { fetchQuestionTypes, isLoading: questionTypesIsLoading } =
		useQuestionTypes()
	const [filteredTypes, setFilteredTypes] = useState([])

	useEffect(() => {
		if (queryParams.mode === 'edit_revision') {
			setModal(EDIT_MODAL)
		} else if (queryParams.mode === 'add') setModal(ADD_MODAL)
	}, [queryParams.mode])

	const handleTableChange = (pagination, filters, sorters) => {
		const { current: page, pageSize: size } = pagination
		const params = { page, size }

		Object.entries(filters).forEach(([key, value]) => {
			if (!value || !value.length) return

			switch (key) {
				case 'lesson':
					Object.assign(params, { lessonPriority: value[0] })
					break
				default:
					Object.assign(params, { [key]: value[0] })
			}
		})

		if (Array.isArray(sorters)) {
			sorters.forEach((sorter) => {
				if (sorter && sorter.column) {
					Object.assign(params, {
						[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
					})
				}
			})
		} else if (sorters.column) {
			Object.assign(params, {
				[`sort_${sorters.columnKey}`]: sorters.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params, { skipNull: true })
		})

		fetchData('admin/revisions', params)
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}

	const getFilterValue = (parameter) => {
		return queryParams[parameter] ? [queryParams[parameter]] : null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
		fetchData('admin/revisions')
	}
	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	const onChangeFile = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const file = e.target.files[0]
		if (!file) return

		setUploadingBulk(true)
		bulkUploadQuestions(file, 'revisionLessons').finally(() =>
			setUploadingBulk(false)
		)
	}

	useEffect(() => {
		fetchQuestionTypes().then((QTs) => {
			setFilteredTypes(
				QTs.filter((el) => AVAILABLE_REVISION_TYPES.includes(el.name))
			)
		})
	}, [fetchQuestionTypes])

	useEffect(() => {
		fetchSubjects()
	}, [fetchSubjects])

	useEffect(() => {
		fetchData('admin/revisions', queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchData])

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold mr-auto">Revision Lessons List</h1>
				<Button
					disabled={resetFiltersDisabled || uploadingBulk}
					onClick={resetFilters}
					type="default"
					className="mr-2"
				>
					Reset All Filters
				</Button>
				<input
					disabled={uploadingBulk}
					type="file"
					ref={fileInputRef}
					className="hidden"
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={onChangeFile}
				/>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => fileInputRef.current?.click()}
					icon={<UploadOutlined />}
					className="flex items-center mr-2"
				>
					BULK UPLOAD
				</Button>
				<Button
					disabled={uploadingBulk}
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				loading={isLoading}
				dataSource={questions}
				className="mx-4 text-xs"
				rowClassName="text-xs"
				tableLayout="fixed"
				size="small"
				scroll={{
					y: 'calc(100vh - 239px)',
					x: 1024
				}}
				pagination={{
					position: ['bottomCenter'],
					total: totalCount,
					current: Number(queryParams?.page) || 1,
					pageSize: Number(queryParams?.size) || 10,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30']
				}}
				onChange={handleTableChange}
			>
				<Table.Column
					title="Subject"
					dataIndex={['lesson', 'subject', 'title']}
					key="subject"
					sorter={{ multiple: 4 }}
					sortOrder={getSortOrder('sort_subject')}
					{...getSelectFilterProps('subject', subjectsData, (record) => (
						<Select.Option value={record._id}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('subject')}
				/>
				<Table.Column
					title="Level"
					dataIndex={['level', 'order']}
					key="level"
					sorter={{ multiple: 4 }}
					sortOrder={getSortOrder('sort_level')}
					{...getSelectFilterProps('level', levelsData, (record) => (
						<Select.Option value={record._id}>{record.title}</Select.Option>
					))}
					filteredValue={getFilterValue('level')}
				/>
				<Table.Column
					title="Lesson NO."
					dataIndex={['lesson', 'priority']}
					key="lesson"
					sorter={{ multiple: 4 }}
					sortOrder={getSortOrder('sort_lesson')}
					{...getSearchFilterProps('lesson number', true)}
					filteredValue={getFilterValue('lessonPriority')}
				/>
				<Table.Column
					title="Lesson"
					dataIndex={['lesson', 'title']}
					key="lessonTitle"
					{...getSearchFilterProps('lessons')}
					filteredValue={getFilterValue('lessonTitle')}
				/>
				{/* <Table.Column
					title="Sequence"
					dataIndex={['question', 'screen']}
					key="screen"
				/> */}
				<Table.Column
					title="Q-Type"
					dataIndex={['type', 'name']}
					key="type"
					sorter={{ multiple: 4 }}
					sortOrder={getSortOrder('sort_type')}
					{...getSelectFilterProps('Question type', filteredTypes, (record) => (
						<Select.Option value={record._id}>{record.name}</Select.Option>
					))}
					filteredValue={getFilterValue('type')}
				/>
				{/* <Table.Column
					title="Status"
					dataIndex={['question', 'status']}
					key="status"
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
				/> */}
				{/* <Table.Column
					title="Last Modified"
					dataIndex="last_modified"
					key="last_modified"
					render={(val) => moment(val).format('DD MMM, YYYY')}
				/> */}
				<Table.Column
					title="Status"
					dataIndex="status"
					key="status"
					className="text-center"
					width={98}
					{...getSelectFilterProps(
						'status',
						[
							{
								_id: 'active',
								title: 'Active'
							},
							{
								_id: 'inactive',
								title: 'Inactive'
							}
						],
						(record) => (
							<Select.Option value={record._id}>{record.title}</Select.Option>
						)
					)}
					filteredValue={getFilterValue('status')}
					render={(val) =>
						val === 'active' ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
				/>
				<Table.Column
					key="actions"
					title="Actions"
					className="text-center"
					width={96}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									setModalVisibility(true, {
										mode: 'edit_revision',
										record_id: record._id
									})
								}}
							/>
							{/* <DeleteBtn
								onDelete={() => {
									deleteQuestion(
										record.question.id,
										'admin_microrevision',
										{
											loading: 'deleting revision lesson...',
											success: 'revision lesson deleted',
											error: 'failed to delete revision lesson'
										},
										{ refetchOnDone: false }
									).then(() => {
										fetchData('admin/revisions', queryParams)
									})
								}}
							/> */}
						</div>
					)}
				/>
			</Table>
			{uploadingBulk ? null : (
				<QuestionModal
					onClose={handleCloseModal}
					title={modal?.title}
					ctaBtnText={modal?.ctaBtnText}
					onAdd={(payload) => addNewQuestion(payload, 'admin/revisions')}
					onEdit={(payload) =>
						editQuestion(payload, 'admin/revisions', {
							refetchOnSuccess: false
						})
					}
					onDelete={(id) => {
						return new Promise((res, rej) => {
							deleteQuestion(
								id,
								'admin/revisions',
								{
									loading: 'deleting revision lesson...',
									success: 'revision lesson deleted',
									error: 'failed to delete revision lesson'
								},
								{ refetchOnDone: false }
							)
								.then(() => {
									fetchData('admin/revisions', queryParams)
									res()
								})
								.catch(rej)
						})
					}}
					subjects={subjectsData}
					subjectsIsLoading={subjectsIsLoading}
					questionTypes={filteredTypes}
					questionTypesIsLoading={questionTypesIsLoading}
					fetchQuestions={fetchData}
				/>
			)}
		</>
	)
}
