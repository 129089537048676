/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
	Button,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Select,
	Spin
} from 'antd'
import isEqual from 'lodash.isequal'
import { array, bool, func, object } from 'prop-types'

import { DeleteBtn, SingleFileUploader } from '../../components'
import { useLessons, useLevels } from '../../hooks'
import { axios, confirmExit } from '../../utils'

export default function EditLessonModal({
	onClose,
	onEdit,
	onDelete,
	queryParams,
	isVisible,
	subjects,
	subjectsIsLoading
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [form] = Form.useForm()
	const [formHasChanged, setFormHasChanged] = useState(false)
	const [record, setRecord] = useState(undefined)

	const { setFieldsValue, resetFields, validateFields, getFieldsValue } = form
	const [filteredLevels, setFilteredLevels] = useState([])
	const { fetchLesson } = useLessons()
	const { isLoading: levelsIsLoading, fetchLevels } = useLevels()

	const modalIsVisible = useMemo(() => {
		if (isVisible !== undefined) return isVisible
		const { modal_is_open, mode, record_id } = queryParams || {}
		return modal_is_open && mode === 'edit_lesson' && record_id
	}, [isVisible, queryParams])

	useEffect(() => {
		if (modalIsVisible) fetchLevels().then(setFilteredLevels)
		else setFilteredLevels([])
	}, [fetchLevels, modalIsVisible])

	const checkFormChanged = useCallback(() => {
		const hasChanged = !isEqual(getFieldsValue(), {
			title: record?.title,
			level: record?.level?._id,
			priority: record?.priority,
			lightIcon: record?.lightIcon,
			darkIcon: record?.darkIcon
		})
		setFormHasChanged(hasChanged)
	}, [getFieldsValue, record])

	const handleCancelEdit = () => {
		confirmExit(onClose, formHasChanged)
	}

	const onChangeLevel = useCallback(
		(levelId, sId) => {
			const formSubject = form.getFieldValue('subject')
			const isObject = typeof formSubject === 'object'
			const formSubjectId = isObject ? formSubject._id : formSubject

			const subjectId = sId ?? formSubjectId
			axios
				.get(`/admin/lessons/priority?level=${levelId}&subject=${subjectId}`)
				.then((res) => {
					const priority = res.data.data

					form.setFieldsValue({ priority })
				})
		},
		[form]
	)

	const handleSubmitEdit = useCallback(() => {
		validateFields().then((values) => {
			setIsSubmitting(true)
			const { lightIcon, darkIcon, subject, level, priority, ...payload } =
				values

			payload._id = record._id
			;['lightIcon', 'darkIcon'].forEach((name) => {
				const hasTemp = /^temp\//i.test(values[name])
				if (!hasTemp) return

				payload[name] = values[name]
			})

			if (level._id !== record.level._id) payload.level = level._id
			if (subject._id !== record.subject._id) payload.subject = subject._id

			onEdit(payload)
				.then(onClose)
				.finally(() => setIsSubmitting(false))
		})
	}, [onClose, onEdit, record, validateFields])

	const handleDelete = () => {
		onDelete(record._id).then(onClose)
	}

	const afterClose = useCallback(() => {
		setIsSubmitting(false)
		setIsFetching(false)
		setRecord(undefined)
		setFormHasChanged(false)
		resetFields()
	}, [resetFields])

	useEffect(() => {
		if (modalIsVisible) {
			setIsFetching(true)
			fetchLesson(queryParams.record_id)
				.then((data) => {
					setRecord(data)
					setFieldsValue(data)
				})
				.catch(() => {
					message.error("Couldn't fetch lesson data")
					onClose()
				})
				.finally(() => setIsFetching(false))
		}
	}, [
		fetchLesson,
		modalIsVisible,
		onClose,
		queryParams.record_id,
		setFieldsValue
	])

	return (
		<Modal
			visible={modalIsVisible}
			onCancel={handleCancelEdit}
			title={<h3 className="font-bold mb-0">Edit Lesson</h3>}
			afterClose={afterClose}
			confirmLoading={isSubmitting}
			forceRender
			destroyOnClose
			centered
			footer={
				<div className="flex flex-row flex-nowrap">
					<DeleteBtn onDelete={handleDelete} type="text" className="mr-auto" />
					<Button onClick={handleCancelEdit}>Cancel</Button>
					<Button
						type="primary"
						disabled={!formHasChanged}
						onClick={handleSubmitEdit}
						className="inline-flex flex-row items-center justify-center"
					>
						Save
					</Button>
				</div>
			}
		>
			<Spin spinning={isFetching}>
				<Form
					form={form}
					name="edit_lesson"
					labelCol={{
						span: 5
					}}
					preserve={false}
					onFieldsChange={checkFormChanged}
				>
					<Form.Item
						name={['subject', '_id']}
						label="Subject"
						rules={[
							{
								required: true,
								message: 'Please select a subject for lesson!'
							}
						]}
					>
						<Select
							notFoundContent={
								subjectsIsLoading ? <Spin size="small" /> : undefined
							}
							onChange={(subjectId) => {
								const levelId = form.getFieldValue('level')._id
								onChangeLevel(levelId, subjectId)
							}}
						>
							{subjects.map((subject) => (
								<Select.Option value={subject._id} key={subject._id}>
									{subject.title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name={['level', '_id']}
						label="Level"
						rules={[
							{
								required: true,
								message: 'Please select a level for lesson!'
							}
						]}
					>
						<Select
							notFoundContent={
								levelsIsLoading ? <Spin size="small" /> : undefined
							}
							onChange={(levelId) => {
								const subjectId = form.getFieldValue('subject')._id
								onChangeLevel(levelId, subjectId)
							}}
						>
							{filteredLevels.map((level) => (
								<Select.Option value={level._id} key={level._id}>
									{level.title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="title"
						label="Title"
						rules={[
							{
								required: true,
								message: 'Please input the title of lesson!'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="priority" label="Lesson NO.">
						<InputNumber readOnly />
					</Form.Item>
					{[
						{
							name: 'lightIcon',
							label: 'Gray Icon'
						},
						{
							name: 'darkIcon',
							label: 'Color Icon'
						}
					].map((icon) => (
						<Form.Item
							name={icon.name}
							key={icon.name}
							label={icon.label}
							rules={[
								{
									required: true,
									message: 'Please select icon file of lesson'
								}
							]}
							className="flex flex-row items-center"
							getValueFromEvent={() => record?.[icon.name].path}
						>
							<div>
								<SingleFileUploader
									type="lesson"
									onlyPNG
									defaultFile={record?.[`${icon.name}Source`]}
									afterUpload={(fileID) => {
										setFieldsValue({ [icon.name]: fileID })
										checkFormChanged()
									}}
								/>
							</div>
						</Form.Item>
					))}
					<Form.Item
						name="status"
						label="Status"
						rules={[
							{
								required: true,
								message: 'Please select a status for lesson!'
							}
						]}
					>
						<Select>
							{[
								{ id: 'active', title: 'Active' },
								{ id: 'inactive', title: 'Inactive' }
							].map((status) => (
								<Select.Option value={status.id} key={status.id}>
									{status.title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}
EditLessonModal.propTypes = {
	onClose: func.isRequired,
	onEdit: func.isRequired,
	onDelete: func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	subjects: array.isRequired,
	subjectsIsLoading: bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	queryParams: object.isRequired,
	isVisible: bool
}
EditLessonModal.defaultProps = {
	isVisible: undefined
}
