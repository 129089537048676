/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Table } from 'antd'
import qs from 'query-string'

import { AddNewLevelModal } from '../../components'
import useLevels from '../../hooks/useLevels'

import EditLevelModal from './edit-btn'

export default function LevelsPage() {
	const {
		data: levels,
		isLoading: levelsIsLoading,
		fetchAllLevels,
		addNewLevel,
		editLevel,
		deleteLevel
	} = useLevels()

	const history = useHistory()
	const queryParams = useMemo(
		() => qs.parse(history.location.search),
		[history.location.search]
	)

	const setModalVisibility = useCallback(
		(isVisible, qParams) => {
			const params = qs.parse(history.location.search)
			if (isVisible) Object.assign(params, { modal_is_open: true, ...qParams })
			else {
				delete params.modal_is_open
				delete params.mode
				delete params.record_id
			}

			history.replace({
				search: qs.stringify(params)
			})
		},
		[history]
	)

	const handleCloseModal = useCallback(
		() => setModalVisibility(false),
		[setModalVisibility]
	)

	const handleTableChange = (_pagination, _filters, sorter) => {
		const params = {}

		if (sorter.column) {
			Object.assign(params, {
				[`sort_${sorter.columnKey}`]: sorter.order === 'descend' ? 1 : 0
			})
		}

		history.replace({
			search: qs.stringify(params)
		})
	}

	const getSortOrder = (parameter) => {
		if (+queryParams[parameter] === 1) return 'descend'
		if (+queryParams[parameter] === 0) return 'ascend'
		return null
	}

	const resetFilters = () => {
		history.replace({
			search: undefined
		})
	}

	const resetFiltersDisabled = useMemo(
		() =>
			!Object.keys(queryParams).filter(
				(el) =>
					!['page', 'size', 'record_id', 'modal_is_open', 'mode'].includes(el)
			).length,
		[queryParams]
	)

	useEffect(() => {
		fetchAllLevels()
	}, [fetchAllLevels])

	return (
		<>
			<header className="flex justify-between items-center px-8 py-2">
				<h1 className="text-base font-bold m-0 mr-auto">Levels List</h1>
				<Button
					onClick={resetFilters}
					disabled={resetFiltersDisabled}
					type="default"
					className="mr-4"
				>
					Reset All Filters
				</Button>
				<Button
					type="primary"
					onClick={() => setModalVisibility(true, { mode: 'add' })}
					icon={<PlusOutlined />}
					className="flex items-center"
				>
					ADD NEW
				</Button>
			</header>
			<Table
				className="mx-4"
				size="small"
				tableLayout="fixed"
				loading={levelsIsLoading}
				rowKey={(record) => record._id}
				pagination={false}
				onChange={handleTableChange}
				scroll={{
					y: 'calc(100vh - 199px)',
					x: 420
				}}
				dataSource={levels}
			>
				<Table.Column
					dataIndex="order"
					title="Index"
					key="order"
					width={84}
					sorter={(a, b) => a.order - b.order}
					sortOrder={getSortOrder('sort_index')}
					className="text-center"
				/>

				<Table.Column dataIndex="title" title="Title" key="title" />
				<Table.Column
					title="Active"
					dataIndex="active"
					key="active"
					className="text-center"
					width={84}
					render={(val) =>
						val ? (
							<CheckOutlined className="text-green-500" />
						) : (
							<CloseOutlined className="text-red-500" />
						)
					}
					// {...getSelectFilterProps(
					// 	'status',
					// 	[
					// 		{
					// 			_id: 'active',
					// 			title: 'Active'
					// 		},
					// 		{
					// 			_id: 'inactive',
					// 			title: 'Inactive'
					// 		}
					// 	],
					// 	(record) => (
					// 		<Select.Option value={record._id}>{record.title}</Select.Option>
					// 	)
					// )}
					// defaultFilteredValue={getFilterValue('status')}
				/>
				<Table.Column
					title="Actions"
					className="w-24 text-center"
					key="actions"
					width={84}
					render={(_val, record) => (
						<div className="flex items-center justify-center">
							<Button
								type="default"
								icon={<EditOutlined />}
								className="mr-2 edit-btn"
								onClick={() => {
									sessionStorage.setItem(
										'active_edit_level',
										JSON.stringify(record)
									)
									setModalVisibility(true, {
										mode: 'edit'
									})
								}}
							/>
							{/* <DeleteBtn onDelete={() => deleteLevelsByIndex(record.index)} /> */}
						</div>
					)}
				/>
			</Table>
			<EditLevelModal
				onClose={handleCloseModal}
				onEdit={editLevel}
				onDelete={deleteLevel}
				queryParams={queryParams}
			/>
			<AddNewLevelModal
				visible={
					!!(queryParams.modal_is_open && queryParams.mode === 'add') || false
				}
				setVisible={setModalVisibility}
				onAdd={addNewLevel}
			/>
		</>
	)
}
