/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { message } from 'antd'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddSubjectPayload
 * @property {string} title
 * @property {string} description
 * @property {string} color
 * @property {string} lightIcon
 * @property {string} darkIcon
 */

/**
 * @typedef {object} EditSubjectPayload
 * @property {string} _id
 * @property {string} title
 * @property {string} description
 * @property {string} color
 * @property {string} lightIcon
 * @property {string} darkIcon
 *
 * @typedef {object} ReOrderSubjectPayload
 * @property {string} _id
 * @property {string} destination
 */

function useSubjects() {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const { enableUpdateStatistics } = useUser()
	const fetchSubjects = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin/subjects?limit=100')
				.then(({ data: res }) => {
					const { rows: subjects } = res.data
					setData(subjects)
					resolve(subjects)
				})
				.catch((err) => {
					reject(err)
				})
				.finally(() => setLoading(false))
		})
	}, [])

	const fetchSubject = useCallback(
		/**
		 * @param {string} id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject(new Error('subject ID not provided'))
				} else {
					setLoading(true)

					axios
						.get(`/admin/subjects/${id}`)
						.then(({ data: res }) => {
							const subject = {
								...res.data,
								lightIconSource: res.data.lightIcon?.path,
								darkIconSource: res.data.darkIcon?.path
							}
							resolve(subject)
						})
						.catch(reject)
						.finally(() => setLoading(false))
				}
			})
		},
		[]
	)

	// const updateSubjectIcons = useCallback(
	// 	/**
	// 	 * @param {UpdateSubjectIconsPayload} payload
	// 	 */
	// 	(payload) => {
	// 		return new Promise((resolve, reject) => {
	// 			const { subject_id } = payload
	// 			message.loading({
	// 				content: 'updating subject icons...',
	// 				key: subject_id
	// 			})
	// 			axios
	// 				.post('/admin/subject/icon_ids', payload)
	// 				.then(({ data: subjectNewIcons }) => {
	// 					message.success({
	// 						content: 'subject icons updated',
	// 						key: subject_id
	// 					})
	// 					resolve(subjectNewIcons)
	// 				})
	// 				.catch((err) => {
	// 					message.error({
	// 						content: 'failed to update subject icons',
	// 						key: subject_id
	// 					})
	// 					reject(err)
	// 				})
	// 		})
	// 	},
	// 	[]
	// )

	const addNewSubject = useCallback(
		/**
		 * @param {AddSubjectPayload} payload
		 */
		({ darkIcon, lightIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				// const { icon_dark, icon_light, ...restPayload } = payload
				message.loading({
					content: 'adding new subject...',
					key: payload.title
				})

				const regex = /^temp\//i
				const hasTempInDark = regex.test(darkIcon)
				if (hasTempInDark) Object.assign(payload, { darkIcon })

				const hasTempInLight = regex.test(lightIcon)
				if (hasTempInLight) Object.assign(payload, { lightIcon })

				axios
					.post('/admin/subjects', payload)
					.then(({ data: newSubject }) => {
						message.success({
							content: 'new subject added',
							key: payload.title
						})
						enableUpdateStatistics()
						const subject = {
							...newSubject,
							lightIconSource: newSubject.lightIcon?.path,
							darkIconSource: newSubject.darkIcon?.path
						}
						enableUpdateStatistics()
						fetchSubjects()
						resolve(subject)
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new subject',
							key: payload.title
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects]
	)

	const deleteSubject = useCallback(
		/**
		 * @param {string} id - subject _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting subject...', key: id })
				axios
					.delete(`/admin/subjects/${id}`)
					.then(() => {
						message.success({
							content: 'subject deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchSubjects()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'deleting subject operation failed',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects]
	)

	const editSubject = useCallback(
		/**
		 * @param {EditSubjectPayload} payload
		 */
		({ _id: subject_id, lightIcon, darkIcon, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'updating subject data...',
					key: subject_id
				})

				const regex = /^temp\//i
				const hasTempInDark = regex.test(darkIcon)
				if (hasTempInDark) Object.assign(payload, { darkIcon })

				const hasTempInLight = regex.test(lightIcon)
				if (hasTempInLight) Object.assign(payload, { lightIcon })
				axios
					.put(`/admin/subjects/${subject_id}`, payload)
					.then(({ data: res }) => {
						const { data: editedSubject } = res
						const subject = {
							...res.data,
							lightIconSource: editedSubject.lightIcon?.path,
							darkIconSource: editedSubject.darkIcon?.path
						}
						message.success({ content: 'subject edited', key: subject_id })
						enableUpdateStatistics()
						fetchSubjects()
						resolve(subject)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update lesson',
							key: subject_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects]
	)

	const reorderSubject = useCallback(
		/**
		 * @param {ReOrderSubjectPayload} payload
		 */
		({ _id: subject_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'reordering subject...',
					key: subject_id
				})

				axios
					.put(`/admin/subjects/reorder/${subject_id}`, payload)
					.then(() => {
						message.success({ content: 'subject reordered', key: subject_id })
						enableUpdateStatistics()
						fetchSubjects()
					})
					.catch((err) => {
						message.error({
							content: 'failed to reorder subject',
							key: subject_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchSubjects]
	)

	return {
		data,
		isLoading,
		fetchSubjects,
		fetchSubject,
		addNewSubject,
		deleteSubject,
		editSubject,
		reorderSubject
	}
}
export default useSubjects
