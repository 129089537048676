/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { message } from 'antd'
import qs from 'query-string'

import { useUser } from '../store/hooks'
import { axios } from '../utils'

/**
 * @typedef {object} AddLevelPayload
 * @property {number} order
 * @property {string} title
 * @property {boolean=} active
 */

/**
 * @typedef {object} EditLevelPayload
 * @property {string} _id
 * @property {number} order
 * @property {string=} title
 * @property {boolean=} active
 */

const useLevels = () => {
	const [data, setData] = useState([])
	const [isLoading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)
	const { search } = useLocation()
	const { enableUpdateStatistics } = useUser()

	const fetchLevels = useCallback(
		/**
		 * @param {object} queryParams - request query parameters as an object
		 */
		(queryParams) => {
			return new Promise((resolve, reject) => {
				setLoading(true)
				const endpoint = `/admin/levels?${qs.stringify(queryParams, {
					skipNull: true
				})}`
				axios
					.get(endpoint)
					.then(({ data: res }) => {
						const { rows: levels, count } = res.data
						setTotalCount(count)
						setData(levels)
						resolve(levels)
					})
					.catch((err) => {
						reject(err)
					})
					.finally(() => setLoading(false))
			})
		},
		[]
	)

	const fetchAllLevels = useCallback(() => {
		return new Promise((resolve, reject) => {
			setLoading(true)
			axios
				.get('/admin/levels?limit=100')
				.then(({ data: res }) => {
					const { rows: levels, count } = res.data
					setTotalCount(count)
					setData(levels)
					resolve(levels)
				})
				.catch(reject)
				.finally(() => setLoading(false))
		})
	}, [])

	const addNewLevel = useCallback(
		/**
		 * @param {AddLevelPayload} payload
		 */
		(payload) => {
			return new Promise((resolve, reject) => {
				const key = Date.now()
				message.loading({
					content: 'adding new level...',
					key
				})

				axios
					.post('/admin/levels', payload)
					.then(() => {
						message.success({
							content: 'new level added',
							key
						})
						enableUpdateStatistics()
						fetchAllLevels()
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to add new level',
							key
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchAllLevels]
	)

	const deleteLevel = useCallback(
		/**
		 * @param {string} id - level _id
		 */
		(id) => {
			return new Promise((resolve, reject) => {
				message.loading({ content: 'deleting level...', key: id })
				axios
					.delete(`/admin/levels/${id}`)
					.then(() => {
						message.success({
							content: 'level deleted',
							key: id
						})
						enableUpdateStatistics()
						fetchLevels(qs.parse(search))
						resolve()
					})
					.catch((err) => {
						message.error({
							content: 'failed to delete level',
							key: id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLevels, search]
	)

	const editLevel = useCallback(
		/**
		 * @param {EditLevelPayload} payload
		 */
		({ _id: level_id, ...payload }) => {
			return new Promise((resolve, reject) => {
				message.loading({
					content: 'editing level data...',
					key: level_id
				})
				axios
					.put(`/admin/levels/${level_id}`, payload)
					.then(({ data: res }) => {
						message.success({
							content: 'level data updated',
							key: level_id
						})
						enableUpdateStatistics()
						fetchLevels(qs.parse(search))
						resolve(res.data)
					})
					.catch((err) => {
						message.error({
							content: 'failed to update level',
							key: level_id
						})
						reject(err)
					})
			})
		},
		[enableUpdateStatistics, fetchLevels, search]
	)

	return {
		data,
		isLoading,
		totalCount,
		fetchLevels,
		addNewLevel,
		deleteLevel,
		editLevel,
		fetchAllLevels
	}
}
export default useLevels
