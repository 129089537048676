import { Form, Input, Select } from 'antd'
import { object } from 'prop-types'

const COLUMNS_LABELS = ['left', 'right']

const defaultValues = {
	left: { label: '', options: [] },
	right: { label: '', options: [] }
}
export default function CLSQuestion({
	formInstance,
	initialValues = defaultValues
}) {
	return (
		<Form name="CLS_question" form={formInstance} initialValues={initialValues}>
			<Form.Item
				name="text"
				label="Question or text"
				labelAlign="left"
				className="flex flex-col"
				rules={[
					{
						required: true,
						message: 'Please input a text for question'
					}
				]}
			>
				<Input.TextArea rows={3} />
			</Form.Item>
			<h3>Classifications:</h3>
			{COLUMNS_LABELS.map((column) => {
				const value = `${column.slice(0, 1).toUpperCase()}${column
					.slice(1)
					.toLowerCase()}`
				return (
					<>
						<h3>{value} Column</h3>
						<div className="w-full flex flex-row flex-nowrap items-center mb-4">
							<Form.Item
								name={[column, 'label']}
								required
								rules={[
									{
										required: true,
										message: `Please enter the label for ${column} column`
									}
								]}
								noStyle
							>
								<Input className="mr-2" placeholder={`${value} Column Label`} />
							</Form.Item>
							<Form.Item
								name={[column, 'options']}
								noStyle
								rules={[
									{
										required: true,
										message: 'please add answers for this field'
									}
								]}
							>
								<Select
									mode="tags"
									placeholder="Answer"
									style={{ width: '100%' }}
									name={[column, 'options']}
								>
									{formInstance.getFieldValue(column)?.options.map((option) => (
										<Select.Option value={option} key={option}>
											{option}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</div>
					</>
				)
			})}
		</Form>
	)
}

CLSQuestion.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	formInstance: object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initialValues: object.isRequired
}
