import { SearchOutlined } from '@ant-design/icons'
import { Button, Radio, Space } from 'antd'

export default function getRadioFilterProps(
	label,
	selectData,
	selectItemRenderer
) {
	return {
		filterDropdown: (props) => {
			// eslint-disable-next-line react/prop-types
			const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props
			return (
				<div className="p-2 flex flex-col">
					<span>{label}</span>
					<Radio.Group
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys([e.target.value])}
						className="mb-2"
					>
						{selectData.map((record) => (
							<span key={record.value}>{selectItemRenderer(record)}</span>
						))}
					</Radio.Group>
					<Space>
						<Button
							type="primary"
							icon={<SearchOutlined />}
							size="small"
							className="w-18 flex items-center"
							onClick={() => {
								confirm()
							}}
						>
							Search
						</Button>
						<Button
							onClick={() => clearFilters()}
							size="small"
							className="w-18"
						>
							Reset
						</Button>
					</Space>
				</div>
			)
		}
	}
}
